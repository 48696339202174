import React, { FC, useContext, useState } from "react";
import { AddressElement } from "@stripe/react-stripe-js";
import { Button, Flex, Heading } from "@chakra-ui/react";
import { ProductsContext } from "../contexts/ProductsContext";
import {
  OrganisationAddress,
  Product,
  ProductPrice,
} from "../../../core/types/permissions";
import { UserContext } from "../../../core/contexts/UserContext";

const AddressForm: FC = () => {
  const {
    billingAddress,
    setBillingAddress,
    selectedProduct,
    selectedPrice,
    startSubscription,
  } = useContext(ProductsContext);
  const { selectedOrg } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  return (
    <form>
      <Heading variant={"section"} mb={3}>
        Company billing address
      </Heading>
      <AddressElement
        options={{
          mode: "billing",
          display: {
            name: "organization",
          },
          defaultValues: {
            name: selectedOrg?.name,
          },
        }}
        onChange={(event) => {
          if (event.complete) {
            setBillingAddress(event.value.address);
          } else {
            setBillingAddress(undefined);
          }
        }}
      />
      <Flex w={"100%"} justifyContent={"flex-end"} mt={4}>
        <Button
          isDisabled={!billingAddress}
          isLoading={loading}
          onClick={() => {
            setLoading(true);
            startSubscription(
              selectedProduct as Product,
              selectedPrice as ProductPrice,
              billingAddress as OrganisationAddress
            );
          }}
        >
          Next
        </Button>
      </Flex>
    </form>
  );
};

export default AddressForm;
