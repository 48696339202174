import { FormScore } from "modules/poll/types/data";
import { createContext } from "react";
import { StatDriver } from "core/types/form";
import { FCWithChildren } from "core/types/components";
import { useParams } from "react-router-dom";
import { useApi } from "core/services/api";
import { StatTypeUrl } from "modules/polls/constants/urls";
import { PollInstance, ScoreLocation } from "modules/polls/types/polls";

export interface IPollScoreSummaryContext {
  poll: PollInstance;
  location?: ScoreLocation;
  formScore: FormScore;
  pollAvgScore: number;
  positiveDrivers: StatDriver[];
  negativeDrivers: StatDriver[];
}

export const PollScoreSummaryContext = createContext<IPollScoreSummaryContext>({
  poll: {} as PollInstance,
  formScore: {} as FormScore,
  location: {} as ScoreLocation,
  pollAvgScore: 0,
  positiveDrivers: [],
  negativeDrivers: [],
});

export const PollScoreSummaryProvider: FCWithChildren = ({ children }) => {
  const { name, number, scoreId } = useParams();
  const pollUrl = `polls/${name}/instances/latest${number ? `/${number}` : ""}`;
  const [, poll] = useApi<PollInstance>(pollUrl);
  const [, pollAvgScoreResult] = useApi(
    poll?.poll_id ? `${StatTypeUrl.AvgScore}?pollInstanceId=${poll.id}` : ""
  );
  const [, formScoreResult] = useApi<{
    stat: FormScore;
  }>(
    `${StatTypeUrl.FormScore}?args=${JSON.stringify({
      scoreId,
    })}`
  );
  const [, pollScoreLocation] = useApi<ScoreLocation>(
    `polls/scores/${scoreId}/location`
  );
  const [, positiveDriversResult] = useApi(
    `${StatTypeUrl.TopDrivers}?args=${JSON.stringify({
      scoreId,
      trend: 1,
    })}`
  );
  const [, negativeDriversResult] = useApi(
    `${StatTypeUrl.TopDrivers}?args=${JSON.stringify({
      scoreId,
      trend: -1,
    })}`
  );

  if (!formScoreResult?.stat || !poll) {
    return null;
  }

  return (
    <PollScoreSummaryContext.Provider
      value={{
        poll,
        pollAvgScore: pollAvgScoreResult?.stat || 0,
        formScore: formScoreResult.stat,
        location: pollScoreLocation as ScoreLocation,
        positiveDrivers: positiveDriversResult?.stat || [],
        negativeDrivers: negativeDriversResult?.stat || [],
      }}
    >
      {children}
    </PollScoreSummaryContext.Provider>
  );
};
