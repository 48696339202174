import React, { FC, useContext } from "react";
import {
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { UserContext } from "../../../core/contexts/UserContext";
import { useFormik } from "formik";
import FormItem from "../../../core/components/FormItem";

interface Props extends BoxProps {}

const DeleteMe: FC<Props> = (props) => {
  const { user, deleteMe } = useContext(UserContext);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate: (values) => {
      const errors: Record<string, string> = {};

      if (!values.email) {
        errors.email = "Required";
      } else if (values.email !== user.email) {
        errors.email = "Must match registered email";
      }

      return errors;
    },
    onSubmit: async (values) => {
      await deleteMe(values.email);
    },
  });

  if (!user) {
    return null;
  }

  return (
    <Box {...props}>
      <Button bg={"error"} onClick={onOpen}>
        Delete Account
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent w={"500px"} maxW={"95%"} pb={5}>
          <ModalHeader>Delete My Account</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading variant={"section"} color={"error"} mb={3}>
              Warning
            </Heading>
            <Text fontSize={"16px"} mb={2}>
              if you delete your account you will lose all your FormScore data
              and will be unrecoverable.
            </Text>
            <Text mb={5}>
              Confirm your email below and click the "Delete" button to delete
              your account.
            </Text>
            <form onSubmit={formik.handleSubmit}>
              <FormItem
                label={"Your email"}
                name={"email"}
                type={"email"}
                onChange={formik.handleChange}
                value={formik.values.email}
                errorMessage={formik.errors.email}
                mb={8}
              />
              <ButtonGroup justifyContent={"flex-end"} w={"100%"}>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  type={"submit"}
                  isDisabled={formik.values.email !== user.email}
                >
                  Delete
                </Button>
              </ButtonGroup>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DeleteMe;
