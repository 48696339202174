import React, { FC, useContext } from "react";
import PollScoreDisplay from "./PollScoreDisplay";
import { PollDataContext } from "../contexts/PollDataContext";
import PollPageHeader from "./PollPageHeader";
import {
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { PollView } from "../types/data";
import PollDriversDisplay from "./PollDriversDisplay";

const PollPage: FC = () => {
  const { pollView, setPollView } = useContext(PollDataContext);
  const views = Object.values(PollView);

  const tabStyle = {
    fontSize: "24px",
    _selected: {
      color: "form.10",
      borderBottom: "2px solid",
      borderColor: "form.10",
    },
  };

  return (
    <div>
      <PollPageHeader />
      <Tabs
        index={views.indexOf(pollView)}
        onChange={(newIndex) => setPollView(views[newIndex])}
        isLazy
      >
        <TabList justifyContent={"center"}>
          <Tab {...tabStyle}>Scores</Tab>
          <Tab {...tabStyle}>Drivers</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <PollScoreDisplay />
          </TabPanel>
          <TabPanel pt={10}>
            <PollDriversDisplay />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Button as={"a"} href={"/polls"} position={"fixed"} bottom={4} left={4}>
        Dashboard
      </Button>
    </div>
  );
};

export default PollPage;
