import React, { FC } from "react";
import { PollAggregateScores } from "../../polls/types/polls";
import { Center, Flex, Text } from "@chakra-ui/react";

type BarDatum = {
  id: number;
  count: number;
  width: number;
  height: number;
};

interface Props {
  scores: PollAggregateScores;
}

const MAX_BAR_WIDTH = 550;
const MAX_BAR_HEIGHT = 350;

const AggregatePollScoreChart: FC<Props> = ({ scores }) => {
  const getBars: () => BarDatum[] = () => {
    const bars = [];

    for (let i = 1; i <= 10; i++) {
      const percent = scores[i] / scores.count;

      bars.push({
        id: i,
        count: scores[i],
        width: MAX_BAR_WIDTH * percent,
        height: MAX_BAR_HEIGHT * percent,
      });
    }

    return bars;
  };

  const getMaxHeight = (bars: BarDatum[]) => {
    return bars.reduce((acc, bar) => {
      if (bar.height > acc) {
        return bar.height;
      }

      return acc;
    }, 0);
  };

  const bars = getBars();
  const maxHeight = getMaxHeight(bars);

  return (
    <Center>
      <Flex direction={"row"} height={`${MAX_BAR_HEIGHT + 50}px`}>
        {bars.map((row) => {
          return (
            <Flex
              key={row.id}
              direction={"column"}
              mr={"10px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Flex
                flexDirection={"column"}
                height={`${MAX_BAR_HEIGHT}px`}
                alignItems={"center"}
                justifyContent={"flex-end"}
                mb={"10px"}
              >
                <Flex>
                  {row.count && row.count > 0 ? (
                    <Text fontSize="xs">{row.count}</Text>
                  ) : null}
                </Flex>
                <Flex
                  transition="all 0.5s ease-in-out"
                  height={`${(row.height / maxHeight) * 100}%`}
                  borderRadius="2px"
                  direction="row"
                  width="15px"
                  backgroundColor={`form.${row.id}`}
                ></Flex>
              </Flex>
              <Flex
                alignItems="center"
                justifyContent="center"
                width="40px"
                height="40px"
                borderRadius="20px"
                backgroundColor={`form.${row.id}`}
              >
                <Text fontSize="sm" color="#ffffff">
                  {row.id}
                </Text>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Center>
  );
};

export default AggregatePollScoreChart;
