import React, { FC, Fragment, useContext } from "react";
import { useWindowSize } from "react-use";
import { animated, useSpring } from "@react-spring/web";
import { PollScoreVariant } from "../types/data";
import { PollDataContext } from "../contexts/PollDataContext";
import { formatPollScoreData } from "../../polls/services/polls";
import { Center } from "@chakra-ui/react";
import { ResponsiveCirclePacking } from "@nivo/circle-packing";
import AggregatePollScoreChart from "./AggregatePollScoreChart";

interface Props {}

const PollScoreChart: FC<Props> = ({}) => {
  const { scores, pollScoreVariant } = useContext(PollDataContext);
  const { width, height } = useWindowSize();
  // subtract numbers are to do with other elements on the page, header, footer, etc
  const widthToSubtract = 0;
  const headerHeight = 275;
  const footerHeight = 0;
  const heightToSubtract = headerHeight + footerHeight;

  const textAnimation = useSpring({
    from: {
      scale: 0,
    },
    to: {
      scale: 1,
    },
  });

  if (!scores) {
    return null;
  }

  if (
    pollScoreVariant === PollScoreVariant.AllScores &&
    !Array.isArray(scores)
  ) {
    return <AggregatePollScoreChart scores={scores} />;
  }

  if (Array.isArray(scores) && scores.length < 1) {
    return null;
  }

  const chartData = formatPollScoreData(scores, pollScoreVariant);

  return (
    <Center
      pointerEvents={"none"}
      width={width - widthToSubtract}
      height={height - heightToSubtract}
      mx={"auto"}
      position={"fixed"}
      left={"50%"}
      top={headerHeight}
      transform={"translateX(-50%)"}
    >
      <ResponsiveCirclePacking
        data={chartData}
        id={"id"}
        value={"value"}
        colorBy={"id"}
        colors={({ data }: any) => {
          return data.color;
        }}
        isInteractive={false}
        leavesOnly
        padding={15}
        enableLabels
        label={(props: any) => props.data.label}
        labelComponent={({ node, style, label }) => {
          return (
            <Fragment>
              <animated.text
                textAnchor={"middle"}
                dominantBaseline={"central"}
                fill={"#ffffff"}
                style={
                  {
                    fontSize: Math.max(14, node.radius / 2),
                    fontFamily: "Fugue Regular",
                    ...style,
                    ...textAnimation,
                  } as any
                }
              >
                {(pollScoreVariant === PollScoreVariant.ScoreAmounts &&
                  `#${node.data.value}`) ||
                  label}
              </animated.text>
            </Fragment>
          );
        }}
      />
    </Center>
  );
};

export default PollScoreChart;
