import { Poll } from "../types/polls";
import { createContext } from "react";
import { FCWithChildren } from "../../../core/types/components";
import { useNavigate, useParams } from "react-router-dom";
import { useApi } from "../../../core/services/api";
import {
  createPollInstance,
  deletePoll,
  deletePollInstance,
} from "../services/polls";
import { mutate } from "swr";

export type IPollGroupContext = {
  poll: Poll;
  updatePoll: (newPoll: Partial<Poll>) => Promise<void>;
  deletePoll: () => Promise<void>;
  createInstance: () => Promise<void>;
  deleteInstance: (instanceId: string) => Promise<void>;
};

export const PollGroupContext = createContext<IPollGroupContext>({
  poll: {} as Poll,
  updatePoll: async () => {},
  deletePoll: async () => {},
  createInstance: async () => {},
  deleteInstance: async () => {},
});

export const PollGroupProvider: FCWithChildren = ({ children }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [, poll] = useApi(`polls/${id}`);

  const handleDeletePoll = async () => {
    await deletePoll(poll.id);
    navigate(`/polls`);
  };

  const handleCreateInstance = async () => {
    const instance = await createPollInstance(poll.id);
    await mutate(`polls/${id}`);
    window.open(`/p/${poll.short_id}/${instance.number}`, "_blank")
  };

  const handleDeleteInstance = async (instanceId: string) => {
    await deletePollInstance(instanceId);
    await mutate(`polls/${id}`);
  };

  return (
    <PollGroupContext.Provider
      value={{
        poll,
        updatePoll: async () => {},
        deletePoll: handleDeletePoll,
        createInstance: handleCreateInstance,
        deleteInstance: handleDeleteInstance,
      }}
    >
      {children}
    </PollGroupContext.Provider>
  );
};
