import React, { FC, useContext } from "react";
import {
  Button,
  ButtonProps,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import FormItem from "../../../core/components/FormItem";
import { PollDriversEditContext } from "../contexts/PollDriversEditContext";
import { listify } from "../../../core/services/strings";
import { Driver } from "core/types/form";

interface Props extends ButtonProps {}

const CreateDriver: FC<Props> = (props) => {
  const { categories, driverTypes, create, add } = useContext(
    PollDriversEditContext
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const defaultValues = {
    title: "",
    description: "",
    active: true,
    is_default: false,
    is_original: false,
    type: undefined,
    category: undefined,
  };
  const formik = useFormik<Partial<Driver>>({
    initialValues: defaultValues,
    validate: (values) => {
      const errors: Record<string, string> = {};

      if (!values.title) {
        errors.title = "Name is required";
      }

      if (!values.type) {
        errors.type = "Type is required";
      }

      if (!values.category) {
        errors.category = "Category is required";
      }
    },
    onSubmit: async (values) => {
      const result = await create(values);
      if (result) {
        await add([result?.id]);
        onClose();
        formik.setValues(defaultValues);
      }
    },
  });

  return (
    <>
      <Tooltip
        label={`Create your own custom work and personal drivers and allocate to ${listify(
          categories?.map((dt) => dt.title)
        )}`}
      >
        <Button onClick={onOpen}>Create Driver</Button>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create a new driver</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form onSubmit={formik.handleSubmit}>
              <VStack spacing={5} mb={6}>
                <FormItem
                  label={"Name"}
                  name={"title"}
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  errorMessage={formik.errors.title}
                />
                <FormItem
                  label={"Type"}
                  name={"type"}
                  inputComponent={
                    <Select
                      value={formik.values?.type?.id}
                      onChange={(e) => {
                        const newType = driverTypes.find(
                          (t) => t.id === e.target.value
                        );

                        formik.setFieldValue("type", newType);
                      }}
                    >
                      <option value={""}>Select type</option>
                      {driverTypes.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.title}
                        </option>
                      ))}
                    </Select>
                  }
                />
                <FormItem
                  label={"FormScore Category"}
                  name={"category"}
                  inputComponent={
                    <Select
                      value={formik.values?.category?.id}
                      onChange={(e) => {
                        const newCategory = categories.find(
                          (t) => t.id === e.target.value
                        );
                        formik.setFieldValue("category", newCategory);
                      }}
                    >
                      <option value={""}>Select category</option>
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.title}
                        </option>
                      ))}
                    </Select>
                  }
                />
              </VStack>
              <Flex justifyContent={"flex-end"}>
                <Button type={"submit"}>Create</Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateDriver;
