import React, { FC, FunctionComponent } from "react";
import { BoxProps, Icon } from "@chakra-ui/react";
import { ReactComponent as LightLogo } from "core/images/form_logo_word.svg";
import { ReactComponent as DarkLogo } from "core/images/form_logo_word_dark.svg";

type LogoVariant = "light" | "dark";

interface Props extends BoxProps {
  variant?: LogoVariant;
}

const logos: Record<LogoVariant, FunctionComponent> = {
  light: LightLogo,
  dark: DarkLogo,
};

const Logo: FC<Props> = ({ variant = "light", ...props }) => {
  // @ts-ignore
  return <Icon as={logos[variant]} {...props} />;
};

export default Logo;
