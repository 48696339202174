import React, { FC } from "react";
import Layout from "../../../core/components/Layout";
import TeamSettings from "./TeamSettings";
import UserSettings from "./UserSettings";
import { Divider } from "@chakra-ui/react";
import SubscriptionSettings from "./SubscriptionSettings";

const SettingsPage: FC = () => {
  return (
    <Layout title={"Settings"}>
      {/* todo: need some permissions here? */}
      <TeamSettings />
      <Divider my={10} />
      <SubscriptionSettings />
      <Divider my={10} />
      <UserSettings />
    </Layout>
  );
};

export default SettingsPage;
