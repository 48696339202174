import React, { FC, useContext } from "react";
import { Box, BoxProps, Heading } from "@chakra-ui/react";
import { TagCloud } from "react-tagcloud";
import { UserContext } from "core/contexts/UserContext";
import { AnalyticsContext } from "modules/analytics/contexts/AnalyticsContext";
import {
  buildStatUrlQuery,
  usePollIdUrlQuery,
} from "modules/polls/services/polls";
import { StatTypeUrl } from "modules/polls/constants/urls";
import { useApi } from "core/services/api";
import colors from "core/theme/colors";
import { StatDriver } from "core/types/form";
import Legend from "core/components/Legend";

interface Props extends BoxProps {}

const DriversWordCloud: FC<Props> = (props) => {
  const { selectedOrg } = useContext(UserContext);
  const { from, to } = useContext(AnalyticsContext);
  const pollUrlQuery = usePollIdUrlQuery();
  const buildQuery = (extraArgs?: Record<string, any>) => {
    return `?${buildStatUrlQuery({
      from: from as string,
      to: to as string,
      selectedOrg,
      urlQuery: pollUrlQuery,
      ...extraArgs,
    })}`;
  };

  const [, positiveDriversResult] = useApi<{
    stat: StatDriver[];
  }>(
    `${StatTypeUrl.TopDrivers}${buildQuery({
      limit: 15,
      trend: 1,
    })}`
  );
  const [, negativeDriversResult] = useApi<{
    stat: StatDriver[];
  }>(
    `${StatTypeUrl.TopDrivers}${buildQuery({
      limit: 15,
      trend: -1,
    })}`
  );

  const drivers = [
    ...(positiveDriversResult?.stat || []).map((d) => ({
      value: d.title,
      count: d.count,
      color: colors.form["10"],
      key: `positive-${d.title}`,
    })),
    ...(negativeDriversResult?.stat || []).map((d) => ({
      value: d.title,
      count: d.count,
      color: colors.form["4"],
      key: `negative-${d.title}`,
    })),
  ];
  const words = drivers;

  return (
    <Box {...props}>
      <Heading variant={"section"} mb={4}>
        Drivers
      </Heading>
      <Legend
        items={[
          {
            title: "Boosting Drivers",
            color: colors.form["10"],
          },
          {
            title: "Lowering Drivers",
            color: colors.form["4"],
          },
        ]}
      />
      <Box textAlign={"center"} maxW={"650px"} mt={4}>
        <TagCloud
          tags={words}
          minSize={15}
          maxSize={40}
          disableRandomColor
          shuffle
        />
      </Box>
    </Box>
  );
};

export default DriversWordCloud;
