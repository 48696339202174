import React, { FC, useContext } from "react";
import { Wrap } from "@chakra-ui/react";
import { ProductsContext } from "../contexts/ProductsContext";
import ProductCard from "./ProductCard";

const Products: FC = () => {
  const { products } = useContext(ProductsContext);

  return (
    <Wrap spacing={10} w={"1150px"} mx={"auto"} maxW={"100%"} pb={10}>
      {products.map((product) => (
        <ProductCard key={product.id} product={product} w={"350px"} />
      ))}
    </Wrap>
  );
};

export default Products;
