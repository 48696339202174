import React, { FC, useContext } from "react";
import { useApi } from "../../../core/services/api";
import { AnalyticsContext } from "../../analytics/contexts/AnalyticsContext";
import { StatTypeUrl } from "../constants/urls";
import { Box, BoxProps } from "@chakra-ui/react";
import FormMultiCircle from "../../../core/components/FormMultiCircle";
import { useStatUrlQuery } from "../services/polls";
import { FormScore } from "modules/poll/types/data";

interface Props extends BoxProps {}

const PollFormScore: FC<Props> = (props) => {
  const { from, to } = useContext(AnalyticsContext);
  const urlQuery = useStatUrlQuery(from as string, to as string);
  const [, formScoreResult] = useApi<{
    stat: FormScore;
  }>(`${StatTypeUrl.FormScore}?${urlQuery}`);

  return (
    <Box {...props}>
      <FormMultiCircle
        categoryScores={formScoreResult?.stat?.categories || []}
        score={formScoreResult?.stat?.avg || 0}
        size={200}
      />
    </Box>
  );
};

export default PollFormScore;
