import React, { FC, useContext, useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Button, Flex, Grid, Text } from "@chakra-ui/react";
import { ProductsContext } from "../contexts/ProductsContext";

const PaymentForm: FC = () => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { getSuccessRedirectUrl, selectedPrice, totalAmountDue } =
    useContext(ProductsContext);
  const taxDue = totalAmountDue
    ? totalAmountDue - (selectedPrice?.amount || 0)
    : 0;

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: getSuccessRedirectUrl(),
      },
    });

    setLoading(false);

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      alert(
        `There was an error trying to complete your subscription: ${result.error.message}`
      );
      return;
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Grid
        gridTemplateColumns={"1fr 1fr"}
        py={3}
        borderTop={"1px solid"}
        borderBottom={"1px solid"}
        mt={4}
      >
        <Text fontWeight={"bold"}>{taxDue ? "Subtotal" : "Total"}</Text>
        <Text textAlign={"right"}>
          £{selectedPrice?.amount.toLocaleString("en-GB")}
        </Text>
        {taxDue > 0 && (
          <>
            <Text fontWeight={"bold"}>VAT</Text>
            <Text textAlign={"right"}>
              £{taxDue.toLocaleString("en-GB", { minimumFractionDigits: 2 })}
            </Text>
            <Text fontWeight={"bold"}>Total</Text>
            <Text textAlign={"right"}>
              £
              {totalAmountDue?.toLocaleString("en-GB", {
                minimumFractionDigits: 2,
              })}
            </Text>
          </>
        )}
      </Grid>

      <Flex w={"100%"} justifyContent={"flex-end"} mt={4}>
        <Button isDisabled={!stripe} type={"submit"} isLoading={loading}>
          Submit
        </Button>
      </Flex>
    </form>
  );
};

export default PaymentForm;
