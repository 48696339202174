export const emojis: Record<
  string,
  { width: number; height: number; image: string }
> = {
  travel: { width: 37, height: 46, image: "" },
  weather: { width: 49, height: 31, image: "" },
  stress: { width: 42, height: 41, image: "" },
  sleep: { width: 45, height: 47, image: "" },
  nutrition: {
    width: 31,
    height: 42,
    image: "",
  },
  health: { width: 37, height: 34, image: "" },
  finance: { width: 43, height: 41, image: "" },
  exercise: { width: 43, height: 35, image: "" },
  energy: { width: 39, height: 43, image: "" },
  connections: {
    width: 48,
    height: 39,
    image: "",
  },
  work: { width: 47, height: 40, image: "" },
  kids: { width: 43.8, height: 40, image: "" },
};
