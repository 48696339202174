import React, { FC, useContext, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Tag,
  TagLabel,
  TagRightIcon,
  useDisclosure,
  Wrap,
} from "@chakra-ui/react";
import { PollDriversEditContext } from "../contexts/PollDriversEditContext";
import { groupBy } from "lodash";
import { MinusIcon } from "@chakra-ui/icons";
import { Driver } from "core/types/form";

interface Props {}

const AddDrivers: FC<Props> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { missingDrivers, add, driverTypes } = useContext(
    PollDriversEditContext
  );
  const [chosenDrivers, setChosenDrivers] = useState<Driver[]>([]);
  const driversToUse = missingDrivers.filter(
    (driver) => chosenDrivers.every((d) => d.id !== driver.id) && !!driver.type
  );
  const driversByType = groupBy(driversToUse, "type.id");

  const handleAdd = async () => {
    const ids = chosenDrivers.map((d) => d.id);
    await add(ids);
    onClose();
  };

  return (
    <>
      <Button onClick={onOpen}>Add Drivers</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent pb={5}>
          <ModalHeader>Add Drivers to Poll</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid gridTemplateColumns={"1fr 1fr"} gridGap={4} mb={8}>
              {driverTypes
                .filter((type) => driversByType[type.id])
                .map((type) => {
                  const groupedDrivers = driversByType[type.id] || [];
                  return (
                    <Box key={type.id}>
                      <Heading variant={"section"} mb={2}>
                        {type.title}
                      </Heading>
                      <Select
                        onChange={(e) => {
                          const driver = missingDrivers.find(
                            (d) => d.id === e.target.value
                          ) as Driver;
                          setChosenDrivers([...chosenDrivers, driver]);
                        }}
                      >
                        <option value="">Select Driver</option>
                        {groupedDrivers.map((driver) => {
                          return (
                            <option key={driver.id} value={driver.id}>
                              {driver.title}
                            </option>
                          );
                        })}
                      </Select>
                    </Box>
                  );
                })}
            </Grid>
            {!!chosenDrivers.length && (
              <Wrap mb={8}>
                {chosenDrivers.map((driver) => (
                  <Tag bg={driver.organisation_id ? "form.8" : "form.10"}>
                    <TagLabel color={"white"}>{driver.title}</TagLabel>
                    <TagRightIcon
                      as={MinusIcon}
                      color={"white"}
                      cursor={"pointer"}
                      onClick={() => {
                        setChosenDrivers(
                          chosenDrivers.filter((d) => d.id !== driver.id)
                        );
                      }}
                    />
                  </Tag>
                ))}
              </Wrap>
            )}
            <Flex justifyContent={"flex-end"}>
              <Button isDisabled={!chosenDrivers.length} onClick={handleAdd}>
                Add
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddDrivers;
