import React, { FC } from "react";
import { Box, Tooltip } from "@chakra-ui/react";
import FormCircle from "./FormCircle";

interface Props {
  categoryScores: {
    color: string;
    avg: number;
  }[];
  score: number;
  size: number;
}

const FormMultiCircle: FC<Props> = ({ score, categoryScores, size }) => {
  const ringStyles = {
    transformOrigin: "50%",
  };
  const circleStyles = {
    fill: "none",
  };
  const completedStyles = {
    strokeLinecap: "round" as any,
  };
  return (
    <Box w={`${size}px`} h={`${size}px`} position={"relative"}>
      <Box as={"svg"} w={"100%"} h={"100%"} viewBox={"0 0 35 35"}>
        {categoryScores?.map((cs, i) => {
          const percent = cs.avg / 10;
          const radius = 15 * (1 - i * 0.21);
          const circumference = radius * 2 * Math.PI;

          return (
            <Tooltip key={cs.color} label={cs.avg}>
              <Box
                key={cs.color}
                as={"g"}
                {...ringStyles}
                className="ring ring1"
                transform={`scale(1) rotate(-90deg)`}
              >
                <Box
                  as={"circle"}
                  stroke={"#D9D9D9"}
                  className="background"
                  cx="50%"
                  cy="50%"
                  r={radius}
                  strokeWidth="2.5"
                  {...circleStyles}
                />
                <Box
                  as={"circle"}
                  stroke={cs.color}
                  className="completed"
                  cx="50%"
                  cy="50%"
                  r={radius}
                  strokeWidth="2.5"
                  // strokeDasharray={`${percent * (1 - i * 0.2)}, 100`}
                  strokeDasharray={`${
                    circumference * percent
                  }, ${circumference}`}
                  {...circleStyles}
                  {...completedStyles}
                />
              </Box>
            </Tooltip>
          );
        })}
      </Box>
      <Box
        position={"absolute"}
        top={"50%"}
        left={"50%"}
        transform={"translate(-50%, -50%)"}
      >
        <FormCircle
          score={score}
          size={`${size * 0.375}px`}
          fontSize={`${size * 0.11}px`}
        >
          {score?.toFixed(1)}
        </FormCircle>
      </Box>
    </Box>
  );
};

export default FormMultiCircle;
