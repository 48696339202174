import React, { FC, useContext } from "react";
import { Box, BoxProps, Button, Flex, Heading } from "@chakra-ui/react";
import { useFormik } from "formik";
import { UserContext } from "../../../core/contexts/UserContext";
import FormItem from "core/components/FormItem";

interface Props extends BoxProps {}

const TeamSettings: FC<Props> = (props) => {
  const { selectedOrg, updateOrg } = useContext(UserContext);
  const formik = useFormik({
    initialValues: selectedOrg,
    enableReinitialize: true,
    validate: (values) => {
      const errors: Record<string, string> = {};

      if (!values.name) {
        errors.name = "Cannot have an empty name";
      }

      return errors;
    },
    onSubmit: async (values) => {
      await updateOrg(values);
    },
  });
  return (
    <Box {...props}>
      <Heading variant={"section"} mb={5}>
        Team Settings
      </Heading>
      <Box as={"form"} onSubmit={formik.handleSubmit as any} maxW={"500px"}>
        <FormItem
          label={"Team Name"}
          name={"name"}
          value={formik.values.name}
          onChange={formik.handleChange}
          errorMessage={formik.errors.name}
          mb={5}
        />
        <Flex justifyContent={"flex-end"}>
          <Button type={"submit"}>Save</Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default TeamSettings;
