import { ScoreRow } from "../components/PollTimeSeriesGraph";
import { last, range } from "lodash";
import { PollEvent } from "../types/polls";
import { differenceInDays, isBefore } from "date-fns";

export const TIME_SERIES_SHORT_DATE_FORMAT = "MMM do yyyy";
export const TIME_SERIES_LONG_DATE_FORMAT = "MMM do yyyy - HH:mm";

export function getLatestTimeSeriesDate({
  scores,
  events,
}: {
  scores: ScoreRow[];
  events: PollEvent[];
}): Date {
  const lastScoreDateStr = last(scores)?.x;
  const lastEventDateStr = last(events)?.date;

  if (!lastScoreDateStr) {
    return new Date();
  }

  if (!lastEventDateStr) {
    return new Date(lastScoreDateStr);
  }

  const lastScoreDate = new Date(lastScoreDateStr);
  const lastEventDate = new Date(lastEventDateStr);

  if (isBefore(lastScoreDate, lastEventDate)) {
    return lastEventDate;
  } else {
    return lastScoreDate;
  }
}

export function calculateTimeSeriesTicks({
  scores,
  events,
}: {
  scores: ScoreRow[];
  events: PollEvent[];
}) {
  if (!scores?.[0] || scores.length === 1) {
    return [];
  }

  const firstDateStr = scores[0].x;
  const firstDate = new Date(firstDateStr);
  const lastDate = getLatestTimeSeriesDate({ scores, events });
  const firstDateTime = firstDate.getTime();
  const lastDateTime = lastDate.getTime();
  const diffInTime = lastDateTime - firstDateTime;
  const diffInDays = Math.abs(differenceInDays(firstDate, lastDate));

  if (diffInDays === 0) {
    return [];
  }

  const ticksBetweenCount = Math.min(Math.max(1, diffInDays), 4);
  const split = Math.floor(diffInTime / ticksBetweenCount);
  const ticksBetween = range(1, ticksBetweenCount).map((num) => {
    return new Date(firstDateTime + split * num);
  });

  return [firstDate, ...ticksBetween, new Date(lastDate)];
}
