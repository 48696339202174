import React, { FC, useContext } from "react";
import { Box, BoxProps, Button, Wrap } from "@chakra-ui/react";
import PollInstanceItem from "./PollInstanceItem";
import { PollGroupContext } from "../contexts/PollGroupContext";

interface Props extends BoxProps {}

const PollInstances: FC<Props> = (props) => {
  const { poll, createInstance } = useContext(PollGroupContext);

  return (
    <Box {...props}>
      <Button onClick={createInstance} mb={5}>
        Start New Poll
      </Button>
      <Wrap spacing={5} py={5}>
        {poll.instances.map((instance) => {
          return <PollInstanceItem instance={instance} key={instance.id} />;
        })}
      </Wrap>
    </Box>
  );
};

export default PollInstances;
