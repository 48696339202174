import React, { FC, useState } from "react";
import { useFormik } from "formik";
import { Box, Button, Flex } from "@chakra-ui/react";
import FormItem from "../../../core/components/FormItem";
import { createOrganisation } from "../../../core/services/form";
import { Organisation } from "../../../core/types/user";
import { startNewTeamTrial } from "../../products/services/products";
import TrialStartedModal from "../../../core/components/TrialStartedModal";

interface Props {
  onSubmit: (org: Organisation) => void;
}

const NewUserTeamForm: FC<Props> = ({ onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [newOrg, setNewOrg] = useState<Organisation | undefined>(undefined);
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.name) {
        errors.name = "Name required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      setLoading(true);
      const org = await createOrganisation(values.name);
      if (org) {
        await startNewTeamTrial(org.id);
        setNewOrg(org);
      }
      setLoading(false);
    },
  });

  const handleModalClose = () => {
    onSubmit(newOrg as Organisation);
    setNewOrg(undefined);
  };

  return (
    <>
      <Box maxW={"500px"}>
        <form onSubmit={formik.handleSubmit}>
          <FormItem
            label={"Team Name"}
            name={"name"}
            onChange={formik.handleChange}
            errorMessage={formik.errors.name}
            mb={4}
          />
          <Flex justifyContent={"flex-end"} w={"100%"}>
            <Button
              type={"submit"}
              mt={4}
              isLoading={loading}
              isDisabled={loading}
            >
              Start Trial
            </Button>
          </Flex>
        </form>
      </Box>
      <TrialStartedModal isOpen={!!newOrg} onClose={handleModalClose} />
    </>
  );
};

export default NewUserTeamForm;
