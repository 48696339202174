import React, { FC, useContext } from "react";
import { useFormik } from "formik";
import FormItem from "../../../core/components/FormItem";
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  PinInput,
  PinInputField,
  Text,
} from "@chakra-ui/react";
import { AuthContext, LoginStage } from "core/contexts/AuthContext";

interface Props {
  onLogin: () => void;
  hideBack?: boolean;
}

const LoginForm: FC<Props> = ({ onLogin, hideBack }) => {
  const { login, verify, stage, setStage } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      email: "",
      code: "",
    },
    validate: (values) => {
      const errors: typeof values = {} as any;

      if (stage === LoginStage.Email) {
        if (!values.email) {
          errors.email = "Email required";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = "Invalid email address";
        }
      }

      if (stage === LoginStage.Code) {
        if (values.email && !values.code) {
          errors.code = "Code required";
        }

        if (values.email && values.code && values.code.length !== 6) {
          errors.code = "Invalid code";
        }
      }

      return errors;
    },
    onSubmit: async (values) => {
      if (stage === LoginStage.Email) {
        await login(values.email);
      }

      if (stage === LoginStage.Code) {
        await verify(values.code);
        onLogin();
      }
    },
  });

  const labels = {
    [LoginStage.Email]: "Enter your email below",
    [LoginStage.Code]: "Enter the code we sent to your email",
  };

  return (
    <Box as={"form"} onSubmit={formik.handleSubmit as any} maxW={"500px"}>
      <Text mb={2}>{labels[stage]}</Text>
      {stage === LoginStage.Email && (
        <FormItem
          name={"email"}
          onChange={formik.handleChange}
          value={formik.values.email}
          errorMessage={formik.errors.email}
        />
      )}
      {stage === LoginStage.Code && (
        <FormItem
          name={"code"}
          value={formik.values.code}
          helperText={"Pro tip: copy the code and paste into the first box"}
          errorMessage={formik.errors.code}
          inputComponent={
            <HStack>
              <PinInput
                value={formik.values.code}
                onChange={(e) => {
                  formik.setFieldValue("code", e);
                }}
              >
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
          }
        />
      )}
      <ButtonGroup mt={4}>
        {stage === LoginStage.Code && !hideBack && (
          <Button bg={"error"} onClick={() => setStage(LoginStage.Email)}>
            Back
          </Button>
        )}
        <Button type={"submit"}>Submit</Button>
      </ButtonGroup>
    </Box>
  );
};

export default LoginForm;
