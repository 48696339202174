export enum PollScoreVariant {
  AllScores = "all-scores",
  ScoreAmounts = "score-amounts",
}

export enum PollView {
  Scores = "scores",
  Drivers = "drivers",
}

export type FormScore = {
  avg: number;
  categories: {
    avg: number;
    title: string;
    color: string;
  }[];
};
