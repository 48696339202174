import React, { FC } from "react";
import { BoxProps, Grid, Select } from "@chakra-ui/react";
import { TimePeriod } from "../types/dates";
import {
  formatDateTimeForInput,
  getDateFromDateTimeInput,
} from "../services/dates";
import FormItem from "./FormItem";

export type TimeControlsProps = {
  from: string | undefined;
  setFrom: (from: string) => void;
  to: string | undefined;
  setTo: (to: string) => void;
  timePeriod: TimePeriod | undefined;
  setTimePeriod: (timePeriod: TimePeriod) => void;
};

const TimeControls: FC<TimeControlsProps & BoxProps> = ({
  from,
  to,
  timePeriod,
  setFrom,
  setTo,
  setTimePeriod,
  ...props
}) => {
  return (
    <Grid
      gridTemplateColumns={"1fr 1fr 1fr"}
      gridGap={4}
      maxW={"700px"}
      {...props}
    >
      <FormItem
        name={"time-period"}
        label={"Time Period"}
        helperText={""}
        inputComponent={
          <Select
            value={timePeriod || ""}
            onChange={(e) => setTimePeriod(e.target.value as TimePeriod)}
          >
            <option value="">Select Time Period</option>
            {Object.values(TimePeriod).map((tp) => {
              return (
                <option value={tp} key={tp}>
                  {tp}
                </option>
              );
            })}
          </Select>
        }
      />
      {timePeriod === TimePeriod.Custom && (
        <>
          <FormItem
            name={"from"}
            label={"From"}
            type={"datetime-local"}
            value={formatDateTimeForInput(from as string)}
            onChange={(e) => {
              setFrom(getDateFromDateTimeInput(e).toISOString());
            }}
          />
          <FormItem
            name={"to"}
            label={"To"}
            type={"datetime-local"}
            value={formatDateTimeForInput(to as string)}
            onChange={(e) => {
              setTo(getDateFromDateTimeInput(e).toISOString());
            }}
          />
        </>
      )}
    </Grid>
  );
};

export default TimeControls;
