import React, { FC } from "react";
import {
  BoxProps,
  Card,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";
import NewPollForm from "./NewPollForm";
import { useNavigate } from "react-router-dom";

interface Props extends BoxProps {}

const NewPollGroupTease: FC<Props> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  return (
    <>
      <Card
        cursor={"pointer"}
        w={"250px"}
        maxW={"100%"}
        bg={"white"}
        position={"relative"}
        onClick={onOpen}
        _active={{
          transform: "scale(0.95)",
        }}
        {...props}
      >
        <VStack
          position={"absolute"}
          top={"50%"}
          left={"50%"}
          transform={"translate(-50%, -50%)"}
        >
          <Icon as={FiPlus} fontSize={"46px"} />
          <Heading variant={"section"} textAlign={"center"}>
            New Poll Bundle
          </Heading>
        </VStack>
      </Card>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Poll Bundle</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <NewPollForm
              pb={4}
              onCreated={(poll) => {
                navigate(`/polls/${poll.id}`);
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewPollGroupTease;
