import React, { FC } from "react";
import {
  Box,
  BoxProps,
  Icon,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { BiInfoCircle } from "react-icons/bi";
import { IconProps } from "@chakra-ui/icons";

interface Props extends BoxProps {
  title?: string;
  label: string;
  iconProps?: IconProps;
}

const InfoTooltip: FC<Props> = ({ title, label, iconProps, ...props }) => {
  return (
    <Box color={"black"} textAlign={"left"} {...props}>
      <Popover>
        <PopoverTrigger>
          <Box>
            <Icon as={BiInfoCircle} display={"block"} {...iconProps} />
          </Box>
        </PopoverTrigger>
        <PopoverContent mr={4} zIndex={3}>
          <PopoverCloseButton />
          {title && <PopoverHeader>{title}</PopoverHeader>}
          <PopoverBody>
            <Text fontSize={"14px"}>{label}</Text>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default InfoTooltip;
