import { last } from "lodash";

export function listify(arr?: string[]) {
  if (!arr) {
    return "";
  }

  const start = arr.slice(0, arr.length - 1);

  return `${start.join(", ")}, and ${last(arr)}`;
}
