import React, { FC } from "react";
import SetScore from "./SetScore";

const PollScorePage: FC = () => {
  return (
    <div>
      <SetScore />
    </div>
  );
};

export default PollScorePage;
