import React, { FC, useContext, useEffect, useState } from "react";
import { PermissionsContext } from "../../../core/contexts/PermissionsContext";
import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { UserContext } from "../../../core/contexts/UserContext";
import { startNewTeamTrial } from "../services/products";
import TrialStartedModal from "../../../core/components/TrialStartedModal";

const StartTrial: FC = () => {
  const { selectedOrg } = useContext(UserContext);
  const { trialAvailable } = useContext(PermissionsContext);
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: trialAvailable,
  });
  const [trialStarted, setTrialStarted] = useState(false);

  useEffect(() => {
    if (trialAvailable && !isOpen) {
      onOpen();
    }
  }, [trialAvailable]);

  const handleStartTrial = async () => {
    await startNewTeamTrial(selectedOrg?.id);
    onClose();
    setTrialStarted(true);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={"550px"} textAlign={"center"} p={4}>
          <ModalBody>
            <VStack spacing={6}>
              <Heading fontSize={"50px"}>Try for Free</Heading>
              <Text>
                Thank you for testing the FormScore for Managers platform.
                Please click the button to access a free 2-week trial and unlock
                powerful analytics features to help you understand what is
                driving the performance readiness of your teams.
              </Text>
              <Button onClick={handleStartTrial}>Unlock Trial</Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <TrialStartedModal
        isOpen={trialStarted}
        onClose={() => {
          setTrialStarted(false);
          window.location.href = "/polls";
        }}
      />
    </>
  );
};

export default StartTrial;
