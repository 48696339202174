import React, { FC, useContext } from "react";
import { Box, Flex, FlexProps, Stack, StackDivider } from "@chakra-ui/react";
import { ProductsContext } from "../contexts/ProductsContext";

interface Props extends FlexProps {}

const PaymentTermSelector: FC<Props> = (props) => {
  const { selectedPaymentTerm, setPaymentTerm } = useContext(ProductsContext);
  const tabProps = (term: "month" | "year") => ({
    px: 4,
    py: 2,
    bg: selectedPaymentTerm === term ? "success" : "transparent",
    color: selectedPaymentTerm === term ? "white" : "",
    cursor: "pointer",
    minW: "95px",
    textAlign: "center" as any,
    onClick: () => setPaymentTerm(term),
  });
  return (
    <Flex justifyContent={"center"} {...props}>
      <Stack
        mr={"15px"}
        direction={"row"}
        spacing={0}
        divider={<StackDivider />}
        overflow={"hidden"}
        borderRadius={"lg"}
        shadow={"md"}
      >
        <Box {...tabProps("month")}>Monthly</Box>
        <Box {...tabProps("year")}>Yearly</Box>
      </Stack>
    </Flex>
  );
};

export default PaymentTermSelector;
