import { PollInstance, ScoreLocation } from "../../polls/types/polls";
import { createContext, useState } from "react";
import { FCWithChildren } from "../../../core/types/components";
import { useParams } from "react-router-dom";
import { useApi } from "../../../core/services/api";
import {
  saveDrivers,
  saveScore,
  saveScoreCategories,
  saveScoreLocation,
} from "../../polls/services/polls";
import { Driver, DriverType, FormCategory } from "core/types/form";

export type IPollContext = {
  poll: PollInstance;
  drivers: Driver[];
  driverTypes: DriverType[];
  currentDriverType: DriverType;
  locations: ScoreLocation[];
  selectedLocation: string;
  setSelectedLocation: (locationId: string) => void;
  categories: FormCategory[];
  saveScore: (score: number) => Promise<any>;
  saveScoreCategories: (
    scoreId: string,
    scoreCategoriesWithScore: Record<string, number>
  ) => Promise<any>;
  saveScoreLocation: (locationId: string) => Promise<any>;
  saveDrivers: (drivers: string[], trend: number) => Promise<any>;
  driverStage: number;
  setDriverStage: (stage: number) => void;
};

export const PollContext = createContext<IPollContext>({
  poll: {} as PollInstance,
  drivers: [],
  driverTypes: [],
  currentDriverType: {} as DriverType,
  locations: [],
  selectedLocation: "",
  setSelectedLocation: () => {},
  categories: [],
  saveScore: async () => {},
  saveScoreCategories: async () => {},
  saveScoreLocation: async () => {},
  saveDrivers: async () => {},
  driverStage: 0,
  setDriverStage: () => {},
});

export const PollProvider: FCWithChildren = ({ children }) => {
  const { name, number, scoreId } = useParams();
  const url = `polls/${name}/instances/latest${number ? `/${number}` : ""}`;
  const [, poll] = useApi<PollInstance>(url);
  const [, drivers] = useApi(
    (poll &&
      poll.capture_drivers &&
      `polls/reasons?pollInstanceId=${poll.id}`) ||
      ""
  );
  const [, driverTypes] = useApi(
    (poll?.capture_drivers && "drivers/types") || ""
  );
  const [, locations] = useApi("score-locations");
  const [, categories] = useApi("form-categories");
  const [driverStage, setDriverStage] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState("");

  const currentDriverType = driverTypes?.[driverStage];
  const driversToUse = drivers?.filter?.((driver: any) => {
    if (!currentDriverType) {
      return false;
    }

    return driver?.type?.id === currentDriverType.id;
  });

  const handleSaveScore = async (score: number) => {
    return saveScore(poll?.id as string, score);
  };

  const handleSaveScoreCategories = async (
    scoreId: string,
    scoreCategoriesWithScore: Record<string, number>
  ) => {
    return saveScoreCategories({
      scoreId: scoreId,
      pollId: poll?.poll_id as string,
      pollInstanceId: poll?.id as string,
      scoreCategoriesWithScores: scoreCategoriesWithScore,
    });
  };

  const handleSaveScoreLocation = async (locationId: string) => {
    return saveScoreLocation(scoreId as string, locationId);
  };

  const handleSaveDrivers = async (drivers: string[], trend: number) => {
    return saveDrivers(poll?.id as string, scoreId as string, drivers, trend);
  };

  return (
    <PollContext.Provider
      value={{
        poll: (poll || {}) as PollInstance,
        drivers: driversToUse || [],
        driverTypes: driverTypes || [],
        currentDriverType,
        locations: locations || [],
        selectedLocation,
        setSelectedLocation,
        categories: categories || [],
        saveScore: handleSaveScore,
        saveScoreCategories: handleSaveScoreCategories,
        saveScoreLocation: handleSaveScoreLocation,
        saveDrivers: handleSaveDrivers,
        driverStage,
        setDriverStage,
      }}
    >
      {children}
    </PollContext.Provider>
  );
};
