import React, { FC, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PollContext } from "../contexts/PollContext";
import { Box, Button, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import DriverSelect from "./DriverSelect";
import { Driver } from "core/types/form";

const SetDrivers: FC = () => {
  const { name, number, scoreId } = useParams();
  const navigate = useNavigate();
  const {
    drivers,
    saveDrivers,
    driverTypes,
    currentDriverType,
    driverStage,
    setDriverStage,
  } = useContext(PollContext);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<{
    positive: string[];
    negative: string[];
  }>({
    positive: [],
    negative: [],
  });
  const [tab, setTab] = useState<"positive" | "negative">("positive");
  const canSkip =
    driverStage === 0 && !selected.positive.length && !selected.negative.length;
  const summaryUrl = `/p/${name}${
    number ? `/${number}` : ""
  }/score/${scoreId}/summary`;

  const tabStyles = (selected: boolean) => ({
    bg: selected ? "success" : "body",
    color: selected ? "white" : "grey.3",
    padding: "5px 20px",
    cursor: "pointer",
    userSelect: "none" as any,
    w: "50%",
    textAlign: "center" as any,
  });
  const handleSave = async () => {
    if (driverStage !== driverTypes.length - 1) {
      setDriverStage(driverStage + 1);
      return;
    }
    setLoading(true);
    if (!selected.positive.length && !selected.negative.length) {
      navigate(summaryUrl);
      return;
    }

    const calls = [];

    if (selected.positive.length) {
      calls.push(saveDrivers(selected.positive, 1));
    }

    if (selected.negative.length) {
      calls.push(saveDrivers(selected.negative, -1));
    }

    Promise.all(calls)
      .catch((e) => {
        console.error(e);
        alert(
          "There was an error saving your form score reasons. Please try again"
        );
        setLoading(false);
      })
      .then(() => {
        navigate(summaryUrl);
      });
  };

  const handleSkip = () => {
    // * reached end of inputs
    if (driverStage === driverTypes.length - 1) {
      navigate(summaryUrl);
      return;
    }

    setDriverStage(driverStage + 1);
  };

  const renderDriverSelect = (
    driverList: Driver[],
    trend: "positive" | "negative"
  ) => {
    return (
      <DriverSelect
        drivers={driverList}
        display={tab === trend ? "grid" : "none"}
        onDriverSelect={(items) =>
          setSelected({
            ...selected,
            [trend]: items,
          })
        }
        trend={trend}
        showEmojis={driverStage !== 0}
      />
    );
  };

  const resetScreen = () => {
    window.scrollTo(0, 0);
    setTab("positive");
  };

  return (
    <Flex
      pb={"180px"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
      position={"relative"}
    >
      <Heading fontSize={"18px"} mb={2} w={"100%"}>
        {currentDriverType?.title} Drivers
      </Heading>
      <Text color={"grey.3"} fontSize={"14px"} mb={5} w={"100%"}>
        Tap the drivers contributing to your wellbeing score
      </Text>
      <Flex
        mb={4}
        width={"100%"}
        borderRadius={"10px"}
        border={"1px solid"}
        borderColor={"grey.1"}
        overflow={"hidden"}
        position={"sticky"}
        top={0}
      >
        <Box
          onClick={() => setTab("positive")}
          {...tabStyles(tab === "positive")}
        >
          Boosting
        </Box>
        <Box width={"1px"} height={"34px"} background={"border"} />
        <Box
          onClick={() => setTab("negative")}
          {...tabStyles(tab === "negative")}
          bg={tab === "negative" ? "form.4" : "body"}
        >
          Lowering
        </Box>
      </Flex>

      <Box>
        {renderDriverSelect(drivers, "positive")}
        {renderDriverSelect(drivers, "negative")}
      </Box>
      <VStack
        bg={"body"}
        p={4}
        width={"100%"}
        bottom={0}
        position={"fixed"}
        maxW={"440px"}
        w={"100%"}
        mx={"auto"}
      >
        <Button
          bg={(canSkip && "white") || "success"}
          borderColor={(canSkip && "success") || ""}
          color={(canSkip && "success") || "white"}
          variant={"poll"}
          disabled={loading}
          opacity={loading ? 0.5 : 1}
          onClick={() => {
            if (canSkip) {
              handleSkip();
            } else {
              handleSave();
            }
            resetScreen();
          }}
        >
          {(canSkip && "Skip") || "Save"}
        </Button>
        {driverStage !== 0 && (
          <Button
            bg={"white"}
            color={"success"}
            border={"1px solid"}
            borderColor={"success"}
            w={"100%"}
            borderRadius={"10px"}
            onClick={() => {
              setDriverStage(driverStage - 1);
              resetScreen();
            }}
          >
            Back
          </Button>
        )}
      </VStack>
    </Flex>
  );
};

export default SetDrivers;
