import { addDays, isBefore } from "date-fns";

export function storeItem(key: string, data: any, expiry: number) {
  return localStorage.setItem(
    key,
    JSON.stringify({
      payload: data,
      expiry: addDays(new Date(), expiry).toISOString(),
    })
  );
}

export function getItem<T = any>(key: string) {
  const item = JSON.parse(localStorage.getItem(key) || "{}");

  if (item) {
    const { expiry, payload } = item;

    if (isBefore(new Date(), new Date(expiry))) {
      return payload;
    } else {
      removeItem(key);
    }
  }

  return undefined;
}

export function removeItem(key: string) {
  return localStorage.removeItem(key);
}
