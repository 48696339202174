import React, { FC, useContext } from "react";
import { Box, Button, Heading, Text, VStack } from "@chakra-ui/react";
import { PollScoreSummaryContext } from "modules/poll/contexts/PollScoreSummaryContext";
import FormMultiCircle from "core/components/FormMultiCircle";
import Legend from "core/components/Legend";
import PollStatDriversDisplay from "modules/poll/components/PollStatDriversDisplay";
import { format } from "date-fns";
import { TIME_SERIES_SHORT_DATE_FORMAT } from "modules/polls/services/graphs";
import { useNavigate } from "react-router-dom";

interface Props {}

const PollScoreSummaryPage: FC<Props> = (props) => {
  const navigate = useNavigate();
  const {
    poll,
    location,
    pollAvgScore,
    formScore,
    positiveDrivers,
    negativeDrivers,
  } = useContext(PollScoreSummaryContext);

  const locationLabels: Record<string, string> = {
    Remote: "remotely",
    "In Person": "in person",
  };

  return (
    <Box p={4}>
      <Box textAlign={"center"} mb={8}>
        <Heading fontSize={"36px"} my={2}>
          Your FormScore Summary
        </Heading>
        <Heading fontSize={"28px"} mb={2}>
          {poll.name}
        </Heading>
        <Heading fontSize={"20px"}>
          {format(new Date(poll.created_at), TIME_SERIES_SHORT_DATE_FORMAT)}
        </Heading>
      </Box>
      <VStack mb={6}>
        <FormMultiCircle
          categoryScores={formScore.categories}
          score={formScore.avg}
          size={250}
        />
        <Legend items={formScore.categories} />
        <Text textAlign={"center"} pt={3}>
          Poll average score: <strong>{pollAvgScore}</strong>
        </Text>
      </VStack>
      <VStack spacing={5} mb={10}>
        {location && (
          <Text>
            Today you are working{" "}
            <strong>{locationLabels[location.title]}</strong>
          </Text>
        )}
        <PollStatDriversDisplay
          drivers={positiveDrivers}
          title={"Your Boosting Drivers"}
          variant={"no-count"}
        />
        <PollStatDriversDisplay
          drivers={negativeDrivers}
          title={"Your Lowering Drivers"}
          variant={"no-count"}
        />
      </VStack>
      <Box w={"100%"} maxW={"440px"} mx={"auto"} p={4}>
        <Button
          w={"100%"}
          variant={"poll"}
          bg={"success"}
          onClick={() => {
            navigate("/p/thank-you");
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default PollScoreSummaryPage;
