import React, { FC, useContext } from "react";
import { PollDriversEditContext } from "../contexts/PollDriversEditContext";
import {
  Box,
  Grid,
  Heading,
  HStack,
  Tag,
  TagLabel,
  TagRightIcon,
  Text,
  Tooltip,
  Wrap,
} from "@chakra-ui/react";
import { MinusIcon } from "@chakra-ui/icons";
import { groupBy } from "lodash";
import AddDrivers from "./AddDrivers";
import CreateDriver from "./CreateDriver";
import Legend from "../../../core/components/Legend";

const PollDriversEdit: FC = () => {
  const { drivers, driverTypes, remove, missingDrivers } = useContext(
    PollDriversEditContext
  );

  if (!drivers) {
    return null;
  }

  const groupedDrivers = groupBy(drivers, "type.id");

  return (
    <Box>
      <Text mb={4}>
        Use the interface below to manage the drivers for this poll and create
        your own custom drivers
      </Text>
      <Grid gridTemplateColumns={"1fr 1fr"} gridGap={10} mb={4} maxW={"850px"}>
        {driverTypes.map((type) => {
          const typeDrivers = groupedDrivers[type.id];
          return (
            <Box key={type.id}>
              <Heading mb={4} variant={"section"}>
                {type.title}
              </Heading>
              <Wrap maxW={"400px"} mb={4}>
                {typeDrivers?.map((driver) => (
                  <Tooltip
                    key={driver.id}
                    label={`Click the minus to remove drivers and customise your poll. You can always add them back using the “Add Driver” button that will appear below`}
                  >
                    <Tag
                      key={driver.id}
                      bg={driver.organisation_id ? "form.8" : "success"}
                    >
                      <TagLabel color={"white"}>{driver.title}</TagLabel>
                      <TagRightIcon
                        as={MinusIcon}
                        color={"white"}
                        cursor={"pointer"}
                        onClick={() => {
                          remove(driver.id);
                        }}
                      />
                    </Tag>
                  </Tooltip>
                ))}
              </Wrap>
            </Box>
          );
        })}
      </Grid>
      <Legend
        mb={10}
        items={[
          {
            color: "success",
            title: "FormScore drivers",
          },
          {
            color: "form.8",
            title: "Your custom drivers",
          },
        ]}
      />

      <HStack>
        <CreateDriver />
        {missingDrivers?.length > 0 && <AddDrivers />}
      </HStack>
    </Box>
  );
};

export default PollDriversEdit;
