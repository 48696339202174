import { createContext, useContext, useEffect, useState } from "react";
import { FCWithChildren } from "core/types/components";
import { AuthContext } from "./AuthContext";
import { api, useApi } from "core/services/api";
import { Organisation, User } from "core/types/user";
import { useLocation, useNavigate } from "react-router-dom";
import { mutate } from "swr";
import { logout } from "../services/auth";
import { SELECTED_ORG_COOKIE_NAME } from "../constants/cookies";
import {
  createOrganisation,
  getOrgLandingUrl,
  updateOrganisation,
} from "../services/form";
import { getItem, removeItem, storeItem } from "../services/storage";
import { isEqual } from "lodash";
import { catchify } from "../services/ajax";

export type IUserContext = {
  user: User;
  selectedOrg: Organisation;
  setSelectedOrg: (org: Organisation | undefined) => void;
  createOrg: (orgName: string) => Promise<void>;
  updateOrg: (newOrg: Partial<Organisation>) => Promise<void>;
  deleteMe: (promptedEmail: string) => Promise<void>;
};

export const UserContext = createContext<IUserContext>({
  user: {} as User,
  selectedOrg: {} as Organisation,
  setSelectedOrg: () => {},
  createOrg: async () => {},
  updateOrg: async () => {},
  deleteMe: async () => {},
});

export const UserProvider: FCWithChildren = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn } = useContext(AuthContext);
  const [loadingState, user] = useApi(isLoggedIn ? "user/me" : "", {});
  const [loadingOrgs, orgs] = useApi(
    user?.id ? "user/me/organisations" : "",
    {}
  );

  const [selectedOrg, setSelectedOrg] = useState<Organisation | undefined>(
    getItem<Organisation>(SELECTED_ORG_COOKIE_NAME)
  );

  const handleSetSelectedOrg = async (org: Organisation | undefined) => {
    if (!org) {
      removeItem(SELECTED_ORG_COOKIE_NAME);
    } else {
      storeItem(SELECTED_ORG_COOKIE_NAME, org, 1);
    }
    setSelectedOrg(org);
    await mutate("");
    navigate(getOrgLandingUrl(org as Organisation));
  };

  const handleCreateOrg = async (orgName: string) => {
    await createOrganisation(orgName);
    await mutate("user/me");
  };

  const handleUpdateOrg = async (org: Partial<Organisation>) => {
    await updateOrganisation(org);
    await mutate("user/me");
  };

  const handleDeleteMe = async (promptedEmail: string) => {
    if (promptedEmail !== user.email) {
      alert(
        "Email does not match your registered email address. Please try again"
      );
      return;
    }

    const [err] = await catchify(
      api.delete({
        url: "user/me",
      })
    );

    if (err) {
      alert(
        "There was an error when trying to delete your user. Please contact us to so we can resolve the issue"
      );
      return;
    }

    logout();
  };

  useEffect(() => {
    if (user?.id && orgs?.length && !selectedOrg) {
      setSelectedOrg(orgs[0]);
      storeItem(SELECTED_ORG_COOKIE_NAME, orgs[0], 1);
    }

    if (
      user &&
      orgs &&
      user?.id &&
      !orgs?.length &&
      !location.pathname.includes("new-user") &&
      !location.pathname.includes("new-poll")
    ) {
      navigate("/new-user");
    }
  }, [user?.id, orgs?.length, location.pathname]);

  useEffect(() => {
    if (orgs?.length && selectedOrg) {
      const userOrg = orgs.find(
        (org: Organisation) => org.id === selectedOrg.id
      );

      if (userOrg && !isEqual(userOrg, selectedOrg)) {
        storeItem(SELECTED_ORG_COOKIE_NAME, userOrg, 1);
        setSelectedOrg(userOrg);
      }
    }
  }, [JSON.stringify(orgs || []), selectedOrg, selectedOrg?.id]);

  return (
    <UserContext.Provider
      value={{
        user:
          user && orgs
            ? {
                ...user,
                orgs: orgs || [],
              }
            : undefined,
        selectedOrg: selectedOrg as Organisation,
        setSelectedOrg: handleSetSelectedOrg,
        createOrg: handleCreateOrg,
        updateOrg: handleUpdateOrg,
        deleteMe: handleDeleteMe,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
