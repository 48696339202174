import React, { FC, useContext } from "react";
import PollScoreChart from "./PollScoreChart";
import { Box } from "@chakra-ui/react";
import SwitchPollScoreView from "./SwitchPollScoreView";
import { PollScoreVariant } from "../types/data";
import FormScale from "../../../core/components/FormScale";
import { PollDataContext } from "../contexts/PollDataContext";

const PollScoreDisplay: FC = () => {
  const { pollScoreVariant } = useContext(PollDataContext);

  return (
    <Box position={"relative"}>
      <Box>
        <SwitchPollScoreView />
      </Box>
      {pollScoreVariant === PollScoreVariant.ScoreAmounts && (
        <FormScale position={"absolute"} top={`calc(100% + 15px)`} left={0} />
      )}
      <PollScoreChart />
    </Box>
  );
};

export default PollScoreDisplay;
