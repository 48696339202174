import React, { FC, ReactNode } from "react";
import { Center, CenterProps, Divider, Heading } from "@chakra-ui/react";

interface Props extends CenterProps {
  afterHeading?: () => ReactNode;
}

const HeroHeading: FC<Props> = ({ children, afterHeading, ...props }) => {
  return (
    <>
      <Center minH={"250px"} {...props}>
        <div>
          <Heading variant={"hero"}>{children}</Heading>
          {afterHeading?.()}
        </div>
      </Center>
      <Divider />
    </>
  );
};

export default HeroHeading;
