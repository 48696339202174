const colors = {
  body: "#F9F9F9",
  primary: "#133567",
  error: "#B73C21",
  success: "#3E77F7",
  successDarker: "#306cf3",
  form: {
    10: "#387CFF",
    9: "#6296FC",
    8: "#73BEE8",
    7: "#78D1CC",
    6: "#48A791",
    5: "#E5AC3D",
    4: "#E5833D",
    3: "#E55B3D",
    2: "#B73C21",
    1: "#252729",
  },
  link: "#387eff",
  grey: {
    1: "#C4CAD8",
    2: "#B2B2B2",
    3: "#717171",
    4: "#DEDADA",
    5: "#D9D9D9",
    6: "#303030",
  },
};
export default colors;
