import React, { FC, useContext } from "react";
import Layout from "../../../core/components/Layout";
import Polls from "./Polls";
import { UserContext } from "../../../core/contexts/UserContext";

const PollsPage: FC = () => {
  const { selectedOrg } = useContext(UserContext);

  return (
    <Layout
      title={"FormScore Polls"}
      subTitle={
        selectedOrg?.is_parent
          ? `You are in a parent entity which allows you to view the aggregated analytics for a number of sub teams or divisions. Polls created here should be for the level of the organisation you are in. For example, if this is the top level parent you could run polls for “All Hands Calls”. If this parent org is for a Function you could create “Finance Function Off-site”.`
          : `Create and view FormScore polls for your team.`
      }
    >
      <Polls />
    </Layout>
  );
};

export default PollsPage;
