import superagent from "superagent";
import { getAuthToken, isLoggedIn, logout } from "./auth";
import useSWR from "swr";
// @ts-ignore
import saveAs from "file-saver";

type RequestArgs = {
  url: string;
  method: "get" | "post" | "patch" | "delete";
  data?: any;
  headers?: any;
};

export type ApiResponse<T = any> = {
  status: "SUCCESS" | "ERROR";
  payload: T;
};

export const api = {
  createHeaders(headers?: RequestArgs["headers"]) {
    const finalHeaders = {
      ...(headers || {}),
      "x-app-token": process.env.REACT_APP_APP_TOKEN || "web",
      "Content-Type": "application/json",
    };

    if (getAuthToken()) {
      finalHeaders["x-user-token"] = getAuthToken();
    }

    return finalHeaders;
  },

  async request<T = any>({
    url,
    method,
    data,
    headers,
  }: RequestArgs): Promise<ApiResponse<T> | null> {
    if (!url) {
      return null;
    }

    const req = superagent[method](`${process.env.REACT_APP_API_URL}/${url}`);
    const finalHeaders = this.createHeaders(headers);

    if (data) {
      if (method === "get") {
        req.query(data);
      }

      if (method === "post" || method === "patch" || method === "delete") {
        req.send(data);
      }
    }

    req.set(finalHeaders);

    try {
      const result = await req;
      const body = result.body;

      return {
        status: "SUCCESS",
        payload: body,
      };
    } catch (e: any) {
      if (e?.response?.body?.error) {
        e.message = e.response.body.error;
      }
      throw e;
    }
  },

  get<T = any>(args: Omit<RequestArgs, "method">) {
    return this.request<T>({
      ...args,
      method: "get",
    });
  },

  post<T = any>(args: Omit<RequestArgs, "method">) {
    return this.request<T>({
      ...args,
      method: "post",
    });
  },

  patch<T = any>(args: Omit<RequestArgs, "method">) {
    return this.request<T>({
      ...args,
      method: "patch",
    });
  },

  delete<T = any>(args: Omit<RequestArgs, "method">) {
    return this.request<T>({
      ...args,
      method: "delete",
    });
  },

  async download(args: Omit<RequestArgs, "method">, fileName: string) {
    const headers = this.createHeaders();
    headers["Content-Type"] = "text/csv";

    fetch(`${process.env.REACT_APP_API_URL}/${args.url}`, {
      headers,
      responseType: "blob",
    } as any)
      .then((response) => response.blob())
      .then((blob) => saveAs(blob, fileName));
  },
};

export enum ApiState {
  Idle,
  Loading,
  Error,
  Success,
}

export const useApi = <T = any>(
  url: string,
  args?: Omit<RequestArgs, "method" | "url">,
  options?: {
    defaultValue?: T;
    revalidateOnFocus?: boolean;
    onSuccess?: (res: T) => void;
  }
): [ApiState, T | null | undefined] => {
  const { data, error, isValidating } = useSWR(
    url,
    () =>
      api
        .get({
          url,
          ...args,
        })
        .then((res) => {
          options?.onSuccess?.(res?.payload);
          return res;
        }),
    {
      // revalidateIfStale: false,
      revalidateOnFocus: !!options?.revalidateOnFocus,
    }
  );

  if (error) {
    if (error?.status === 401) {
      if (isLoggedIn()) {
        logout();
      }
    }

    return [ApiState.Error, null];
  }

  if (isValidating) {
    return [ApiState.Loading, data?.payload || options?.defaultValue];
  }

  if (!isValidating && !data) {
    return [ApiState.Idle, options?.defaultValue];
  }

  return [ApiState.Success, data?.payload || (options?.defaultValue as T)];
};
