import React, { FC } from "react";
import { ApiState, useApi } from "../../../core/services/api";
import PollStatDisplay, { PollStatDisplayProps } from "./PollStatDisplay";

interface Props extends Omit<PollStatDisplayProps, "stat"> {
  url: string;
}

const PollStat: FC<Props> = ({ url, ...props }) => {
  const [loading, result] = useApi(url);

  return (
    <PollStatDisplay
      stat={result?.stat}
      loading={loading !== ApiState.Success}
      variant={"titleTop"}
      {...props}
    />
  );
};

export default PollStat;
