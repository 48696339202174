import React, { FC } from "react";
import { HStack, Square, Stack, StackProps, Text } from "@chakra-ui/react";

interface Props extends StackProps {
  items: {
    title: string;
    color: string;
  }[];
}

const Legend: FC<Props> = ({ items, ...props }) => {
  return (
    <Stack direction={"row"} alignItems={"center"} {...props}>
      {items.map((item) => (
        <HStack key={item.title}>
          <Square size={"20px"} bg={item.color} />
          <Text fontSize={"14px"}>{item.title}</Text>
        </HStack>
      ))}
    </Stack>
  );
};

export default Legend;
