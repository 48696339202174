import { createContext, useContext } from "react";
import { FCWithChildren } from "../../../core/types/components";
import { api, useApi } from "../../../core/services/api";
import { useParams } from "react-router-dom";
import { mutate } from "swr";
import { addCustomDriver } from "../services/drivers";
import { UserContext } from "../../../core/contexts/UserContext";
import { Driver, DriverType, FormCategory } from "core/types/form";

export type IPollDriversContext = {
  drivers: Driver[];
  missingDrivers: Driver[];
  driverTypes: DriverType[];
  categories: FormCategory[];
  remove: (driverId: string) => Promise<void>;
  add: (driverIds: string[]) => Promise<void>;
  create: (driver: Partial<Driver>) => Promise<Driver | void>;
};

export const PollDriversEditContext = createContext<IPollDriversContext>({
  drivers: [],
  categories: [],
  missingDrivers: [],
  driverTypes: [],
  remove: async () => {},
  add: async () => {},
  create: async () => {},
});

export const PollDriversEditProvider: FCWithChildren = ({ children }) => {
  const { id, instanceId } = useParams();
  const { selectedOrg } = useContext(UserContext);
  const [, categories] = useApi("form-categories");

  const getQuery = () => {
    return instanceId ? "pollInstanceId=" + instanceId : "pollId=" + id;
  };

  const url = `polls/reasons?${getQuery()}`;
  let driversUrls = "drivers";

  if (selectedOrg) {
    driversUrls += `?organisation_id=${selectedOrg.id}`;
  }

  const [, allDrivers] = useApi(driversUrls);
  const [, drivers] = useApi(url);
  const [, driverTypes] = useApi("drivers/types");
  const missingDrivers =
    allDrivers?.filter?.((driver: Driver) => {
      return driver.type && drivers?.every((d: Driver) => d.id !== driver.id);
    }) || [];

  const handleDriverRemove = async (driverId: string) => {
    await api.delete({
      url: `polls/reasons`,
      data: {
        pollId: !instanceId ? id : null,
        pollInstanceId: instanceId,
        reasonId: driverId,
      },
    });

    await mutate(url);
  };

  const handleDriversAdd = async (driverIds: string[]) => {
    await api.post({
      url: "polls/reasons",
      data: {
        pollId: !instanceId ? id : null,
        pollInstanceId: instanceId,
        reasonIds: driverIds,
      },
    });

    await mutate(url);
  };

  const handleDriverCreate = async (driver: Partial<Driver>) => {
    const result = await addCustomDriver(driver, selectedOrg);
    await mutate(driversUrls);
    return result;
  };

  return (
    <PollDriversEditContext.Provider
      value={{
        drivers: drivers || [],
        driverTypes: driverTypes || [],
        categories: categories || [],
        missingDrivers,
        remove: handleDriverRemove,
        add: handleDriversAdd,
        create: handleDriverCreate,
      }}
    >
      {children}
    </PollDriversEditContext.Provider>
  );
};
