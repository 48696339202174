import React, { FC, Fragment, useContext } from "react";
import { Box, BoxProps, Grid, Heading, VStack, Wrap } from "@chakra-ui/react";
import FormCircle from "../../../core/components/FormCircle";
import { StatTypeUrl } from "../constants/urls";
import PollStat from "./PollStat";
import { buildStatUrlQuery, usePollIdUrlQuery } from "../services/polls";
import PollStatDriver from "./PollStatDriver";
import { UserContext } from "core/contexts/UserContext";
import { AnalyticsContext } from "../../analytics/contexts/AnalyticsContext";
import { StatDriver } from "core/types/form";

interface Props extends BoxProps {
  showCounts?: boolean;
}

const PollStats: FC<Props> = ({ showCounts, ...props }) => {
  const { selectedOrg } = useContext(UserContext);
  const { from, to } = useContext(AnalyticsContext);
  const pollUrlQuery = usePollIdUrlQuery();
  const buildQuery = (extraArgs?: Record<string, any>) => {
    return `?${buildStatUrlQuery({
      from: from as string,
      to: to as string,
      selectedOrg,
      urlQuery: pollUrlQuery,
      ...extraArgs,
    })}`;
  };

  const driverRender =
    ({ color }: { color: string }) =>
    (drivers: StatDriver[]) => {
      if (!drivers || !drivers[0]) {
        return <Fragment />;
      }

      return (
        <VStack w={"100%"}>
          {drivers.map((driver) => (
            <PollStatDriver
              key={driver.id}
              color={color}
              driver={driver}
              variant={"row"}
            />
          ))}
        </VStack>
      );
    };

  const minimumCardWidth = "275px";

  return (
    <Box {...props}>
      <Heading variant={"section"} mb={4}>
        Poll Stats
      </Heading>
      <Grid
        gridTemplateColumns={
          showCounts
            ? `repeat(3, ${minimumCardWidth})`
            : `repeat(auto-fill, ${minimumCardWidth})`
        }
        gridGap={5}
        mb={5}
      >
        <PollStat
          minW={minimumCardWidth}
          title={"Highest Score"}
          url={`${StatTypeUrl.HighestScore}${buildQuery()}`}
          render={(stat) => <FormCircle score={stat}>{stat}</FormCircle>}
        />
        <PollStat
          minW={minimumCardWidth}
          title={"Average Score"}
          url={`${StatTypeUrl.AvgScore}${buildQuery()}`}
          render={(stat) => <FormCircle score={stat}>{stat}</FormCircle>}
        />
        <PollStat
          minW={minimumCardWidth}
          title={"Lowest Score"}
          url={`${StatTypeUrl.LowestScore}${buildQuery()}`}
          render={(stat) => <FormCircle score={stat}>{stat}</FormCircle>}
        />
        {showCounts && (
          <>
            <PollStat
              minW={minimumCardWidth}
              url={`${StatTypeUrl.PollCount}${buildQuery()}`}
              title={"Number of Polls"}
            />
            <PollStat
              minW={minimumCardWidth}
              url={`${StatTypeUrl.ParticipantCount}${buildQuery()}`}
              title={"Number of Scores"}
            />
          </>
        )}
        <PollStat
          minW={minimumCardWidth}
          title={"Average no. of Participants"}
          url={`${StatTypeUrl.AvgScoreCount}${buildQuery()}`}
        />
      </Grid>
      <Wrap spacing={5} mb={5}>
        <PollStat
          minW={minimumCardWidth}
          title={"Most Selected Drivers"}
          url={`${StatTypeUrl.TopDrivers}${buildQuery({
            limit: 5,
          })}`}
          justifyContent={"flex-start"}
          render={driverRender({ color: "form.10" })}
        />
        <PollStat
          minW={minimumCardWidth}
          title={"Top Boosting Drivers"}
          url={`${StatTypeUrl.TopDrivers}${buildQuery({
            limit: 5,
            trend: 1,
          })}`}
          justifyContent={"flex-start"}
          render={driverRender({ color: "form.10" })}
        />
        <PollStat
          minW={minimumCardWidth}
          title={"Top Lowering Drivers"}
          url={`${StatTypeUrl.TopDrivers}${buildQuery({
            limit: 5,
            trend: -1,
          })}`}
          justifyContent={"flex-start"}
          render={driverRender({ color: "form.4" })}
        />
      </Wrap>
    </Box>
  );
};

export default PollStats;
