import React, { FC } from "react";
import PollTimeSeriesGraph from "./PollTimeSeriesGraph";
import PollStats from "./PollStats";
import { Box, BoxProps, Heading } from "@chakra-ui/react";
import AnalyticsTimeControls from "../../analytics/components/AnalyticsTimeControls";
import PollLocationStats from "./PollLocationStats";
import PollFormScore from "./PollFormScore";
import DriversWordCloud from "modules/polls/components/DriversWordCloud";

interface Props extends BoxProps {}

const PollGroupAnalytics: FC = (props) => {
  return (
    <Box position={"relative"} {...props}>
      <PollFormScore
        position={"absolute"}
        top={"-130px"}
        left={"50%"}
        transform={"translateX(-50%)"}
      />
      <Heading variant={"section"} mb={3} pt={"40px"}>
        FormScore Analytics
      </Heading>
      <AnalyticsTimeControls mb={4} />
      <PollTimeSeriesGraph mb={10} />
      <PollStats mb={10} showCounts />
      <PollLocationStats />
      <DriversWordCloud mt={10} />
    </Box>
  );
};

export default PollGroupAnalytics;
