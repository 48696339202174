import React, { FC, useContext } from "react";
import { Button } from "@chakra-ui/react";
import { PollDataContext } from "../contexts/PollDataContext";
import { PollScoreVariant } from "../types/data";

const SwitchPollScoreView: FC = () => {
  const { pollScoreVariant, setPollScoreVariant } = useContext(PollDataContext);
  return (
    <>
      <Button
        variant={"outline"}
        color={"black"}
        borderColor={"black"}
        onClick={() => {
          const newVariant =
            pollScoreVariant === PollScoreVariant.AllScores
              ? PollScoreVariant.ScoreAmounts
              : PollScoreVariant.AllScores;
          setPollScoreVariant(newVariant);
        }}
      >
        Switch View
      </Button>
    </>
  );
};

export default SwitchPollScoreView;
