import React, { FC } from "react";
import SetDrivers from "./SetDrivers";
import { Box, Heading } from "@chakra-ui/react";
import { format } from "date-fns";
import SetLocation from "./SetLocation";

const PollDriversPage: FC = () => {
  return (
    <Box
      height={"calc(100vh - 86px)"}
      p={4}
      maxWidth={"480px"}
      mx={"auto"}
      userSelect={"none"}
      sx={{
        touchAction: "manipulation",
      }}
    >
      <Heading
        variant={"section"}
        mb={5}
        pb={3}
        borderBottom={"1px"}
        borderColor={"grey.1"}
      >
        {format(new Date(), "EEEE, MMM yy")}{" "}
        <Box as={"span" as any} color={"grey.1"}>
          ({format(new Date(), "h:mm")})
        </Box>
      </Heading>
      <SetLocation mb={5} />
      <SetDrivers />
    </Box>
  );
};

export default PollDriversPage;
