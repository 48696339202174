import { addHours, format, isBefore, isValid, subMonths } from "date-fns";
import { ChangeEvent, useState } from "react";
import { TimePeriod, timePeriodToMonthNumber } from "../types/dates";

export function formatDateTimeForInput(dateStr: string) {
  if (!dateStr) {
    return undefined;
  }

  // * using the T in the date-fns format returns weird result
  return format(new Date(dateStr), "yyyy-MM-dd HH:mm").replace(" ", "T");
}

export function getDateFromDateTimeInput(
  e: ChangeEvent<HTMLInputElement>
): Date {
  return new Date(e.target.value.replace("T", " "));
}

export function useTimeControls(initialEarliestFrom?: string) {
  const [earliestFrom, setEarliestFrom] = useState<string | undefined>(
    initialEarliestFrom
  );
  const [from, setFrom] = useState<string | undefined>(undefined);
  const [to, setTo] = useState<string | undefined>(undefined);
  const [timePeriod, setTimePeriod] = useState<TimePeriod | undefined>(
    undefined
  );

  const handleSetFrom = (newFrom: string) => {
    const newFromDate = new Date(newFrom);
    if (!isValid(newFromDate)) {
      return;
    }

    setFrom(newFrom);
  };

  const handleSetTimePeriod = (timePeriod: TimePeriod) => {
    if (!timePeriod || timePeriod === TimePeriod.Custom) {
      setFrom(undefined);
      setTo(undefined);
      setTimePeriod(timePeriod);
      return;
    }

    // todo: not sure this is even needed. Might be able to just delete
    const toDate = addHours(new Date(), 1);
    let fromDate = subMonths(toDate, timePeriodToMonthNumber[timePeriod]);

    if (earliestFrom && isBefore(fromDate, new Date(earliestFrom))) {
      fromDate = new Date(earliestFrom);
    }

    const from = fromDate.toISOString();
    const to = toDate.toISOString();

    setFrom(from);
    setTo(to);
    setTimePeriod(timePeriod);
  };

  return {
    from,
    setFrom: handleSetFrom,
    setEarliestFrom,
    to,
    setTo,
    timePeriod,
    setTimePeriod: handleSetTimePeriod,
  };
}
