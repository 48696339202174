import React, { FC, useContext } from "react";
import { Box, BoxProps, Button, Heading, HStack, Text } from "@chakra-ui/react";
import { UserContext } from "../../../core/contexts/UserContext";
import { PermissionsContext } from "../../../core/contexts/PermissionsContext";
import CancelSubscription from "./CancelSubscription";
import { format } from "date-fns";

interface Props extends BoxProps {}

const SubscriptionSettings: FC<Props> = (props) => {
  const { selectedOrg } = useContext(UserContext);
  const { activeProduct } = useContext(PermissionsContext);

  return (
    <Box {...props}>
      <Heading variant={"section"} mb={4}>
        Subscription Settings
      </Heading>
      {(!activeProduct && (
        <Text>You do not currently have an active subscription</Text>
      )) || (
        <Box>
          <Text>
            {selectedOrg.name} is currently subscribed to the{" "}
            {activeProduct?.product?.name} subscription.
          </Text>
          {activeProduct?.trial_expires_at && (
            <Text mt={2}>
              Your current subscription will end on{" "}
              <strong>
                {format(new Date(activeProduct.trial_expires_at), "dd/MM/yyyy")}
              </strong>
            </Text>
          )}
          {!activeProduct?.trial && (
            <HStack mt={4}>
              <CancelSubscription />
              <Button
                as={"a"}
                href={"https://billing.stripe.com/p/login/9AQcQm0nha3Dakw6oo"}
                target={"_blank"}
              >
                Billing Portal
              </Button>
            </HStack>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SubscriptionSettings;
