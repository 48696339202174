import React, { FC, useContext } from "react";
import { Box, BoxProps, Select } from "@chakra-ui/react";
import { UserContext } from "../contexts/UserContext";
import {
  buildOrgHierarchy,
  flattenOrgHierarchy,
} from "modules/teams/services/teams";
import { Organisation } from "core/types/user";
import { TeamMember } from "modules/teams/types/teams";

interface Props extends BoxProps {}

const SelectOrg: FC<Props> = (props) => {
  const { user, selectedOrg, setSelectedOrg } = useContext(UserContext);

  if (!user || !user.orgs?.length) {
    return null;
  }

  // * Sort all orgs in order: parent, child child, parent, child... etc
  // * might be better to move this to BE? Not sure. Works for now but is convoluted
  const parents = user.orgs.filter((o) => !o.parent_id);
  const allChildren = user.orgs.filter((o) => o.parent_id);
  let orgs = parents.reduce((acc, o) => {
    const hierarchy = buildOrgHierarchy([o, ...allChildren]);
    return [...acc, ...flattenOrgHierarchy(hierarchy as TeamMember)];
  }, [] as Organisation[]);
  const childOrgsWithNoParent = allChildren.filter(o => !orgs.some(co => co.id === o.id));

  orgs = [
      ...orgs,
      ...childOrgsWithNoParent
  ]

  return (
    <Box {...props}>
      <Select
        bg={"white"}
        value={selectedOrg?.id}
        onChange={(e) => {
          const org = user?.orgs?.find?.((org) => org.id === e.target.value);

          if (selectedOrg?.id && org?.id === selectedOrg.id) {
            return null;
          }

          if (org) {
            setSelectedOrg(org);
          } else {
            setSelectedOrg(undefined);
          }
        }}
      >
        {orgs.map((org) => (
          <option key={org.id} value={org.id}>
            {org.short_name || org.name}
          </option>
        ))}
      </Select>
    </Box>
  );
};

export default SelectOrg;
