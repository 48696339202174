import React, { FC } from "react";
import Layout from "../../../core/components/Layout";
import NewUserTeamForm from "./NewUserTeamForm";
import { addNewPollForOrg } from "../../polls/services/polls";

const NewUserPage: FC = () => {
  return (
    <Layout title={"Your Team"} hideMenu>
      <NewUserTeamForm
        onSubmit={async (org) => {
          const result = await addNewPollForOrg(org);

          if (result && result.poll && result.instance) {
            window.location.href = `/p/${result.poll.short_id}`;
          }
        }}
      />
    </Layout>
  );
};

export default NewUserPage;
