import React, { FC, useContext } from "react";
import { PollsContext } from "../contexts/PollsContext";
import { useFormik } from "formik";
import { Box, BoxProps, Button, Flex, Select } from "@chakra-ui/react";
import FormItem from "../../../core/components/FormItem";

interface Props extends BoxProps {
  onCreated?: () => void;
}

const NewPollInstanceForm: FC<Props> = ({ onCreated, ...props }) => {
  const { polls, startNewPoll, createAndStartPoll } = useContext(PollsContext);
  const formik = useFormik({
    initialValues: {
      pollId: "",
      name: "",
    },
    validate: (values) => {
      const errors = {} as any;

      if (!values.pollId) {
        errors.pollId = "Poll Bundle required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      if (values.name) {
        await createAndStartPoll({
          name: values.name,
          capture_drivers: true,
          capture_location: true,
          capture_categories: true,
        });
        onCreated?.();
        return;
      }

      const poll = polls.find((p) => p.id === values.pollId);

      if (poll) {
        await startNewPoll(poll);
        onCreated?.();
      }
    },
  });

  return (
    <Box {...props}>
      <form onSubmit={formik.handleSubmit}>
        <FormItem
          name={"pollId"}
          label={"Poll Bundle"}
          helperText={"Choose the bundle for this new poll"}
          errorMessage={formik.errors.pollId}
          inputComponent={
            <Select
              value={formik.values.pollId}
              name={"pollId"}
              onChange={(e) => {
                formik.setValues({
                  name: "",
                  pollId: e.target.value,
                });
              }}
            >
              <option value="">Select Poll Bundle</option>
              <option value="new-bundle">Create New Poll Bundle</option>
              {polls.map((poll) => (
                <option value={poll.id} key={poll.id}>
                  {poll.name}
                </option>
              ))}
            </Select>
          }
          mb={5}
        />
        {formik.values.pollId === "new-bundle" && (
          <FormItem
            name={"name"}
            label={"Bundle Name"}
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        )}
        <Flex justifyContent={"flex-end"} w={"100%"}>
          <Button type={"submit"} mt={6}>
            Create
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default NewPollInstanceForm;
