import React, { FC, useContext, useEffect, useRef } from "react";
import QRCode from "qrcode";
import { Box, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { PollContext } from "../contexts/PollContext";
import { MdOutlineContentCopy } from "react-icons/md";

interface Props {}

const QrCode: FC<Props> = () => {
  const ref = useRef(null);
  const { poll } = useContext(PollContext);
  const { number } = useParams();

  let qrPath = poll.short_id ? `p/${poll.short_id}/score` : "";

  if (number) {
    qrPath = `p/${poll.short_id}/${number}/score`;
  }

  const refCurrent = ref.current;
  const url = `${window.location.origin}/${qrPath}`;

  useEffect(() => {
    if (ref.current) {
      QRCode.toCanvas(
        refCurrent,
        url,
        {
          margin: 1,
          width: 180,
          errorCorrectionLevel: "H",
        },
        (error) => {
          if (error) {
            console.error(error);
            alert("Error with qr code");
          }
        }
      );
    }
  }, [refCurrent, url]);

  return (
    <Box position={"relative"}>
      <Box as={"canvas"} ref={ref} width={"100%"} height={"100%"} />
      <InputGroup position={"absolute"} top={"102%"} left={0}>
        <Input readOnly value={url} />
        <InputRightElement
          userSelect={"none"}
          _active={{
            transform: "scale(0.9)",
          }}
        >
          <MdOutlineContentCopy
            cursor={"pointer"}
            onClick={() => {
              navigator.clipboard.writeText(url);
            }}
          />
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};

export default QrCode;
