import React, { FC, useContext } from "react";
import { Flex, HStack, Image, Spinner, Text, VStack } from "@chakra-ui/react";
import { getDefaultEmojiUrl } from "../services/drivers";
import { ApiState, useApi } from "../../../core/services/api";
import { StatTypeUrl } from "../constants/urls";
import { usePollIdUrlQuery } from "../services/polls";
import { Poll, PollInstance } from "../types/polls";
import { UserContext } from "../../../core/contexts/UserContext";
import { useParams } from "react-router-dom";
import { InsightDriver } from "core/types/form";

interface Props {
  poll?: Poll | PollInstance;
}

const PollInsightsFeed: FC<Props> = ({ poll }) => {
  const idQuery = usePollIdUrlQuery();
  const { id, instanceId } = useParams();
  const { selectedOrg } = useContext(UserContext);
  const query = `?${idQuery}${
    !idQuery
      ? `args=${JSON.stringify({
          orgId: selectedOrg.id,
        })}`
      : ""
  }`;
  const [participantState, participantsResult] = useApi(
    `${StatTypeUrl.ParticipantCount}${query}`
  );
  const [insightsState, insights] = useApi<{ stat: InsightDriver[] }>(
    `${StatTypeUrl.Insights}${query}`
  );

  const [pollCountState, pollCountResult] = useApi<{ stat: number }>(
    !instanceId ? `${StatTypeUrl.PollCount}${query}` : ""
  );

  const participantCount = participantsResult?.stat;
  const pollCount = pollCountResult?.stat;

  if (
    participantState !== ApiState.Success ||
    insightsState !== ApiState.Success ||
    (!instanceId && pollCountState !== ApiState.Success)
  ) {
    return <Spinner size={"80px"} />;
  }

  if (participantState === ApiState.Success && !participantCount) {
    return (
      <Text fontSize={"24px"}>
        Your insights feed will populate here when people start taking{" "}
        {idQuery ? "the poll" : "your polls"}
      </Text>
    );
  }

  const getDescription = () => {
    const people = participantCount === 1 ? "person has" : "people have";
    if (!id && !instanceId) {
      return `So far, ${participantCount} ${people} taken part across ${pollCount} polls.`;
    }

    if (instanceId) {
      return `So far, ${participantCount} ${people} taken part in this poll.`;
    }

    if (id && poll && pollCount) {
      return `In the ${poll.name} bundle, ${participantCount} ${people} taken part across ${pollCount} polls.`;
    }
  };

  return (
    <VStack spacing={8} justifyContent={"flex-start"} alignItems={"flex-start"}>
      <Text fontSize={"24px"}>{getDescription()}</Text>
      {insights?.stat?.map?.((driver) => {
        return (
          <HStack
            maxW={"700px"}
            alignItems={"flex-start"}
            spacing={6}
            key={driver.id}
          >
            <Flex w={"50px"} justifyContent={"center"}>
              <Image
                src={getDefaultEmojiUrl(driver)}
                w={"auto"}
                h={"auto"}
                maxW={"50px"}
                maxH={"50px"}
              />
            </Flex>
            <Text>
              <strong>{driver.count}</strong> participant
              {driver.count !== 1 ? "s" : ""} chose{" "}
              <strong>{driver.title}</strong> as their{" "}
              <strong>most lowering</strong> driver. {driver.insight.content}
            </Text>
          </HStack>
        );
      })}
    </VStack>
  );
};

export default PollInsightsFeed;
