import React, { FC } from "react";
import { Link, matchRoutes, useLocation } from "react-router-dom";
import { routes } from "../../routes";
import { Breadcrumb, BreadcrumbItem } from "@chakra-ui/react";

const AppBreadcrumbs: FC = () => {
  const location = useLocation();
  const matches = matchRoutes(Object.values(routes), location);
  const breadcrumbs = matches?.[0]?.route?.breadcrumbs?.();

  if (!breadcrumbs) {
    return null;
  }

  return (
    <Breadcrumb p={4}>
      {breadcrumbs.map((breadcrumb) => {
        return (
          <BreadcrumbItem
            key={breadcrumb.title}
            isCurrentPage={!!breadcrumb.current}
          >
            <Link to={breadcrumb.link}>{breadcrumb.title}</Link>
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

export default AppBreadcrumbs;
