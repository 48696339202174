import React, { FC, ReactNode } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardProps,
  Heading,
  Skeleton,
} from "@chakra-ui/react";

export type DisplayCardProps = {
  title: ReactNode;
  loading?: boolean;
  variant?: "titleTop" | "titleBottom";
} & Omit<CardProps, "title">;

const DisplayCard: FC<DisplayCardProps> = ({
  title,
  loading,
  children,
  variant = "titleBottom",
  ...props
}) => {
  const renderTitle = () => {
    const Container = variant === "titleTop" ? CardHeader : CardFooter;

    return (
      <Skeleton isLoaded={!loading} w={"100%"}>
        <Container pb={(variant === "titleTop" && 1) || ""}>
          <Heading size={"md"} textAlign={"center"} w={"100%"}>
            {title}
          </Heading>
        </Container>
      </Skeleton>
    );
  };

  return (
    <Card position={"relative"} bg={"white"} {...props}>
      {variant === "titleTop" && renderTitle()}
      <Skeleton minH={loading ? "150px" : ""} w={"100%"} isLoaded={!loading}>
        <CardBody
          justifyContent={"center"}
          display={"flex"}
          flexDirection={"column"}
        >
          {children}
        </CardBody>
      </Skeleton>
      {variant === "titleBottom" && renderTitle()}
    </Card>
  );
};

export default DisplayCard;
