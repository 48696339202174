import { createContext } from "react";
import { PollInstance } from "../types/polls";
import { FCWithChildren } from "../../../core/types/components";
import { useApi } from "../../../core/services/api";
import { useParams } from "react-router-dom";

export type IPollInstanceContext = {
  instance: PollInstance;
};

export const PollInstanceContext = createContext<IPollInstanceContext>({
  instance: {} as PollInstance,
});

export const PollInstanceProvider: FCWithChildren = ({ children }) => {
  const { instanceId } = useParams();
  const [, instance] = useApi(`polls/instances/${instanceId}`);

  if (!instance) {
    return null;
  }

  return (
    <PollInstanceContext.Provider
      value={{
        instance,
      }}
    >
      {children}
    </PollInstanceContext.Provider>
  );
};
