import React, { FC } from "react";
import PollStats from "./PollStats";
import PollLocationStats from "./PollLocationStats";
import { Box, BoxProps } from "@chakra-ui/react";
import PollFormScore from "./PollFormScore";
import DriversWordCloud from "modules/polls/components/DriversWordCloud";

interface Props extends BoxProps {}

const PollInstanceAnalytics: FC<Props> = (props) => {
  return (
    <Box position={"relative"} {...props}>
      <PollFormScore
        position={"absolute"}
        top={"-170px"}
        left={"50%"}
        transform={"translateX(-50%)"}
      />
      <PollStats mb={10} mt={10} pt={5} />
      <PollLocationStats />
      <DriversWordCloud mt={10} />
    </Box>
  );
};

export default PollInstanceAnalytics;
