import React, { FC } from "react";
import {
  Button,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text,
  VStack,
} from "@chakra-ui/react";

interface Props extends Omit<ModalProps, "children"> {}

const TrialStartedModal: FC<Props> = (props) => {
  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent maxW={"550px"} textAlign={"center"} pb={8}>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={5} mb={10}>
            <Heading fontSize={"50px"} mb={6}>
              You're in!
            </Heading>
            <Text fontSize={"24px"} px={6}>
              Thank you. You've successfully activated your 2-week free trial.
            </Text>
            <Image
              src={"/lego-2.jpg"}
              w={"200px"}
              borderRadius={"50px"}
              overflow={"hidden"}
            />
            <Button
              as={"a"}
              href={"https://www.formscore.today/talks/checkin-trial"}
              target={"_blank"}
            >
              Watch Video
            </Button>
          </VStack>
          <VStack spacing={5}>
            <Text fontSize={"24px"} px={8}>
              Check out our "How To" guide on running your first poll.
            </Text>
            <Button
              as={"a"}
              href={"https://www.formscore.today/faq/check-in-run-poll"}
              target={"_blank"}
            >
              How to run a poll
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TrialStartedModal;
