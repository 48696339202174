import React, { FC, useContext } from "react";
import { ApiState, useApi } from "../../../core/services/api";
import { useStatUrlQuery } from "../services/polls";
import { AnalyticsContext } from "../../analytics/contexts/AnalyticsContext";
import { StatTypeUrl } from "../constants/urls";
import {
  Box,
  BoxProps,
  Heading,
  HStack,
  Image,
  Spinner,
  Text,
  Wrap,
} from "@chakra-ui/react";
import DisplayCard from "../../../core/components/DisplayCard";
import FormMultiCircle from "../../../core/components/FormMultiCircle";
import { getEmojiUrl } from "../services/drivers";

type LocationStat = {
  title: string;
  emoji: string;
  score: {
    avg: number;
  };
  categories: {
    avg: number;
    color: string;
  }[];
};

interface Props extends BoxProps {}

const PollLocationStats: FC<Props> = (props) => {
  const { from, to } = useContext(AnalyticsContext);
  const urlQuery = useStatUrlQuery(from as string, to as string);
  const url = `${StatTypeUrl.LocationScores}?${urlQuery}`;
  const [loading, locationStatsResult] = useApi<{ stat: LocationStat[] }>(url);
  const locationStats = locationStatsResult?.stat || [];

  return (
    <Box {...props}>
      <Heading variant={"section"} mb={5}>
        Ways of working and performance readiness
      </Heading>
      {loading !== ApiState.Success && <Spinner />}
      {loading === ApiState.Success && !locationStats?.length && (
        <Text>No data available yet</Text>
      )}
      <Wrap spacing={5}>
        {locationStats.map((stat) => (
          <DisplayCard
            key={stat.title}
            title={
              <HStack
                spacing={2}
                as={"span"}
                w={"100%"}
                justifyContent={"center"}
              >
                <Image src={getEmojiUrl(stat.emoji)} w={"40px"} />
                <span>{stat.title}</span>
              </HStack>
            }
            variant={"titleTop"}
          >
            <FormMultiCircle
              categoryScores={stat.categories}
              score={stat.score.avg}
              size={210}
            />
          </DisplayCard>
        ))}
      </Wrap>
    </Box>
  );
};

export default PollLocationStats;
