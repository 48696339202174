import React, { FC, useContext } from "react";
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import { UserContext } from "../../../core/contexts/UserContext";
import Logo from "core/components/Logo";
import QuickPollForm from "./QuickPollForm";

const HomePage: FC = () => {
  const { user } = useContext(UserContext);

  return (
    <Box
      h={"100vh"}
      bg={"#13386F"}
      position={"relative"}
      color={"white"}
      overflow={"hidden"}
    >
      <Image
        src={"/hero-bg.jpg"}
        w={"100%"}
        h={"auto"}
        position={"absolute"}
        bottom={0}
        right={0}
      />
      <Image
        src={"/hero-bg-asset.png"}
        w={"400px"}
        h={"auto"}
        position={"absolute"}
        right={"10%"}
        top={"30%"}
      />
      <Box position={"relative"} px={10} pt={5}>
        <Flex
          w={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={20}
        >
          <Logo w={"110px"} h={"auto"} display={"block"} />

          <Text
            as={"a"}
            href={(!user && "/login") || "/polls"}
            color={"white"}
            p={4}
          >
            {(!user && "Login") || "My Polls"}
          </Text>
        </Flex>
        <Box>
          <Heading variant={"hero"} mb={8}>
            FormScore for Teams
          </Heading>
          <Heading fontSize={["16px", "22px", "44px"]} maxW={"1000px"} mb={6}>
            Understand what is driving your team’s wellbeing and readiness to
            perform in a few minutes as part of your team meetings.
          </Heading>
          <QuickPollForm />
        </Box>
      </Box>
    </Box>
  );
};

export default HomePage;
