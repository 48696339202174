import { api } from "../../../core/services/api";
import {
  Poll,
  PollAggregateScores,
  PollInstance,
  PollScore,
} from "../types/polls";
import { PollScoreVariant } from "../../poll/types/data";
import colors from "../../../core/theme/colors";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { Organisation } from "../../../core/types/user";
import { useContext } from "react";
import { UserContext } from "../../../core/contexts/UserContext";

export async function addNewPoll(poll: Partial<Poll>) {
  const result = await api.post<Poll>({
    url: "polls",
    data: {
      poll,
    },
  });

  return result?.payload as Poll;
}

export async function deletePoll(pollId: string) {
  const result = await api.delete({
    url: `polls/${pollId}`,
  });

  return result?.payload;
}

export async function createPollInstance(pollId: string) {
  const result = await api.post({
    url: `polls/${pollId}/start`,
  });

  return result?.payload;
}

export async function addNewPollForOrg(org: Organisation) {
  if (org) {
    const newPoll = await addNewPoll({
      name: `${org.name} Polls`,
      organisation_id: org.id,
      capture_drivers: true,
      capture_location: true,
      capture_categories: true,
    });
    const newPollInstance = await createPollInstance(newPoll.id);

    return {
      poll: newPoll,
      instance: newPollInstance,
    };
  }

  return false;
}

export async function deletePollInstance(pollInstanceId: string) {
  const result = await api.delete({
    url: `polls/instances/${pollInstanceId}`,
  });

  return result?.payload;
}

export async function downloadPollInstanceData(instance: PollInstance) {
  return api.download(
    {
      url: `polls/instances/${instance.id}/download`,
    },
    `${instance.name} - ${instance.number} Report.csv`
  );
}

export async function saveScore(pollId: string, score: number) {
  const result = await api.post({
    url: `polls/instances/${pollId}/scores`,
    data: {
      score,
    },
  });

  return result?.payload;
}

export async function saveScoreCategories({
  pollId,
  pollInstanceId,
  scoreId,
  scoreCategoriesWithScores,
}: {
  scoreId: string;
  pollId: string;
  pollInstanceId: string;
  scoreCategoriesWithScores: Record<string, number>;
}) {
  const result = await api.post({
    url: `polls/scores/${scoreId}/categories`,
    data: {
      pollId,
      pollInstanceId,
      categories: scoreCategoriesWithScores,
    },
  });

  return result?.payload;
}

export async function saveScoreLocation(scoreId: string, locationId: string) {
  const result = await api.post({
    url: `polls/scores/${scoreId}/location`,
    data: {
      locationId,
    },
  });

  return result?.payload;
}

export async function saveDrivers(
  pollId: string,
  scoreId: string,
  drivers: string[],
  trend: number
) {
  const result = await api.post({
    url: `polls/instances/${pollId}/scores/${scoreId}/reasons`,
    data: {
      reasonTrend: trend,
      reasonIds: drivers,
    },
  });

  return result?.payload;
}

type PollChartItem = {
  id: number | string;
  label: string | number;
  color: string;
  value: number;
};

const formatters: Record<
  PollScoreVariant,
  (scores: PollScore[]) => PollChartItem[]
> = {
  [PollScoreVariant.AllScores]: (scores: PollScore[]) =>
    scores.map((s) => ({
      id: s.score + "-" + s.id,
      label: s.score,
      color: (colors.form as any)[Math.round(s.score)],
      value: 1,
    })),
  [PollScoreVariant.ScoreAmounts]: (
    scores: PollScore[] | PollAggregateScores
  ) => {
    if (!scores) {
      return [];
    }

    if (Array.isArray(scores)) {
      const scoresObj = scores.reduce((acc, s) => {
        const score = Math.round(s.score);

        if (!acc[score]) {
          acc[score] = {
            id: score,
            label: score,
            color: (colors.form as any)[score],
            value: 0,
          };
        }

        acc[score].value += 1;

        return acc;
      }, {} as Record<any, PollChartItem>);
      return Object.values(scoresObj);
    } else {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => {
        const value = scores[num];

        return {
          id: num,
          label: num,
          color: (colors.form as any)[num],
          value,
        };
      });
    }
  },
};

export function formatPollScoreData(
  scores: PollScore[],
  variant: PollScoreVariant
) {
  return {
    id: "poll-scores",
    value: 0,
    children: formatters[variant](scores),
  };
}

export function formatCreatedDate(dateStr: string) {
  return format(new Date(dateStr), "do MMM yyyy HH:mm");
}

export function usePollIdUrlQuery() {
  const { id, instanceId } = useParams();

  if (!id && !instanceId) {
    return "";
  }

  return instanceId ? `pollInstanceId=${instanceId}` : `pollId=${id}`;
}

export function buildStatUrlQuery({
  urlQuery,
  from,
  to,
  selectedOrg,
  ...extraArgs
}: {
  urlQuery: string;
  from: string;
  to: string;
  selectedOrg: Organisation;
} & Record<string, any>) {
  const queryArgs = extraArgs || {};

  if (!urlQuery) {
    queryArgs.orgId = selectedOrg.id;
  }

  if (from) {
    queryArgs.from = from;
  }
  if (to) {
    queryArgs.to = to;
  }

  if (Object.keys(queryArgs).length) {
    const argsStr = `args=${JSON.stringify(queryArgs)}`;
    urlQuery += `${urlQuery ? "&" : ""}${argsStr}`;
  }

  return urlQuery;
}

export function useStatUrlQuery(from: string, to: string) {
  const { selectedOrg } = useContext(UserContext);
  let urlQuery = usePollIdUrlQuery();

  return buildStatUrlQuery({
    urlQuery,
    from,
    to,
    selectedOrg,
  });
}
