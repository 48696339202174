import {
  OrganisationAddress,
  Product,
  ProductPrice,
} from "../../../core/types/permissions";
import { createContext, useContext, useState } from "react";
import { FCWithChildren } from "../../../core/types/components";
import { api, useApi } from "../../../core/services/api";
import { UserContext } from "../../../core/contexts/UserContext";
import {
  cancelSubscription,
  startSubscription,
  startTrial,
} from "../services/products";

export enum CheckoutStep {
  None,
  Price,
  Address,
  Payment,
}

export type IProductsContext = {
  products: Product[];
  // orgProducts: OrganisationProduct[];
  startTrial: (product: Product) => Promise<void>;
  startSubscription: (
    product: Product,
    price: ProductPrice,
    billingAddress: OrganisationAddress
  ) => Promise<void>;
  cancelSubscription: () => Promise<void>;
  finaliseSubscription: (orgProductId: string) => Promise<void>;
  checkoutStep: CheckoutStep;
  setCheckoutStep: (newStep: CheckoutStep) => void;
  selectedProduct?: Product;
  setProduct: (product: Product | undefined) => void;
  selectedPaymentTerm: "month" | "year";
  setPaymentTerm: (newTerm: "month" | "year") => void;
  selectedPrice?: ProductPrice;
  setPrice: (price: ProductPrice) => void;
  billingAddress?: OrganisationAddress;
  setBillingAddress: (address?: OrganisationAddress) => void;
  clientSecret?: string;
  totalAmountDue?: number;
  getSuccessRedirectUrl: () => string;
};

export const ProductsContext = createContext<IProductsContext>({
  products: [],
  startTrial: async () => {},
  startSubscription: async () => {},
  cancelSubscription: async () => {},
  finaliseSubscription: async () => {},
  checkoutStep: CheckoutStep.Price,
  setCheckoutStep: () => {},
  selectedProduct: undefined,
  setProduct: () => {},
  selectedPaymentTerm: "month",
  setPaymentTerm: () => {},
  selectedPrice: undefined,
  setPrice: () => {},
  billingAddress: undefined,
  setBillingAddress: () => {},
  getSuccessRedirectUrl: () => "",
});

export const ProductsProvider: FCWithChildren = ({ children }) => {
  const { selectedOrg } = useContext(UserContext);
  const [, products] = useApi("products?provider=stripe");
  const [checkoutStep, setCheckoutStep] = useState(CheckoutStep.None);
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(
    undefined
  );
  const [subscriptionId, setSubscriptionId] = useState<string | undefined>(
    undefined
  );
  const [clientSecret, setClientSecret] = useState<string | undefined>(
    undefined
  );
  const [totalAmountDue, setTotalAmountDue] = useState<number | undefined>(
    undefined
  );
  const [orgProductId, setOrgProductId] = useState<string | undefined>(
    undefined
  );
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState<
    "month" | "year"
  >("year");
  const [selectedPrice, setSelectedPrice] = useState<ProductPrice | undefined>(
    undefined
  );
  const [billingAddress, setBillingAddress] = useState<
    OrganisationAddress | undefined
  >(undefined);

  const handleStartTrial = async (product: Product) => {
    await startTrial(selectedOrg.id, product.id);
    window.location.href = "/polls";
  };

  const handleStartSubscription = async (
    product: Product,
    price: ProductPrice,
    billingAddress: OrganisationAddress
  ) => {
    const result = await startSubscription(
      selectedOrg.id,
      product.id,
      price.id,
      billingAddress
    );
    setCheckoutStep(CheckoutStep.Payment);
    setSubscriptionId(result?.payload?.data?.subscriptionId);
    setClientSecret(result?.payload?.data?.clientSecret);
    setTotalAmountDue(result?.payload?.data?.totalAmountDue);
    setOrgProductId(result?.payload?.data?.organisationProductId);
  };

  const handleCancelSubscription = async () => {
    const result = await cancelSubscription(orgProductId as string);
    setCheckoutStep(CheckoutStep.None);
    setSubscriptionId(undefined);
    setClientSecret(undefined);
    setOrgProductId(undefined);
  };

  const handleFinaliseSubscription = async (orgProductId: string) => {
    const result = api.post({
      url: `organisation-products/${orgProductId}/finalise`,
    });
    setCheckoutStep(CheckoutStep.None);
    setSubscriptionId(undefined);
    setClientSecret(undefined);
    setOrgProductId(undefined);
  };

  return (
    <ProductsContext.Provider
      value={{
        products: products || [],
        startTrial: handleStartTrial,
        startSubscription: handleStartSubscription,
        cancelSubscription: handleCancelSubscription,
        finaliseSubscription: handleFinaliseSubscription,
        checkoutStep,
        setCheckoutStep,
        selectedProduct: selectedProduct,
        setProduct: setSelectedProduct,
        selectedPaymentTerm: selectedPaymentTerm,
        setPaymentTerm: setSelectedPaymentTerm,
        selectedPrice: selectedPrice,
        setPrice: setSelectedPrice,
        billingAddress,
        setBillingAddress,
        clientSecret,
        totalAmountDue,
        getSuccessRedirectUrl: () =>
          `${
            process.env.REACT_APP_PAYMENT_COMPLETE_URL as string
          }?org_product_id=${orgProductId}`,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
