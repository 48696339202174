import React, { ReactNode, useContext } from "react";
import { FCWithChildren } from "../types/components";
import {
  Avatar,
  Box,
  BoxProps,
  Flex,
  Grid,
  Heading,
  Text,
} from "@chakra-ui/react";
import Logo from "./Logo";
import { UserContext } from "../contexts/UserContext";
import { AuthContext } from "../contexts/AuthContext";
import { useMenuLinks } from "../../routes";
import { NavLink } from "react-router-dom";
import SelectOrg from "./SelectOrg";
import FormCircle from "./FormCircle";
import MobileModal from "./MobileModal";

type Props = {
  title?: string;
  subTitle?: string;
  contentProps?: BoxProps;
  hideMenu?: boolean;
} & BoxProps;

type NavNodeFunction = ({ isActive }: { isActive?: boolean }) => ReactNode;

const Layout: FCWithChildren<Props> = ({
  title,
  subTitle,
  contentProps,
  hideMenu,
  children,
  ...props
}) => {
  const { user } = useContext(UserContext);
  const { logout } = useContext(AuthContext);
  const menuLinks = useMenuLinks();
  return (
    <>
      <Box position={"relative"} zIndex={1} {...props}>
        <Grid gridTemplateColumns={"200px 1fr"} position={"relative"}>
          <Flex
            position={"sticky"}
            top={0}
            h={"100vh"}
            bg={"primary"}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            <Box>
              <Flex p={4} mb={5} flexDirection={"column"}>
                <Logo w={"70px"} h={"auto"} display={"block"} mb={6} />
                <SelectOrg />
              </Flex>
              <Box>
                {user &&
                  !hideMenu &&
                  menuLinks.map((link) => {
                    const container = (children: NavNodeFunction) => {
                      if (link.target) {
                        return (
                          <Box
                            as={"a"}
                            key={link.title}
                            href={link.path}
                            target={link.target}
                          >
                            {children({ isActive: false })}
                          </Box>
                        );
                      }

                      return (
                        <NavLink key={link.title} to={link.path}>
                          {({ isActive }) => children({ isActive })}
                        </NavLink>
                      );
                    };

                    return container(({ isActive }) => {
                      return (
                        <Box
                          display={"block"}
                          p={4}
                          color={"white"}
                          position={"relative"}
                          _hover={{
                            "> .link__active": {
                              opacity: 1,
                            },
                          }}
                        >
                          {link.title}
                          <Box
                            className={"link__active"}
                            opacity={isActive ? 1 : 0}
                            bg={"form.10"}
                            w={"7px"}
                            h={"100%"}
                            position={"absolute"}
                            right={0}
                            top={0}
                          />
                        </Box>
                      );
                    });
                  })}
              </Box>
            </Box>

            <Flex p={4} alignItems={"center"} color={"white"}>
              {user && <Avatar name={user.email} mr={2} />}
              <Box>
                {user && (
                  <Text
                    lineHeight={"1"}
                    w={"112px"}
                    whiteSpace={"nowrap"}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                  >
                    {user.first_name
                      ? user.first_name + " " + user.last_name
                      : user.email}
                  </Text>
                )}
                <Text fontSize={"10px"} onClick={logout} cursor={"pointer"}>
                  Logout
                </Text>
              </Box>
            </Flex>
          </Flex>
          <Box p={4} {...(contentProps || {})}>
            {title && (
              <Heading variant={"hero"} mb={2}>
                {title}
              </Heading>
            )}
            {subTitle && (
              <Text fontSize={"18px"} mb={5} pr={"130px"}>
                {subTitle}
              </Text>
            )}
            {children}
          </Box>
        </Grid>
        <FormCircle score={7} position={"absolute"} top={5} right={5}>
          <Text
            fontSize={"22px"}
            transform={"rotate(-35deg)"}
            fontWeight={"bold"}
          >
            In Beta
          </Text>
        </FormCircle>
      </Box>
      <MobileModal />
    </>
  );
};

export default Layout;
