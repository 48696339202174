import {
  OrganisationProduct,
  ProductFeature,
  ProductFeatureItem,
} from "../types/permissions";
import { createContext, useContext } from "react";
import { FCWithChildren } from "../types/components";
import { ApiState, useApi } from "../services/api";
import { UserContext } from "./UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import { cancelSubscription } from "../../modules/products/services/products";
import { mutate } from "swr";

export type IPermissionsContext = {
  canAccessFeature: (feature: ProductFeature) => boolean;
  organisationProducts: OrganisationProduct[];
  trialAvailable: boolean;
  activeProduct?: OrganisationProduct;
  cancelProduct: (productId: string) => Promise<void>;
};

const unsubscribedUrlWhitelist = [
  "subscribe",
  "subscription-complete",
  "subscription-success",
  "settings",
  "new-poll",
  "p/",
  "thank-you",
];

export const PermissionsContext = createContext<IPermissionsContext>({
  canAccessFeature: () => false,
  organisationProducts: [],
  trialAvailable: false,
  activeProduct: undefined,
  cancelProduct: async () => {},
});

export const PermissionsProvider: FCWithChildren = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedOrg, user } = useContext(UserContext);
  const [featuresLoading, features] = useApi<ProductFeatureItem[]>(
    selectedOrg?.id && `products/organisations/${selectedOrg.id}/features`,
    {}
  );
  const [productsLoading, organisationProducts] = useApi<OrganisationProduct[]>(
    selectedOrg?.id && `products/organisations/${selectedOrg.id}`
  );

  const handleCanAccessFeature = (feature: ProductFeature) => {
    return !!features?.some((f) => f.id === feature);
  };

  const handleCancel = async (productId: string) => {
    await cancelSubscription(productId);

    alert("Your subscription has been cancelled.");

    mutate(`products/organisations/${selectedOrg.id}`);
    mutate(`products/organisations/${selectedOrg.id}/features`);
  };

  if (
    featuresLoading === ApiState.Success &&
    user?.id &&
    !features?.length &&
    location.pathname !== "/" &&
    unsubscribedUrlWhitelist.every((url) => !location.pathname.includes(url))
  ) {
    navigate("/subscribe");
    return null;
  }

  return (
    <PermissionsContext.Provider
      value={{
        canAccessFeature: handleCanAccessFeature,
        organisationProducts: organisationProducts || [],
        trialAvailable:
          productsLoading === ApiState.Success &&
          (organisationProducts?.length || 0) < 1,
        activeProduct: (organisationProducts || []).find((p) => p.active),
        cancelProduct: handleCancel,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

export const useCanAccessFeature = (feature: ProductFeature) => {
  const { canAccessFeature } = useContext(PermissionsContext);

  return canAccessFeature(feature);
};
