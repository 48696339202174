import { api } from "../../../core/services/api";
import { OrganisationAddress } from "../../../core/types/permissions";

export function startNewTeamTrial(orgId: string) {
  return api.post({
    url: `products/default-trial`,
    data: {
      orgId,
    },
  });
}

export function startTrial(orgId: string, productId: string) {
  return api.post({
    url: `products/${productId}/trial`,
    data: {
      orgId,
    },
  });
}

export function startSubscription(
  orgId: string,
  productId: string,
  priceId: string,
  billingAddress: OrganisationAddress
) {
  return api.post({
    url: `products/${productId}/subscribe`,
    data: {
      orgId,
      type: "stripe",
      args: {
        price_id: priceId,
        billing_address: billingAddress,
      },
    },
  });
}

export function cancelSubscription(orgProductId: string) {
  return api.delete({
    url: `organisation-products/${orgProductId}`,
  });
}
