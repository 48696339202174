import React, { FC, ReactElement } from "react";
import {
  Flex,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Text,
} from "@chakra-ui/react";

export interface FormItemProps extends FormControlProps {
  name: string;
  value?: any;
  type?: string;
  label?: string;
  helperText?: string;
  errorMessage?: string;
  inputProps?: InputProps;
  inputComponent?: ReactElement;
  inputLeft?: ReactElement | string;
  inputRight?: ReactElement | string;
  inputSuffix?: ReactElement;
  onChange?: InputProps["onChange"];
}

const FormItem: FC<FormItemProps> = ({
  name,
  value,
  type,
  label,
  placeholder,
  helperText,
  errorMessage,
  onChange,
  inputProps = {},
  inputComponent,
  inputLeft,
  inputRight,
  inputSuffix,
  ...props
}) => {
  return (
    <FormControl isInvalid={!!errorMessage} {...props}>
      {label && (
        <FormLabel mb={1} fontSize={"17px"} lineHeight={"26px"}>
          {label}
        </FormLabel>
      )}
      {helperText && (
        <FormHelperText mt={0} mb={2}>
          {helperText}
        </FormHelperText>
      )}
      {inputComponent || (
        <Flex
          alignItems={"flex-end"}
          w={"100%"}
          display={inputSuffix ? "flex" : "block"}
        >
          <InputGroup w={"unset"}>
            {inputLeft && <InputLeftElement children={inputLeft} />}
            <Input
              {...inputProps}
              name={name}
              type={type}
              onChange={onChange}
              value={value}
              placeholder={placeholder}
              isInvalid={!!errorMessage}
              fontSize={"17px"}
              variant={"flushed"}
            />
            {inputRight && <InputRightElement children={inputRight} />}
          </InputGroup>
          {inputSuffix}
        </Flex>
      )}
      {errorMessage && (
        <Text mt={2} color={"error"} fontSize={"15px"} lineHeight={"15px"}>
          {errorMessage}
        </Text>
      )}
    </FormControl>
  );
};

export default FormItem;
