import { api, ApiState, useApi } from "../../../core/services/api";
import { createContext, useContext } from "react";
import { FCWithChildren } from "../../../core/types/components";
import { useParams } from "react-router-dom";
import { mutate } from "swr";
import { last } from "lodash";
import { PollEvent } from "../../polls/types/polls";
import { useStatUrlQuery } from "../../polls/services/polls";
import {
  CategoryRow,
  PollTimeSeriesData,
  ScoreRow,
} from "../../polls/components/PollTimeSeriesGraph";
import { StatTypeUrl } from "../../polls/constants/urls";
import { UserContext } from "../../../core/contexts/UserContext";
import { useTimeControls } from "../../../core/services/dates";
import { TimeControlsProps } from "../../../core/components/TimeControls";

export type IAnalyticsContext = TimeControlsProps & {
  scoresOverTime: PollTimeSeriesData;
  scoresOverTimeLoading: ApiState;
  events: PollEvent[];
  addEvent: (newEvent: Partial<PollEvent>) => Promise<void>;
  updateEvent: (event: Partial<PollEvent>) => Promise<void>;
  removeEvent: (eventId: string) => Promise<void>;
};

export const AnalyticsContext = createContext<IAnalyticsContext>({
  from: undefined,
  setFrom: () => {},
  to: undefined,
  setTo: () => {},
  timePeriod: undefined,
  setTimePeriod: () => {},
  scoresOverTime: {} as IAnalyticsContext["scoresOverTime"],
  scoresOverTimeLoading: ApiState.Loading,
  events: [],
  addEvent: async () => {},
  updateEvent: async () => {},
  removeEvent: async () => {},
});

export const AnalyticsProvider: FCWithChildren = ({ children }) => {
  const { id } = useParams();
  const { selectedOrg } = useContext(UserContext);
  const {
    from,
    setFrom,
    setEarliestFrom,
    to,
    setTo,
    timePeriod,
    setTimePeriod,
  } = useTimeControls();
  let urlQuery = useStatUrlQuery(from as string, to as string);

  let eventsUrl = `polls/${id}/events?${urlQuery}`;
  if (!id) {
    eventsUrl = `polls/events?${urlQuery}`;
  }
  const [scoresOverTimeLoading, scoresOverTime] = useApi<{
    stat: {
      scores: ScoreRow[];
      categoryScores: Record<string, CategoryRow[]>;
    };
  }>(
    `${StatTypeUrl.ScoresOverTime}?${urlQuery}`,
    {},
    {
      onSuccess: (result) => {
        if (result?.stat.scores.length) {
          setEarliestFrom(last(result.stat.scores)?.x);
        }
      },
    }
  );

  const [eventsLoading, events] = useApi(eventsUrl);

  const handleAddEvent = async (event: Partial<PollEvent>) => {
    await api.post({
      url: id ? `polls/${id}/events` : "polls/events",
      data: {
        event,
        orgId: !id ? selectedOrg.id : null,
      },
    });
    mutate(eventsUrl);
  };

  const handleUpdateEvent = async (event: Partial<PollEvent>) => {
    await api.patch({
      url: !id ? `polls/events${event.id}` : `polls/${id}/events/${event.id}`,
      data: {
        event,
      },
    });

    mutate(eventsUrl);
  };

  const handleRemoveEvent = async (eventId: string) => {
    await api.delete({
      url: `polls/events/${eventId}`,
    });

    mutate(eventsUrl);
  };

  return (
    <AnalyticsContext.Provider
      value={{
        from,
        setFrom,
        to,
        setTo,
        timePeriod,
        setTimePeriod,
        scoresOverTime: {
          scores: scoresOverTime?.stat?.scores || [],
          categories: scoresOverTime?.stat?.categoryScores || ({} as any),
        },
        scoresOverTimeLoading,
        events: events || [],
        addEvent: handleAddEvent,
        updateEvent: handleUpdateEvent,
        removeEvent: handleRemoveEvent,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
