import React, { FC } from "react";
import { Box, Button, Heading, Image } from "@chakra-ui/react";

const ThankYouPage: FC = () => {
  return (
    <Box p={4}>
      <Heading fontSize={"36px"} mt={"200px"} textAlign={"center"}>
        Thank you
      </Heading>
      <Image
        display={"block"}
        width={"153px"}
        mx={"auto"}
        src={"/150_form_logo.png"}
        alt="form logo"
      />
      <Button
        as={"a"}
        target={"_blank"}
        w={"440px"}
        display={"block"}
        maxW={"100%"}
        fontSize={"22px"}
        mx={"auto"}
        href={"https://www.formscore.today/workplace"}
        color={"white"}
        textAlign={"center"}
      >
        Find Out More
      </Button>
    </Box>
  );
};

export default ThankYouPage;
