import React, { FC } from "react";
import Layout from "../../../core/components/Layout";
import TeamsStats from "./TeamsStats";

const TeamsPage: FC = () => {
  return (
    <Layout title={"Teams"}>
      <TeamsStats mb={8} />
    </Layout>
  );
};

export default TeamsPage;
