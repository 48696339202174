import React, { FC, useContext } from "react";
import {
  Box,
  BoxProps,
  Card,
  CardBody,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { PollContext } from "../contexts/PollContext";
import { ScoreLocation } from "../../polls/types/polls";
import { getEmojiUrl } from "../../polls/services/drivers";

interface Props extends BoxProps {}

const SetLocation: FC<Props> = (props) => {
  const {
    selectedLocation,
    setSelectedLocation,
    saveScoreLocation,
    poll,
    locations,
    driverStage,
  } = useContext(PollContext);
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: true,
  });

  const handleClick = (location: ScoreLocation) => {
    setSelectedLocation(location.id);
    saveScoreLocation(location.id);
  };

  if (!poll?.capture_location || driverStage !== 0) {
    return null;
  }

  return (
    <>
      <Box {...props}>
        <Heading fontSize={"18px"} mb={2}>
          Where are you working today?
        </Heading>
        <Stack direction={"row"} position={"relative"} spacing={4}>
          {locations.map((location, i) => {
            const isSelected = selectedLocation === location.id;
            return (
              <HStack
                key={location.id}
                onClick={() => handleClick(location)}
                spacing={2}
                p={2}
                borderRadius={"10px"}
                opacity={isSelected ? 1 : 0.4}
                cursor={"pointer"}
                userSelect={"none"}
                border={"1px solid"}
                borderColor={isSelected ? "success" : "transparent"}
              >
                <Image src={getEmojiUrl(location.emoji)} w={"25px"} />
                <Text>{location.title}</Text>
              </HStack>
            );
          })}
        </Stack>
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc
        closeOnOverlayClick
        isCentered
      >
        <ModalOverlay />
        <ModalContent mx={3}>
          <ModalHeader textAlign={"center"}>
            Where are you working today?
          </ModalHeader>
          <ModalBody p={5}>
            <VStack spacing={5}>
              {locations.map((location) => {
                return (
                  <Card
                    cursor={"pointer"}
                    key={location.id}
                    w={"100%"}
                    onClick={() => {
                      handleClick(location);
                      onClose();
                    }}
                    shadow={"md"}
                  >
                    <CardBody>
                      <HStack>
                        <Image src={getEmojiUrl(location.emoji)} w={"50px"} />
                        <Heading variant={"section"}>{location.title}</Heading>
                      </HStack>
                    </CardBody>
                  </Card>
                );
              })}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SetLocation;
