import React, { FC } from "react";
import { StatDriver } from "core/types/form";
import PollStatDisplay, {
  PollStatDisplayProps,
} from "modules/polls/components/PollStatDisplay";
import { VStack } from "@chakra-ui/react";
import PollStatDriver, {
  PollStatDriverProps,
} from "modules/polls/components/PollStatDriver";

type Props = {
  variant?: PollStatDriverProps["variant"];
  drivers: StatDriver[];
} & Omit<PollStatDisplayProps, "stat" | "variant">;

const PollStatDriversDisplay: FC<Props> = ({ drivers, variant, ...props }) => {
  return (
    <PollStatDisplay
      maxW={"300px"}
      w={"100%"}
      stat={drivers}
      variant={"titleTop"}
      {...props}
      render={() => (
        <VStack w={"100%"}>
          {drivers.map((driver) => (
            <PollStatDriver
              key={driver.id}
              color={"form.10"}
              driver={driver}
              variant={variant || "row"}
            />
          ))}
        </VStack>
      )}
    ></PollStatDisplay>
  );
};

export default PollStatDriversDisplay;
