import React, { FC, useContext } from "react";
import { Poll } from "../types/polls";
import { Box, CardProps, Flex, Spinner, Text } from "@chakra-ui/react";
import { PollsContext } from "../contexts/PollsContext";
import { DeleteIcon, ViewIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { ApiState, useApi } from "../../../core/services/api";
import { StatTypeUrl } from "../constants/urls";
import FormCircle from "../../../core/components/FormCircle";
import GridCard, {
  GridCardButtonProp,
} from "../../../core/components/GridCard";
import { FiPlus } from "react-icons/fi";

interface Props extends CardProps {
  poll: Poll;
}

const PollGroupTease: FC<Props> = ({ poll, ...props }) => {
  const { deletePoll, startNewPoll } = useContext(PollsContext);
  const [avgLoading, avgScore] = useApi(
    `${StatTypeUrl.AvgScore}?pollId=${poll.id}`
  );
  const [pollCountLoading, pollCount] = useApi(
    `${StatTypeUrl.PollCount}?pollId=${poll.id}`
  );
  const [scoreCountLoading, scoreCount] = useApi(
    `${StatTypeUrl.ParticipantCount}?pollId=${poll.id}`
  );

  const navigate = useNavigate();
  const actionButtons: GridCardButtonProp[] = [
    {
      label: "Delete Poll Group",
      icon: DeleteIcon,
      onClick: () => deletePoll(poll.id),
    },
  ];

  if (poll?.instances?.length) {
    actionButtons.unshift({
      label: "View Latest Poll",
      icon: ViewIcon,
      as: "a",
      href: `/p/${poll.short_id}`,
      target: "_blank",
    });
  }

  return (
    <GridCard
      title={poll.name}
      onClick={() => navigate(`/polls/${poll.id}`)}
      renderAfterChildren={() => (
        <Flex
          justifyContent={"space-between"}
          w={"100%"}
          mt={4}
          position={"absolute"}
          bottom={2}
          left={0}
          px={4}
        >
          <Box>
            {pollCountLoading === ApiState.Success && (
              <Text>
                {pollCount.stat} Poll{pollCount.stat !== 1 ? "s" : ""}
              </Text>
            )}
          </Box>
          <Box>
            {scoreCountLoading === ApiState.Success && (
              <Text>
                {scoreCount.stat} Score{scoreCount.stat !== 1 ? "s" : ""}
              </Text>
            )}
          </Box>
        </Flex>
      )}
      buttonsLeft={[
        {
          label: "Start New Poll",
          icon: FiPlus,
          onClick: () => {
            startNewPoll(poll);
          },
        },
      ]}
      buttonsRight={actionButtons}
    >
      {avgLoading !== ApiState.Success && <Spinner />}
      {avgLoading === ApiState.Success && avgScore && (
        <FormCircle score={avgScore.stat}>{avgScore.stat}</FormCircle>
      )}
    </GridCard>
  );
};

export default PollGroupTease;
