import { Organisation } from "../../../core/types/user";
import { catchify } from "../../../core/services/ajax";
import { api } from "../../../core/services/api";
import { Driver } from "core/types/form";

export function getEmojiUrl(emoji: string) {
  return `${process.env.REACT_APP_S3_URL}/emoji/${emoji}.png`;
}

export function getDefaultEmojiUrl(driver: Driver) {
  if (driver.emoji) {
    return getEmojiUrl(driver.emoji);
  }

  if (driver.organisation_id) {
    return getEmojiUrl("default-custom");
  }

  if (driver?.type?.emoji) {
    return getEmojiUrl(driver.type.emoji);
  }

  return "";
}

export async function addCustomDriver(
  driver: Partial<Driver>,
  org: Organisation
) {
  const [err, result] = await catchify(
    api.post({
      url: "drivers/custom",
      data: {
        driver,
        organisation_id: org.id,
      },
    })
  );

  if (err) {
    console.error(err);
    alert("There was an error creating your custom driver");
    return;
  }

  return result?.payload;
}
