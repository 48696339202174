import React, { FC, useContext } from "react";
import { Button, Flex, VStack } from "@chakra-ui/react";
import FormItem from "../../../core/components/FormItem";
import { useFormik } from "formik";
import { PollEvent } from "../types/polls";
import {
  formatDateTimeForInput,
  getDateFromDateTimeInput,
} from "../../../core/services/dates";
import { startOfDay } from "date-fns";
import { AnalyticsContext } from "../../analytics/contexts/AnalyticsContext";

interface Props {
  event?: Partial<PollEvent>;
  onSubmit?: () => void;
}

const EventForm: FC<Props> = ({ event, onSubmit }) => {
  const { addEvent, updateEvent } = useContext(AnalyticsContext);

  const formik = useFormik<Partial<PollEvent>>({
    initialValues: {
      title: "",
      ...event,
      date: new Date(
        event?.date || startOfDay(new Date()).toISOString()
      ).toISOString(),
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (values.id) {
        await updateEvent(values);
      } else {
        await addEvent(values as Partial<PollEvent>);
      }

      onSubmit?.();
      formik.setValues({
        id: null,
        title: "",
        date: new Date().toISOString(),
      } as any);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <VStack>
          <FormItem
            label={"Title"}
            name={"title"}
            value={formik.values.title}
            onChange={formik.handleChange}
          />
          <FormItem
            label={"Date"}
            name={"date"}
            type={"datetime-local"}
            value={formatDateTimeForInput(formik.values.date as string)}
            onChange={(e) => {
              formik.setFieldValue(
                "date",
                getDateFromDateTimeInput(e).toISOString()
              );
            }}
          />
        </VStack>
        <Flex mt={4} justifyContent={"flex-end"}>
          <Button type={"submit"}>Submit</Button>
        </Flex>
      </form>
    </>
  );
};

export default EventForm;
