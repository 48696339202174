import { defineStyleConfig } from "@chakra-ui/react";

const headingComponent = defineStyleConfig({
  baseStyle: {
    fontWeight: "normal",
  },
  variants: {
    hero: {
      fontSize: ["22px", "36px", "64px"],
    },
    section: {
      fontSize: "22px",
    },
    subSection: {
      fontSize: "16px",
    },
  },
});

export default headingComponent;
