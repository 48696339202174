import React, { FC, useContext } from "react";
import { BoxProps } from "@chakra-ui/react";

import { AnalyticsContext } from "../contexts/AnalyticsContext";
import TimeControls from "../../../core/components/TimeControls";

interface Props extends BoxProps {}

const AnalyticsTimeControls: FC<Props> = (props) => {
  const { from, to, timePeriod, setFrom, setTo, setTimePeriod } =
    useContext(AnalyticsContext);
  return (
    <TimeControls
      {...props}
      from={from}
      to={to}
      timePeriod={timePeriod}
      setFrom={setFrom}
      setTo={setTo}
      setTimePeriod={setTimePeriod}
    />
  );
};

export default AnalyticsTimeControls;
