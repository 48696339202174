import React, { FC, useContext } from "react";
import { UserContext } from "../../../core/contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { getOrgLandingUrl } from "../../../core/services/form";

interface Props {}

const LoginSuccessPage: FC<Props> = (props) => {
  const { selectedOrg } = useContext(UserContext);
  const navigate = useNavigate();

  if (selectedOrg) {
    navigate(getOrgLandingUrl(selectedOrg));
    return null;
  }

  return <></>;
};

export default LoginSuccessPage;
