import { extendTheme } from "@chakra-ui/react";
import colors from "./colors";
import buttonComponent from "./button";
import headingComponent from "./heading";

const theme = extendTheme({
  fonts: {
    heading: "Fugue Regular",
    body: "Inter",
  },
  styles: {
    global: {
      body: {
        background: "body",
      },
    },
  },
  colors,
  components: {
    Button: buttonComponent,
    Heading: headingComponent,
  },
});

export default theme;
