import React, { FC } from "react";
import DriversWordCloud from "modules/polls/components/DriversWordCloud";
import Layout from "core/components/Layout";
import AnalyticsTimeControls from "modules/analytics/components/AnalyticsTimeControls";
import PollTimeSeriesGraph from "modules/polls/components/PollTimeSeriesGraph";
import PollStats from "modules/polls/components/PollStats";
import PollLocationStats from "modules/polls/components/PollLocationStats";

const AnalyticsPage: FC = () => {
  return (
    <Layout title={"Analytics"}>
      <AnalyticsTimeControls mb={4} />
      <PollTimeSeriesGraph mb={10} />
      <PollStats mb={10} showCounts />
      <PollLocationStats />
      <DriversWordCloud mt={10} />
    </Layout>
  );
};

export default AnalyticsPage;
