import React, { FC, useContext, useState } from "react";
import { AuthContext, LoginStage } from "../../../core/contexts/AuthContext";
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from "@chakra-ui/react";
import LoginForm from "../../auth/components/LoginForm";
import { Navigate } from "react-router-dom";
import { PollDataContext } from "../../poll/contexts/PollDataContext";
import PollPage from "../../poll/components/PollPage";
import { PollsProvider } from "../contexts/PollsContext";
import { PollScoreVariant, PollView } from "../../poll/types/data";
import { UserContext } from "../../../core/contexts/UserContext";
import NewPollInstanceForm from "./NewPollInstanceForm";
import NewUserTeamForm from "../../auth/components/NewUserTeamForm";
import { addNewPollForOrg } from "../services/polls";

enum CreationStage {
  Login = "login",
  Create = "create",
}

const labels: Record<CreationStage, string> = {
  [CreationStage.Login]: "Verify your email",
  [CreationStage.Create]: "Create your poll",
};

const NewPollPage: FC = () => {
  const { stage, isLoggedIn } = useContext(AuthContext);
  const { user } = useContext(UserContext);
  const [creationStage, setCreationStage] = useState(
    isLoggedIn ? CreationStage.Create : CreationStage.Login
  );

  if (stage !== LoginStage.Code) {
    return <Navigate to={"/"} />;
  }

  return (
    <Box>
      <Box opacity={0.2}>
        <PollDataContext.Provider
          value={{
            pollView: PollView.Scores,
            setPollView: () => {},
            pollScoreVariant: PollScoreVariant.AllScores,
            setPollScoreVariant: () => {},
            categories: [],
            formScore: {
              avg: 7.2,
              categories: [],
            },
            scores: [
              {
                score: 8,
                id: "1",
              },
              {
                score: 6,
                id: "2",
              },
              {
                score: 7,
                id: "3",
              },
              {
                score: 6,
                id: "4",
              },
              {
                score: 9,
                id: "5",
              },
            ],
            scoreCount: 5,
            driverStats: {} as any,
          }}
        >
          <PollPage />
        </PollDataContext.Provider>
      </Box>
      <Modal isCentered isOpen onClose={() => {}}>
        <ModalContent>
          <ModalHeader>{labels[creationStage]}</ModalHeader>
          <ModalBody>
            {creationStage === CreationStage.Login && (
              <LoginForm
                hideBack
                onLogin={() => {
                  setCreationStage(CreationStage.Create);
                }}
              ></LoginForm>
            )}
            {creationStage === CreationStage.Create && !user?.orgs?.length && (
              <NewUserTeamForm
                onSubmit={async (org) => {
                  const result = await addNewPollForOrg(org);

                  if (result && result.poll && result.instance) {
                    window.location.href = `/p/${result.poll.short_id}`;
                  }
                }}
              />
            )}
            {creationStage === CreationStage.Create && !!user?.orgs?.length && (
              <PollsProvider>
                <NewPollInstanceForm />
              </PollsProvider>
            )}
            {/*<NewPollForm*/}
            {/*  onCreated={async (newPoll) => {*/}
            {/*    const res = await createPollInstance(newPoll.id);*/}
            {/*    navigate(`/p/${newPoll.short_id}/${res.number}`);*/}
            {/*  }}*/}
            {/*/>*/}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default NewPollPage;
