import React, { FC, useContext } from "react";
import { Box, BoxProps, Text } from "@chakra-ui/react";
import EventsGraph from "./EventsGraph";
import ManageEvents from "./ManageEvents";
import { ApiState } from "../../../core/services/api";
import colors from "../../../core/theme/colors";
import {
  calculateTimeSeriesTicks,
  getLatestTimeSeriesDate,
  TIME_SERIES_SHORT_DATE_FORMAT,
} from "../services/graphs";
import { format } from "date-fns";
import { ResponsiveLine } from "@nivo/line";
import { AnalyticsContext } from "../../analytics/contexts/AnalyticsContext";
import { UserContext } from "../../../core/contexts/UserContext";

export type ScoreRow = {
  x: string;
  y: number;
};

export type CategoryRow = {
  x: string;
  y: number;
  color: string;
};

export type PollTimeSeriesData = {
  scores: ScoreRow[];
  categories: Record<string, CategoryRow[]>;
};

const graphPadding = { top: 50, right: 110, bottom: 50, left: 60 };

interface Props extends BoxProps {}
const PollTimeSeriesGraph: FC<Props> = (props) => {
  const { selectedOrg } = useContext(UserContext);
  const { scoresOverTimeLoading, scoresOverTime, events } =
    useContext(AnalyticsContext);
  let maxY = 10;

  if (events?.length) {
    maxY = 13;
  }

  return (
    <Box w={"100%"} position={"relative"} {...props}>
      <Text mb={4}>
        {selectedOrg?.is_parent
          ? `You are in a parent organisation. View the FormScore analytics of the sub-teams over time. This chart will populate over time as your teams complete the polls. Check out the “Teams” tab on the left to view poll utilisation.`
          : `View your FormScore analytics over time. This chart will populate over
        time as your team completes the polls.`}
      </Text>
      <Box position={"relative"} mb={2} w={"100%"} h={"400px"}>
        {scoresOverTimeLoading === ApiState.Success && (
          <ResponsiveLine
            data={[
              {
                id: "FormScore",
                color: colors.form["10"],
                data: scoresOverTime.scores,
              },
              ...Object.entries(scoresOverTime.categories).map(
                ([title, data]) => {
                  return {
                    id: title,
                    color: data?.[0]?.color,
                    data,
                  };
                }
              ),
            ]}
            curve={"catmullRom"}
            lineWidth={8}
            margin={graphPadding}
            layers={["axes", "lines", "legends", "mesh", "slices", "points"]}
            colors={{ datum: "color" }}
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: "black",
                    strokeWidth: 1,
                    height: "110%",
                  },
                },
              },
            }}
            isInteractive
            useMesh
            // enablePoints={false}
            yScale={{
              type: "linear",
              max: maxY,
            }}
            axisLeft={{
              legend: "Score",
              legendOffset: -40,
              legendPosition: "middle",
              tickValues: [5, 10],
            }}
            xScale={{
              format: "%Y-%m-%dT%H:%M:%S.%L%Z",
              type: "time",
              max: getLatestTimeSeriesDate({
                scores: scoresOverTime?.scores || [],
                events: events || [],
              }),
            }}
            axisBottom={{
              legend: "Time",
              legendPosition: "middle",
              legendOffset: 40,
              format: (val) =>
                format(new Date(val), TIME_SERIES_SHORT_DATE_FORMAT),
              tickValues: calculateTimeSeriesTicks({
                scores: scoresOverTime?.scores || [],
                events: events || [],
              }),
            }}
            legends={[
              {
                anchor: "top-left",
                direction: "row",
                itemWidth: 100,
                itemHeight: 70,
                translateY: -80,
              },
            ]}
          />
        )}
        <EventsGraph
          position={"absolute"}
          top={0}
          left={0}
          pt={`${graphPadding.top}px`}
          pr={`${graphPadding.right}px`}
          pb={`${graphPadding.bottom}px`}
          pl={`${graphPadding.left}px`}
        />
      </Box>
      <ManageEvents />
    </Box>
  );
};

export default PollTimeSeriesGraph;
