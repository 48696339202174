import React, { FC } from "react";
import Layout from "../../../core/components/Layout";
import LoginForm from "./LoginForm";
import { useNavigate } from "react-router-dom";

const LoginPage: FC = () => {
  const navigate = useNavigate();
  return (
    <Layout title={"Login"}>
      <LoginForm
        onLogin={() => {
          navigate("/login-success");
        }}
      />
    </Layout>
  );
};

export default LoginPage;
