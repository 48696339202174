import React, { FC } from "react";
import Layout from "../../../core/components/Layout";
import PollInsightsFeed from "../../polls/components/PollInsightsFeed";

const FeedPage: FC = () => {
  return (
    <Layout title={"Insights Feed"}>
      <PollInsightsFeed />
    </Layout>
  );
};

export default FeedPage;
