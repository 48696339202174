import React, { FC, useContext } from "react";
import FormItem from "../../../core/components/FormItem";
import { Button, Flex, Select } from "@chakra-ui/react";
import { ProductPrice } from "../../../core/types/permissions";
import { CheckoutStep, ProductsContext } from "../contexts/ProductsContext";

const PricePlanSelector: FC = () => {
  const {
    selectedProduct,
    setCheckoutStep,
    selectedPrice,
    setPrice,
    selectedPaymentTerm,
    setPaymentTerm,
  } = useContext(ProductsContext);
  const prices = selectedProduct?.prices?.[selectedPaymentTerm] || [];

  return (
    <>
      <FormItem
        mb={5}
        name={"paymentTerm"}
        label={"Payment Term"}
        inputComponent={
          <Select
            value={selectedPaymentTerm}
            onChange={(e) => {
              setPaymentTerm(e.target.value as any);
            }}
          >
            <option value={"month"}>Monthly</option>
            <option value={"year"}>Yearly</option>
          </Select>
        }
      />
      <FormItem
        name={"price"}
        label={"Price Plan"}
        inputComponent={
          <Select
            value={selectedPrice?.id || ""}
            onChange={(e) => {
              const newPrice = prices.find((p) => p.id === e.target.value);
              setPrice(newPrice as ProductPrice);
            }}
          >
            <option value="">Choose Price Plan</option>
            {prices?.map((price) => (
              <option key={price.id} value={price.id}>
                {price.name} employees - £{price.amount.toLocaleString("en-GB")}
              </option>
            ))}
          </Select>
        }
      />
      <Flex justifyContent={"flex-end"} mt={5}>
        <Button
          isDisabled={!selectedPrice}
          onClick={() => {
            setCheckoutStep(CheckoutStep.Address);
          }}
        >
          Subscribe
        </Button>
      </Flex>
    </>
  );
};

export default PricePlanSelector;
