import React, { FC, useContext, useState } from "react";
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { PermissionsContext } from "../../../core/contexts/PermissionsContext";

const CancelSubscription: FC = () => {
  const { activeProduct, cancelProduct } = useContext(PermissionsContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Button bg={"error"} onClick={onOpen}>
        Cancel Subscription
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent w={"500px"} maxW={"95%"} pb={5}>
          <ModalHeader>Cancel My Subscription</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={5}>
              Are you sure you want to cancel your subscription? You will no
              longer be able to access FormScore for Managers.
            </Text>
            <ButtonGroup justifyContent={"flex-end"} w={"100%"}>
              <Button onClick={onClose}>No</Button>
              <Button
                isLoading={loading}
                onClick={async () => {
                  setLoading(true);
                  await cancelProduct(activeProduct?.id as string);
                  setLoading(false);
                  onClose();
                }}
              >
                Yes, I want to cancel
              </Button>
            </ButtonGroup>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CancelSubscription;
