import React, { FC, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutStep, ProductsContext } from "../contexts/ProductsContext";
import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import PaymentForm from "./PaymentForm";
import PricePlanSelector from "./PricePlanSelector";
import AddressForm from "./AddressForm";

interface Props {}

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
);

const ProductCheckout: FC<Props> = (props) => {
  const {
    selectedProduct,
    setProduct,
    clientSecret,
    cancelSubscription,
    checkoutStep,
    setCheckoutStep,
    startSubscription,
    selectedPrice,
    selectedPaymentTerm,
  } = useContext(ProductsContext);

  const handleClose = () => {
    if (clientSecret) {
      cancelSubscription();
    } else {
      setProduct(undefined);
      setCheckoutStep(CheckoutStep.None);
    }
  };

  return (
    <Modal isOpen={checkoutStep !== CheckoutStep.None} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading>Subscribe to {selectedProduct?.name} Plan</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={5}>
          {checkoutStep === CheckoutStep.Price && <PricePlanSelector />}
          {checkoutStep === CheckoutStep.Address && (
            <Elements stripe={stripePromise}>
              <AddressForm />
            </Elements>
          )}
          {checkoutStep === CheckoutStep.Payment && clientSecret && (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret,
              }}
            >
              <PaymentForm />
            </Elements>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProductCheckout;
