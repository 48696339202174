import { createContext, useContext, useEffect, useState } from "react";
import { PollContext } from "./PollContext";
import { mutate } from "swr";
import { FormCategory, StatDriver } from "core/types/form";
import { FormScore, PollScoreVariant, PollView } from "modules/poll/types/data";
import { PollScore } from "modules/polls/types/polls";
import { FCWithChildren } from "core/types/components";
import { StatTypeUrl } from "modules/polls/constants/urls";
import { ApiState, useApi } from "core/services/api";

export type IPollDataContext = {
  pollView: PollView;
  setPollView: (view: PollView) => void;
  pollScoreVariant: PollScoreVariant;
  setPollScoreVariant: (variant: PollScoreVariant) => void;
  formScore: FormScore;
  scores: PollScore[];
  scoreCount: number;
  categories: FormCategory[];
  driverStats: {
    loading: boolean;
    top: StatDriver[];
    positive: StatDriver[];
    negative: StatDriver[];
  };
};

let timer: any;

export const PollDataContext = createContext<IPollDataContext>({
  pollView: PollView.Scores,
  setPollView: () => {},
  pollScoreVariant: PollScoreVariant.AllScores,
  setPollScoreVariant: () => {},
  formScore: {
    avg: 0,
    categories: [],
  },
  scores: [],
  scoreCount: 0,
  categories: [],
  driverStats: {
    loading: true,
    top: [],
    positive: [],
    negative: [],
  },
});

let socket: WebSocket;

export const PollDataProvider: FCWithChildren = ({ children }) => {
  const { poll } = useContext(PollContext);
  const [pollView, setPollView] = useState(PollView.Scores);
  const [pollScoreVariant, setPollScoreVariant] = useState(
    PollScoreVariant.AllScores
  );
  const scoresUrl = `polls/instances/${poll.id}/scores`;
  const scoreCountUrl = `${StatTypeUrl.ParticipantCount}?pollInstanceId=${poll.id}`;
  const formScoreUrl = `${StatTypeUrl.FormScore}?pollInstanceId=${poll.id}`;
  const topUrl = `${StatTypeUrl.TopDrivers}?pollInstanceId=${
    poll.id
  }&args=${JSON.stringify({
    limit: 5,
  })}`;
  const topPositiveUrl = `${StatTypeUrl.TopDrivers}?pollInstanceId=${
    poll.id
  }&args=${JSON.stringify({
    limit: 5,
    trend: 1,
  })}`;
  const topNegativeUrl = `${StatTypeUrl.TopDrivers}?pollInstanceId=${
    poll.id
  }&args=${JSON.stringify({
    limit: 5,
    trend: -1,
  })}`;

  const [scoresLoading, scores] = useApi(poll?.id && scoresUrl);
  const [scoreCountLoading, scoreCount] = useApi(poll?.id && scoreCountUrl);
  const [formScoreLoading, formScore] = useApi(poll?.id && formScoreUrl);
  const [categoriesLoading, categories] = useApi("form-categories");
  const [topDriversLoading, topDrivers] = useApi(poll.id && topUrl);
  const [topPositiveLoading, topPositive] = useApi(poll.id && topPositiveUrl);
  const [topNegativeLoading, topNegative] = useApi(poll.id && topNegativeUrl);

  const pollData = async () => {
    clearTimeout(timer);
    await Promise.all(
      [
        formScoreUrl,
        scoresUrl,
        scoreCountUrl,
        topUrl,
        topPositiveUrl,
        topNegativeUrl,
      ].map((url) => mutate(url))
    );
    timer = setTimeout(pollData, 2000);
  };

  useEffect(() => {
    if (poll?.id) {
      clearTimeout(timer);
      timer = setTimeout(pollData, 100);
    }
  }, [poll?.id]);

  return (
    <PollDataContext.Provider
      value={{
        pollView,
        setPollView,
        pollScoreVariant,
        setPollScoreVariant,
        formScore: formScore?.stat || {
          avg: 0,
          categories: [],
        },
        scores: scores || [],
        scoreCount: scoreCount?.stat || 0,
        categories: categories || [],
        driverStats: {
          loading:
            topDriversLoading !== ApiState.Success &&
            topPositiveLoading !== ApiState.Success &&
            topNegativeLoading !== ApiState.Success,
          top: topDrivers?.stat || [],
          positive: topPositive?.stat || [],
          negative: topNegative?.stat || [],
        },
      }}
    >
      {children}
    </PollDataContext.Provider>
  );
};
