import React, { FC, useContext, useState } from "react";
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightAddon,
} from "@chakra-ui/react";
import { AuthContext } from "../../../core/contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const QuickPollForm: FC = () => {
  const { login } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  return (
    <Box
      as={"form"}
      onSubmit={
        (async (e: any) => {
          e.preventDefault();
          const email = (e.target as any)?.querySelector?.("input")?.value;

          if (email) {
            setLoading(true);
            await login(email);
            navigate("/new-poll");
          }
        }) as any
      }
      maxW={"500px"}
      display={["none", "none", "block"]}
    >
      <InputGroup>
        <Input
          placeholder={"Your work email here"}
          name={"email"}
          pr={"80px"}
        />
        <InputRightAddon bg={"transparent"}>
          <Button
            type={"submit"}
            variant={"unstyled"}
            bg={"transparent"}
            minWidth={"auto"}
            isLoading={loading}
          >
            Create Poll
          </Button>
        </InputRightAddon>
      </InputGroup>
    </Box>
  );
};

export default QuickPollForm;
