import React, { FC, useContext } from "react";
import { Product } from "../../../core/types/permissions";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardProps,
  Grid,
  Heading,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { CheckoutStep, ProductsContext } from "../contexts/ProductsContext";

interface Props extends CardProps {
  product: Product;
}

const frequencyLabels: Record<string, string> = {
  monthly: "Monthly",
  employees_monthly: "Per employee/month",
};

const ProductCard: FC<Props> = ({ product, ...props }) => {
  const { setCheckoutStep, setProduct, selectedPaymentTerm } =
    useContext(ProductsContext);

  const prices = product.prices?.[selectedPaymentTerm];
  const handleSubscribeClick = async () => {
    if (prices) {
      setProduct(product);
      setCheckoutStep(CheckoutStep.Price);
    } else {
      // todo: contact sales process
    }
  };

  const renderPriceRange = () => {
    if (!prices) {
      return "£enquire";
    }

    const { lowest, highest } = prices.reduce(
      (acc: Record<string, number>, price) => {
        if (price.amount < acc.lowest) {
          acc.lowest = price.amount;
        }

        if (price.amount > acc.highest) {
          acc.highest = price.amount;
        }

        return acc;
      },
      {
        lowest: Number.MAX_SAFE_INTEGER,
        highest: 0,
      } as Record<string, number>
    );

    return `£${lowest.toLocaleString("en-GB")}-${highest.toLocaleString(
      "en-GB"
    )}`;
  };

  return (
    <Card borderRadius={"50px"} shadow={"lg"} overflow={"hidden"} {...props}>
      <CardHeader textAlign={"center"}>
        <Heading>{product.name}</Heading>
        <Text>{product.description}</Text>
      </CardHeader>
      <CardBody>
        <UnorderedList px={4}>
          {product.features.map((feature) => {
            return (
              <ListItem key={feature.id}>
                <Text>{feature.description}</Text>
              </ListItem>
            );
          })}
        </UnorderedList>
      </CardBody>
      <CardFooter pb={0} px={0}>
        <Box w={"100%"}>
          <Box textAlign={"center"}>
            <Heading variant={"section"}>{renderPriceRange()}</Heading>
            <Text opacity={prices ? 1 : 0}>
              {prices ? `per ${selectedPaymentTerm}` : "-"}
            </Text>
            <Button my={4} onClick={handleSubscribeClick} fontWeight={"normal"}>
              {prices ? "Subscribe" : "Contact Sales"}
            </Button>
          </Box>
          <Box bg={"grey.5"} py={3} px={"50px"} minH={"200px"}>
            {prices && (
              <Grid
                gridTemplateColumns={"1fr 1fr"}
                gridGap={4}
                justifyContent={"center"}
              >
                <Box>
                  <Text fontWeight={"bold"}>{product.details.team_label}</Text>
                  {prices?.map?.((price) => (
                    <Text
                      w={"117px"}
                      key={price.id}
                      whiteSpace={"nowrap"}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                    >
                      {price.name}
                    </Text>
                  ))}
                </Box>
                <Box>
                  <Text fontWeight={"bold"} textTransform={"capitalize"}>
                    {selectedPaymentTerm + "ly"} Cost
                  </Text>
                  {prices?.map?.((price) => (
                    <Text key={price.id}>
                      £{price.amount.toLocaleString("en-GB")}
                    </Text>
                  ))}
                </Box>
              </Grid>
            )}
          </Box>
        </Box>
      </CardFooter>
    </Card>
  );
};

export default ProductCard;
