import React, { FC, useContext } from "react";
import { Box, BoxProps, Text } from "@chakra-ui/react";
import { getLatestTimeSeriesDate } from "../services/graphs";
import { AnalyticsContext } from "../../analytics/contexts/AnalyticsContext";

interface Props extends BoxProps {}

const EventsGraph: FC<Props> = (props) => {
  const { events, scoresOverTime } = useContext(AnalyticsContext);
  const itemW = 60;

  const firstScoreDateTime = new Date(
    scoresOverTime.scores?.[0]?.x as string
  ).getTime();
  const latestScoreDateTime = getLatestTimeSeriesDate({
    scores: scoresOverTime.scores || [],
    events: events || [],
  }).getTime();
  const dateTimeDifference = latestScoreDateTime - firstScoreDateTime;

  return (
    <Box w={"100%"} h={"100%"} {...props}>
      <Box
        position={"relative"}
        w={"100%"}
        h={"100%"}
        clipPath={"inset( -100vw -10% -100vw 0 )"}
      >
        {events.map((event) => {
          const eventDateTime = new Date(event.date).getTime();
          const eventDateTimeDifference = latestScoreDateTime - eventDateTime;
          const position =
            100 -
            Math.floor((eventDateTimeDifference / dateTimeDifference) * 100);

          return (
            <Box
              key={event.id}
              h={"80%"}
              w={`${itemW}px`}
              position={"absolute"}
              left={`${position}%`}
              bottom={0}
              ml={`-${itemW / 2}px`}
              zIndex={1}
              _hover={{
                zIndex: 2,
              }}
            >
              <Text
                position={"absolute"}
                bottom={"105%"}
                left={"50%"}
                transform={"translate(-50%)"}
                w={"200%"}
                textAlign={"center"}
                p={2}
                bg={"white"}
                boxShadow={"sm"}
                fontSize={"12px"}
              >
                {event.title}
              </Text>
              <Box
                borderRight={"dashed 1px black"}
                w={0}
                h={"103%"}
                position={"absolute"}
                bottom={0}
                left={"50%"}
                transform={"translate(-50%)"}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default EventsGraph;
