import React, { FC, useContext } from "react";
import { PollDataContext } from "../contexts/PollDataContext";
import { Box, BoxProps, HStack, Icon, Text } from "@chakra-ui/react";
import { FaUserAlt } from "react-icons/fa";

interface Props extends BoxProps {}

const PollMiniStats: FC<Props> = (props) => {
  const { scoreCount } = useContext(PollDataContext);

  return (
    <Box {...props}>
      <HStack spacing={5}>
        <HStack alignItems={"center"}>
          <Icon fontSize={"25px"} as={FaUserAlt} />
          <Text fontSize={"22px"}>
            x
            <Box as={"span"} fontSize={"30px"}>
              {scoreCount}
            </Box>
          </Text>
        </HStack>
      </HStack>
    </Box>
  );
};

export default PollMiniStats;
