import { createContext, useState } from "react";
import {
  isLoggedIn,
  loginOrRegister,
  logout,
  verify,
} from "core/services/auth";
import { FCWithChildren } from "core/types/components";
import { catchify } from "../services/ajax";
import { useLocation } from "react-router-dom";

export enum LoginStage {
  Email,
  Code,
}

export type IAuthContext = {
  stage: LoginStage;
  setStage: (stage: LoginStage) => void;
  isLoggedIn: boolean;
  login: (email: string) => Promise<void>;
  verify: (code: string) => Promise<void>;
  logout: () => void;
  authError?: Error;
};

export const AuthContext = createContext<IAuthContext>({
  stage: LoginStage.Email,
  setStage: () => {},
  isLoggedIn: false,
  login: async () => Promise.resolve(),
  verify: async () => Promise.resolve(),
  logout: () => {},
  authError: undefined,
});

export const AuthProvider: FCWithChildren = ({ children }) => {
  const [authError, setAuthError] = useState<Error | undefined>(undefined);
  const [stage, setStage] = useState(LoginStage.Email);
  const [stateIsLoggedIn, setStateIsLoggedIn] = useState(isLoggedIn());
  const [verificationId, setVerificationId] = useState("");
  const location = useLocation();

  const handleAuth = async (email: string) => {
    const [err, verificationId] = await catchify(loginOrRegister(email));
    if (err) {
      setAuthError(err);
      window.alert("There was an error. Please refresh and try again.");
      throw err;
    }

    setStage(LoginStage.Code);
    setVerificationId(verificationId);
  };

  const handleVerify = async (code: string) => {
    const result = await verify(verificationId, code);

    if (result.error || !result.success) {
      setAuthError(
        result?.error ||
          new Error("There was an error with your login. Please try again")
      );
      window.alert("Verification has expired. Please refresh and try again");
      throw result.error;
    } else {
      setStateIsLoggedIn(true);
    }
  };

  const loggedOutUrlWhitelist = [
    "p/",
    "login",
    "new-poll",
    "subscription-complete",
    "subscription-success",
  ];

  if (
    !stateIsLoggedIn &&
    location.pathname !== "/" &&
    loggedOutUrlWhitelist.every((url) => !location.pathname.includes(url))
  ) {
    logout();
  }

  return (
    <AuthContext.Provider
      value={{
        stage,
        setStage,
        isLoggedIn: stateIsLoggedIn,
        login: handleAuth,
        verify: handleVerify,
        logout,
        authError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
