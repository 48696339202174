import React, { FC, useContext } from "react";
import { PollInstance } from "../types/polls";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { ApiState, useApi } from "../../../core/services/api";
import FormCircle from "../../../core/components/FormCircle";
import { DeleteIcon, ViewIcon } from "@chakra-ui/icons";
import { PollGroupContext } from "../contexts/PollGroupContext";
import { StatTypeUrl } from "../constants/urls";
import GridCard from "../../../core/components/GridCard";
import { useNavigate } from "react-router-dom";
import { formatCreatedDate } from "../services/polls";

interface Props {
  instance: PollInstance;
}

const PollInstanceItem: FC<Props> = ({ instance }) => {
  const { deleteInstance, poll } = useContext(PollGroupContext);
  const [avgLoading, avgScore] = useApi(
    `${StatTypeUrl.AvgScore}?pollInstanceId=${instance.id}`
  );
  const [scoreCountLoading, scoreCount] = useApi(
    `${StatTypeUrl.ParticipantCount}?pollInstanceId=${instance.id}`
  );

  const navigate = useNavigate();

  return (
    <GridCard
      title={formatCreatedDate(instance.created_at)}
      renderAfterChildren={() => (
        <Flex
          justifyContent={"flex-end"}
          w={"100%"}
          mt={4}
          position={"absolute"}
          bottom={2}
          left={0}
          px={4}
        >
          <Box>
            {scoreCountLoading === ApiState.Success && (
              <Text>
                {scoreCount.stat} Score{scoreCount.stat !== 1 ? "s" : ""}
              </Text>
            )}
          </Box>
        </Flex>
      )}
      buttonsRight={[
        {
          label: "View Poll",
          icon: ViewIcon,
          as: "a",
          href: `/p/${instance.short_id}/${instance.number}`,
          target: "_blank",
        },
        {
          label: "Delete Poll",
          icon: DeleteIcon,
          onClick: () => deleteInstance(instance.id),
        },
      ]}
      onClick={() => navigate(`/polls/${poll.id}/${instance.id}`)}
    >
      {avgLoading !== ApiState.Success && <Spinner />}
      {avgLoading === ApiState.Success && avgScore && (
        <FormCircle score={avgScore.stat}>{avgScore.stat}</FormCircle>
      )}
    </GridCard>
  );
};

export default PollInstanceItem;
