import React, { FC, useContext, useState } from "react";
import {
  Box,
  BoxProps,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  TagRightIcon,
  useDisclosure,
  Wrap,
} from "@chakra-ui/react";
import { MinusIcon } from "@chakra-ui/icons";
import { format } from "date-fns";
import EventForm from "./EventForm";
import { PollEvent } from "../types/polls";
import { TIME_SERIES_LONG_DATE_FORMAT } from "../services/graphs";
import { AnalyticsContext } from "../../analytics/contexts/AnalyticsContext";
import { useCanAccessFeature } from "../../../core/contexts/PermissionsContext";
import { ProductFeature } from "../../../core/types/permissions";

interface Props extends BoxProps {}

const ManageEvents: FC = (props) => {
  const { events, removeEvent } = useContext(AnalyticsContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [chosenEvent, setChosenEvent] = useState<
    Partial<PollEvent> | undefined
  >(undefined);
  const canAccess = useCanAccessFeature(ProductFeature.EventsOverlay);

  if (!canAccess) {
    return null;
  }

  return (
    <Box {...props}>
      <Heading variant={"section"} mb={3}>
        Events
      </Heading>
      <Wrap mb={4}>
        {events.map((event) => {
          return (
            <Tag key={event.id} color={"white"} bg={"success"}>
              <TagLabel
                onClick={() => {
                  setChosenEvent(event);
                  onOpen();
                }}
              >
                {event.title} -{" "}
                {format(new Date(event.date), TIME_SERIES_LONG_DATE_FORMAT)}
              </TagLabel>
              <TagRightIcon
                as={MinusIcon}
                onClick={() => {
                  removeEvent(event.id);
                }}
              ></TagRightIcon>
            </Tag>
          );
        })}
      </Wrap>
      <Button onClick={onOpen}>Add Event</Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setChosenEvent(undefined);
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent pb={5}>
          <ModalHeader>Add Event to Poll</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <EventForm
              event={chosenEvent}
              onSubmit={() => {
                setChosenEvent(undefined);
                onClose();
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ManageEvents;
