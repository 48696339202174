import React, { FC, useContext } from "react";
import Layout from "../../../core/components/Layout";
import HeroHeading from "../../../core/components/HeroHeading";
import { PollInstanceContext } from "../contexts/PollInstanceContext";
import {
  Box,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { formatCreatedDate } from "../services/polls";
import AppBreadcrumbs from "../../../core/components/AppBreadcrumbs";
import PollDriversEdit from "./PollDriversEdit";
import { PollDriversEditProvider } from "../contexts/PollDriversEditContext";
import PollInsightsFeed from "./PollInsightsFeed";
import PollInstanceAnalytics from "./PollInstanceAnalytics";

const PollInstanceEditPage: FC = () => {
  const { instance } = useContext(PollInstanceContext);
  return (
    <Layout contentProps={{ p: 0 }}>
      <HeroHeading
        afterHeading={() => (
          <Heading fontSize={"20px"} textAlign={"center"} mt={4}>
            {formatCreatedDate(instance.created_at)}
          </Heading>
        )}
      >
        {instance.name}
      </HeroHeading>
      <AppBreadcrumbs />
      <Box p={4}>
        <Tabs isLazy>
          <TabList borderBottom={"none"}>
            <Tab>Feed</Tab>
            <Tab>Analytics</Tab>
            {instance?.capture_drivers && <Tab>Drivers</Tab>}
          </TabList>
          <TabPanels py={4}>
            <TabPanel>
              <PollInsightsFeed poll={instance} />
            </TabPanel>
            <TabPanel>
              <PollInstanceAnalytics />
            </TabPanel>
            {instance?.capture_drivers && (
              <TabPanel>
                <PollDriversEditProvider>
                  <PollDriversEdit />
                </PollDriversEditProvider>
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Box>
    </Layout>
  );
};

export default PollInstanceEditPage;
