export enum StatTypeUrl {
  FormScore = "polls/stats/form-score",
  AvgScore = "polls/stats/avg-score",
  AvgScoreCount = "polls/stats/avg-score-count",
  HighestScore = "polls/stats/highest-score",
  LowestScore = "polls/stats/lowest-score",
  TopDrivers = "polls/stats/top-drivers",
  ScoresOverTime = "polls/stats/scores-over-time",
  ParticipantCount = "polls/stats/participant-count",
  PollCount = "polls/stats/poll-count",
  Insights = "polls/stats/insights",
  LocationScores = "polls/stats/location-scores",
}
