import { Organisation } from "../types/user";
import { api } from "./api";
import { catchify } from "./ajax";

export async function createOrganisation(name: string) {
  const [err, result] = await catchify(
    api.post<Organisation>({
      url: "organisation",
      data: {
        name,
      },
    })
  );

  if (err) {
    console.error(err);
    alert(`Error creating team: ${JSON.stringify(err, null, 2)}`);
    return;
  }

  return result?.payload;
}

export async function updateOrganisation(org: Partial<Organisation>) {
  const [err, result] = await catchify(
    api.patch({
      url: `organisation/${org.id}`,
      data: org,
    })
  );

  if (err) {
    alert(`There was an error updating the team. Please try again.`);
  }

  return result?.payload;
}

export function getOrgLandingUrl(org: Organisation) {
  if (org.is_parent) {
    return "/analytics";
  }

  return "/polls";
}
