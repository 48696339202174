import React, { FC, useContext, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Slider,
  SliderThumb,
  SliderTrack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { PollContext } from "../contexts/PollContext";
import Logo from "../../../core/components/Logo";
import { mean } from "lodash";
import InfoTooltip from "../../../core/components/InfoTooltip";

const scoreDescriptions: Record<number, string> = {
  1: "Crisis, seek help now",
  2: "I am struggling and need help",
  3: "Extremely low form",
  4: "Very low form",
  5: "Low form",
  6: "Average form",
  7: "Good form",
  8: "Very good form",
  9: "Exceptional form",
  10: "Lifetime peak form",
};

const SetScore: FC = () => {
  const { poll, saveScore, saveScoreCategories, categories } =
    useContext(PollContext);
  // seems that zIndex isn't properly managed with tooltip popups so need to track here to apply correct zIndex value
  const [tooltipClicked, setTooltipClicked] = useState(-1);
  const [categoryScores, setCategoryScores] = useState<Record<string, number>>(
    {}
  );
  const score =
    (categories &&
      Number(
        mean(
          categories.reduce((acc, cat) => {
            acc.push(categoryScores[cat.id] || 6);
            return acc;
          }, [] as number[])
        ).toFixed(1)
      )) ||
    6;
  const roundedScore = Math.round(score);
  const navigate = useNavigate();
  const { number } = useParams();

  const handleSave = async () => {
    // todo: add the category score save
    const result = await saveScore(score);

    await saveScoreCategories(
      result.id,
      categories.reduce((acc, cat) => {
        acc[cat.id] = categoryScores[cat.id] || 6;

        return acc;
      }, categoryScores)
    );

    if (poll.capture_drivers) {
      let driversPath = `/p/${poll.short_id}/score/${result.id}/drivers`;

      if (number) {
        driversPath = `/p/${poll.short_id}/${number}/score/${result.id}/drivers`;
      }

      navigate(driversPath);
    } else {
      navigate("/p/thank-you");
    }
  };

  return (
    <Box
      bg={`form.${roundedScore}`}
      color={"white"}
      h={"100vh"}
      position={"relative"}
      overflowX={"hidden"}
    >
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        textAlign={"center"}
        w={"440px"}
        maxW={"100%"}
        mx={"auto"}
        pt={8}
      >
        <Logo w={"70px"} h={"auto"} />

        <VStack mb={5} spacing={0}>
          <Text fontSize={"70px"} fontFamily={"heading"}>
            {score}
          </Text>
          <Text fontSize={"14px"} position={"relative"}>
            {scoreDescriptions[roundedScore]}{" "}
            <InfoTooltip
              position={"absolute"}
              left={"104%"}
              top={"50%"}
              transform={"translateY(-50%)"}
              zIndex={3 + categories.length}
              iconProps={{
                color: "white",
                fontSize: "16px",
              }}
              display={"inline-block"}
              label={`Your FormScore is a measure of your readiness to perform broken down into Wellbeing, Effectiveness and Commitment to your organisation. Move the sliders to score each of the categories out of 10. Your FormScore is the average of the three. Note: We never share your individual data with your manager.`}
            />
          </Text>
        </VStack>
        <Box fontSize={"12px"} mb={4}>
          <Text px={4}>
            Reflect on your wellbeing, how effective you feel, how committed to
            your organisation you are today and simply score them out of 10.
          </Text>
        </Box>
        <VStack w={"100%"} spacing={6} px={4} mb={4}>
          {categories.map((c, i) => {
            return (
              <VStack
                w={"100%"}
                key={c.id}
                position={"relative"}
                zIndex={tooltipClicked === i ? 2 : 1}
              >
                <Slider
                  position={"relative"}
                  zIndex={1}
                  defaultValue={6}
                  max={10}
                  min={1}
                  step={1}
                  onChange={(val) => {
                    setCategoryScores({
                      ...categoryScores,
                      [c.id]: Math.min(Math.max(val, 1), 10),
                    });
                  }}
                >
                  <SliderTrack h={"3px"} bg={"grey.4"}></SliderTrack>
                  <SliderThumb boxSize={6}>
                    <Box
                      borderRadius={"50%"}
                      bg={c.color}
                      border={"2px solid white"}
                      w={"100%"}
                      h={"100%"}
                    ></Box>
                  </SliderThumb>
                </Slider>
                <Text position={"relative"} zIndex={2}>
                  {c.title}{" "}
                  <InfoTooltip
                    position={"absolute"}
                    left={"104%"}
                    top={"50%"}
                    transform={"translateY(-50%)"}
                    iconProps={{
                      color: "white",
                    }}
                    title={c.short_description}
                    label={c.long_description}
                    onClick={() => setTooltipClicked(i)}
                  />
                </Text>
              </VStack>
            );
          })}
        </VStack>
        <Box px={4} fontSize={"12px"}>
          <Text fontWeight={"bold"}>
            Note - we never share your individual data with your manager.
          </Text>
        </Box>
      </Flex>
      <Box
        position={"absolute"}
        bottom={0}
        left={"50%"}
        transform={"translateX(-50%)"}
        p={4}
        w={"100%"}
        maxW={"440px"}
      >
        <Button
          onClick={handleSave}
          variant={"poll"}
          bg={"white"}
          color={`form.${roundedScore}`}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default SetScore;
