import React, { FC, ReactNode } from "react";
import { Center, Text } from "@chakra-ui/react";
import DisplayCard, {
  DisplayCardProps,
} from "../../../core/components/DisplayCard";

export interface PollStatDisplayProps extends DisplayCardProps {
  stat: any;
  render?: (stat: any) => ReactNode;
}

const PollStatDisplay: FC<PollStatDisplayProps> = ({
  title,
  render,
  stat,
  loading,
  ...props
}) => {
  return (
    <DisplayCard
      minW={"250px"}
      align={"center"}
      title={title}
      loading={loading}
      justifyContent={"space-between"}
      {...props}
    >
      <Center>{render?.(stat) || <Text fontSize={"35px"}>{stat}</Text>}</Center>
    </DisplayCard>
  );
};

export default PollStatDisplay;
