import { FC, useContext } from "react";
import { FCWithChildren } from "./core/types/components";
import HomePage from "./modules/home/components/HomePage";
import NewPollPage from "./modules/polls/components/NewPollPage";
import PollsPage from "./modules/polls/components/PollsPage";
import PollPage from "./modules/poll/components/PollPage";
import { PollProvider } from "./modules/poll/contexts/PollContext";
import PollScorePage from "./modules/poll/components/PollScorePage";
import ThankYouPage from "./modules/poll/components/ThankYouPage";
import { PollDataProvider } from "./modules/poll/contexts/PollDataContext";
import LoginPage from "./modules/auth/components/LoginPage";
import { PollsProvider } from "./modules/polls/contexts/PollsContext";
import PollGroupEditPage from "./modules/polls/components/PollGroupEditPage";
import {
  PollGroupContext,
  PollGroupProvider,
} from "./modules/polls/contexts/PollGroupContext";
import PollDriversPage from "./modules/poll/components/PollDriversPage";
import PollInstanceEditPage from "./modules/polls/components/PollInstanceEditPage";
import {
  PollInstanceContext,
  PollInstanceProvider,
} from "./modules/polls/contexts/PollInstanceContext";
import { formatCreatedDate } from "./modules/polls/services/polls";
import SettingsPage from "./modules/settings/components/SettingsPage";
import NewUserPage from "./modules/auth/components/NewUserPage";
import AnalyticsPage from "./modules/analytics/components/AnalyticsPage";
import { AnalyticsProvider } from "./modules/analytics/contexts/AnalyticsContext";
import FeedPage from "./modules/feed/components/FeedPage";
import ProductsPage from "./modules/products/components/ProductsPage";
import { ProductsProvider } from "./modules/products/contexts/ProductsContext";
import SubscriptionSuccessRedirectPage from "./modules/products/components/SubscriptionSuccessRedirectPage";
import SubscriptionSuccessPage from "./modules/products/components/SubscriptionSuccessPage";
import { useCanAccessFeature } from "./core/contexts/PermissionsContext";
import { ProductFeature } from "./core/types/permissions";
import TeamsPage from "./modules/teams/components/TeamsPage";
import { TeamsProvider } from "./modules/teams/contexts/TeamsContext";
import LoginSuccessPage from "./modules/auth/components/LoginSuccessPage";
import PollScoreSummaryPage from "modules/poll/components/PollScoreSummaryPage";
import { PollScoreSummaryProvider } from "modules/poll/contexts/PollScoreSummaryContext";

export type AppBreadcrumb = {
  title: string;
  link: string;
  current?: boolean;
};

export type PageLink = {
  title: string;
  path: string;
  breadcrumbs?: () => AppBreadcrumb[];
  target?: string;
};

type PageRoute = PageLink & {
  element: FC;
  providers?: FCWithChildren[];
};

export const routes: Record<string, PageRoute> = {
  home: {
    path: "/",
    title: "Home",
    element: HomePage,
  },
  login: {
    path: "/login",
    title: "Login",
    element: LoginPage,
  },
  loginSuccess: {
    path: "/login-success",
    title: "Login Success",
    element: LoginSuccessPage,
  },
  newUser: {
    path: "/new-user",
    title: "New User",
    element: NewUserPage,
  },
  newPoll: {
    path: "/new-poll",
    title: "New Poll",
    element: NewPollPage,
  },
  polls: {
    path: "/polls",
    title: "FormScore Polls",
    element: PollsPage,
    providers: [PollsProvider],
  },
  pollEdit: {
    path: "/polls/:id",
    title: "Poll Edit",
    element: PollGroupEditPage,
    providers: [PollGroupProvider],
    breadcrumbs: function useBreadcrumbs() {
      const { poll } = useContext(PollGroupContext);

      return [
        {
          title: "Poll Bundles",
          link: "/polls",
        },
        {
          title: poll.name,
          link: "#",
          current: true,
        },
      ];
    },
  },
  pollInstanceEdit: {
    path: "/polls/:id/:instanceId",
    title: "Poll Instance Edit",
    element: PollInstanceEditPage,
    providers: [PollInstanceProvider],
    breadcrumbs: function useBreadcrumbs() {
      const { instance } = useContext(PollInstanceContext);

      return [
        {
          title: "Poll Bundles",
          link: "/polls",
        },
        {
          title: instance.name,
          link: `/polls/${instance.poll_id}`,
        },
        {
          title: formatCreatedDate(instance.created_at),
          link: "#",
        },
      ];
    },
  },
  thankYou: {
    path: "/p/thank-you",
    title: "Thank you",
    element: ThankYouPage,
  },
  poll: {
    path: "/p/:name",
    title: "Poll",
    element: PollPage,
    providers: [PollProvider, PollDataProvider],
  },
  setScore: {
    path: "/p/:name/score",
    title: "Set Poll Score",
    element: PollScorePage,
    providers: [PollProvider],
  },
  setDrivers: {
    path: "/p/:name/score/:scoreId/drivers",
    title: "Set Poll Score Drivers",
    element: PollDriversPage,
    providers: [PollProvider],
  },
  pollWithNumber: {
    path: "/p/:name/:number",
    title: "Poll",
    element: PollPage,
    providers: [PollProvider, PollDataProvider],
  },
  setScoreWithNumber: {
    path: "/p/:name/:number/score",
    title: "Set Poll Score",
    element: PollScorePage,
    providers: [PollProvider],
  },
  setDriversWithNumber: {
    path: "/p/:name/:number/score/:scoreId/drivers",
    title: "Set Poll Score Drivers",
    element: PollDriversPage,
    providers: [PollProvider],
  },
  scoreSummary: {
    path: "/p/:name/score/:scoreId/summary",
    title: "Poll Score Summary",
    element: PollScoreSummaryPage,
    providers: [PollScoreSummaryProvider],
  },
  scoreSummaryWithNumber: {
    path: "/p/:name/:number/score/:scoreId/summary",
    title: "Poll Score Summary",
    element: PollScoreSummaryPage,
    providers: [PollScoreSummaryProvider],
  },
  analytics: {
    path: "/analytics",
    title: "Analytics",
    element: AnalyticsPage,
    providers: [AnalyticsProvider],
  },
  feed: {
    path: "/feed",
    title: "Feed",
    element: FeedPage,
    providers: [],
  },
  settings: {
    path: "/settings",
    title: "Settings",
    element: SettingsPage,
    providers: [],
  },
  products: {
    path: "/subscribe",
    title: "Subscribe",
    element: ProductsPage,
    providers: [ProductsProvider],
  },
  subscriptionComplete: {
    path: "/subscription-complete",
    title: "Subscription Complete",
    element: SubscriptionSuccessRedirectPage,
    providers: [ProductsProvider],
  },
  subscriptionSuccess: {
    path: "/subscription-success",
    title: "Subscription Successful",
    element: SubscriptionSuccessPage,
  },
  teams: {
    path: "/teams",
    title: "Teams",
    element: TeamsPage,
    providers: [TeamsProvider],
  },
};

export const useMenuLinks: () => PageLink[] = () => {
  const hasDashboardAccess = useCanAccessFeature(
    ProductFeature.ManagerDashboard
  );
  const hasUnlimitedTeams = useCanAccessFeature(ProductFeature.UnlimitedTeam);

  const links = [
    routes.polls,
    routes.analytics,
    routes.feed,
    routes.settings,
    {
      title: "Help",
      path: "https://www.formscore.today/help-managers",
      target: "_blank",
    },
    {
      title: "Support",
      path: "mailto:support@formscore.today",
      target: "_blank",
    },
  ];

  if (!hasDashboardAccess) {
    links.push(routes.products);
  }

  if (hasUnlimitedTeams) {
    links.splice(3, 0, routes.teams);
  }

  return links;
};
