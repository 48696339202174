import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import AppRouter from "./AppRouter";
import { BrowserRouter } from "react-router-dom";
import theme from "./core/theme";
import { AuthProvider } from "./core/contexts/AuthContext";
import { UserProvider } from "./core/contexts/UserContext";
import Fonts from "core/theme/fonts";
import { PermissionsProvider } from "./core/contexts/PermissionsContext";

export const App = () => (
  <BrowserRouter>
    <ChakraProvider theme={theme}>
      <Fonts />
      <AuthProvider>
        <UserProvider>
          <PermissionsProvider>
            {/*<Box position={"fixed"} bottom={0} right={0}>*/}
            {/*  <ColorModeSwitcher justifySelf="flex-end" />*/}
            {/*</Box>*/}
            <AppRouter />
          </PermissionsProvider>
        </UserProvider>
      </AuthProvider>
    </ChakraProvider>
  </BrowserRouter>
);
