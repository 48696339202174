import React, { FC } from "react";
import { Box, BoxProps, Heading } from "@chakra-ui/react";
import DeleteMe from "./DeleteMe";

interface Props extends BoxProps {}

const UserSettings: FC<Props> = (props) => {
  return (
    <Box {...props}>
      <Heading variant={"section"} mb={5}>
        Account Settings
      </Heading>
      <DeleteMe />
    </Box>
  );
};

export default UserSettings;
