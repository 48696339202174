import React, { FC, useState } from "react";
import {
  Box,
  BoxProps,
  Center,
  Flex,
  Grid,
  Image,
  Text,
} from "@chakra-ui/react";
import { orderBy, xor } from "lodash";
import { emojis } from "../../polls/data/drivers";
import { getDefaultEmojiUrl } from "../../polls/services/drivers";
import { Driver } from "core/types/form";

type Props = BoxProps & {
  drivers: Driver[];
  onDriverSelect: (items: string[]) => void;
  trend: "positive" | "negative";
  showEmojis: boolean;
};

const MAX_EMOJI_WIDTH = 45;
const MAX_EMOJI_HEIGHT = 45;

const DriverSelect: FC<Props> = ({
  drivers,
  onDriverSelect,
  trend,
  showEmojis,
  ...props
}) => {
  const [selected, setSelected] = useState<string[]>([]);
  const layout = showEmojis ? "1fr 1fr 1fr 1fr 1fr" : "1fr 1fr 1fr";
  const gap = showEmojis ? 0 : 2;
  const driversToUse = orderBy(drivers || [], "title", "asc");
  return (
    <Grid
      alignItems={"center"}
      gridTemplateColumns={layout}
      gridColumnGap={gap}
      gridRowGap={3}
      py={2}
      {...props}
    >
      {driversToUse.map((r) => {
        const emoji = emojis[r.emoji] || {
          width: 45,
          height: 45,
        };
        const isSelected = selected.includes(r.id);
        const formColor = trend === "positive" ? "form.10" : "form.4";

        return (
          <Flex
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            key={r.id}
            onClick={() => {
              const newSelected = xor(selected, [r.id]);
              setSelected(newSelected);
              onDriverSelect(newSelected);
            }}
          >
            {showEmojis && (
              <>
                <Center
                  border={"1px solid"}
                  borderColor={isSelected ? formColor : "grey.1"}
                  borderRadius={"5px"}
                  p={1}
                  width={`${MAX_EMOJI_WIDTH}px`}
                  height={`${MAX_EMOJI_HEIGHT}px`}
                >
                  <Image
                    src={getDefaultEmojiUrl(r)}
                    alt={r.title}
                    width={"auto"}
                    height={"auto"}
                    maxWidth={`${
                      Math.min(emoji.width, MAX_EMOJI_WIDTH) * 0.8
                    }px`}
                    maxHeight={`${
                      Math.min(emoji.height, MAX_EMOJI_HEIGHT) * 0.8
                    }px`}
                    display={"block"}
                  />
                </Center>
                <Text
                  fontSize={"10px"}
                  color={isSelected ? "black" : "grey.3"}
                  mt={1}
                  mb={0}
                  whiteSpace={"nowrap"}
                >
                  {r.title}
                </Text>
              </>
            )}
            {!showEmojis && (
              <Box
                borderRadius={"15px"}
                w={"100%"}
                color={"white"}
                bg={isSelected ? formColor : "grey.1"}
                textAlign={"center"}
                p={"4px"}
                fontSize={"12px"}
                cursor={"pointer"}
                userSelect={"none"}
                whiteSpace={"nowrap"}
              >
                {r.title}
              </Box>
            )}
          </Flex>
        );
      })}
    </Grid>
  );
};
export default DriverSelect;
