import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "./routes";
import { FCWithChildren } from "./core/types/components";
import { last } from "lodash";

const AppRouter: FC = () => {
  const appRoutes = Object.values(routes);

  const renderProviders = (Comp: any, providers: FCWithChildren[]): any => {
    if (providers.length) {
      const NextProvider: any = last(providers);

      return renderProviders(
        <NextProvider>{Comp}</NextProvider>,
        providers.slice(0, -1)
      );
    }

    return Comp;
  };

  return (
    <Routes>
      {appRoutes.map((route) => {
        const Comp = route.element;
        let toRender = <Comp />;

        if (route.providers) {
          toRender = renderProviders(toRender, route.providers);
        }

        return <Route key={route.path} path={route.path} element={toRender} />;
      })}
    </Routes>
  );
};

export default AppRouter;
