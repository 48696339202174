import React, { FC, useContext } from "react";
import Layout from "../../../core/components/Layout";
import Products from "./Products";
import { CheckoutStep, ProductsContext } from "../contexts/ProductsContext";
import ProductCheckout from "./ProductCheckout";
import PaymentTermSelector from "./PaymentTermSelector";
import StartTrial from "./StartTrial";

const ProductsPage: FC = () => {
  const { checkoutStep } = useContext(ProductsContext);

  return (
    <Layout
      title={"Subscribe"}
      subTitle={"Choose the FormScore subscription package that suits you"}
    >
      <PaymentTermSelector mb={5} />
      <Products />
      {checkoutStep !== CheckoutStep.None && <ProductCheckout />}
      <StartTrial />
    </Layout>
  );
};

export default ProductsPage;
