import { Organisation } from "core/types/user";
import { flatten, orderBy } from "lodash";
import { TeamMember } from "modules/teams/types/teams";

export function buildOrgHierarchy<
  TIn extends Organisation,
  TOut extends TeamMember
>(orgs: TIn[], onOrg?: (org: TOut) => TOut): null | TOut {
  const parent = orgs.find((o) => !o.parent_id);
  const buildHierarchy = (current: Organisation): any => {
    if (!current) {
      return null;
    }
    const childOrgs = orgs
      .filter((o) => o.parent_id === current.id)
      .map(buildHierarchy);

    const newOrg = {
      ...current,
      children: orderBy(childOrgs, "name") as TOut[],
    };

    if (onOrg) {
      return onOrg(newOrg as any);
    }

    return newOrg;
  };

  if (!parent) {
    return null;
  }

  return buildHierarchy(parent);
}

export function flattenOrgHierarchy(teamMember: TeamMember): TeamMember[] {
  const result: any = [teamMember];

  if (teamMember.children.length) {
    const flattenedChildren = teamMember.children.map((child) =>
      flattenOrgHierarchy(child)
    );

    result.push(...flattenedChildren);
  }

  return flatten(result);
}
