import React, { FC, useContext } from "react";
import Layout from "../../../core/components/Layout";
import {
  Button,
  Center,
  Grid,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { PermissionsContext } from "../../../core/contexts/PermissionsContext";

const IMAGE_SIZE = "200px";
const RECOMMEND_EMAIL_BODY =
  "Hi,\n\nI am using the FormScore for Managers platform and thought you might want to explore this in your team.\n\nThe platform helps you understand the performance readiness of your people in real-time and aggregates anonymous data on the factors influencing their wellbeing, effectiveness, and commitment to the team.\n\nAnd they offer a free 2-week trial!\n\nFind out more at https://formscore.today, and give it a go at https://myform.today";

const SubscriptionSuccessPage: FC = () => {
  const { organisationProducts } = useContext(PermissionsContext);
  const active = organisationProducts?.find((p) => p.active);

  // if (!active) {
  //   return null;
  // }

  const sections = [
    {
      image: "info.png",
      title: "Where to go next?",
      description: `Check out our "How To" Guides.`,
      buttonLabel: `View "How To" Guides`,
      buttonLink: "https://www.formscore.today/help-managers",
    },
    {
      image: "confetti.gif",
      title: "Thank You!",
      description:
        "We're delighted you have chosen FormScore to help manage the performance readiness of your team.",
      buttonLabel: "Watch Video",
      buttonLink: "https://www.formscore.today/talks/checkin-welcome",
    },
    {
      image: "lego.png",
      title: "Loving FormScore so far?",
      description:
        "Recommend to a fellow manager and we will immortalise you in Lego when they sign up to FormScore",
      buttonLabel: "Recommend",
      buttonLink: `mailto:?subject=Here's something exciting&body=${encodeURIComponent(
        RECOMMEND_EMAIL_BODY
      )}`,
    },
  ];

  return (
    <Layout>
      <VStack as={Center} spacing={5} textAlign={"center"}>
        <Stack spacing={3} mb={"80px"}>
          <Heading fontSize={"50px"} mb={6}>
            You're in!
          </Heading>
          <Text fontSize={"28px"} maxW={"600px"}>
            Welcome to FormScore. You've successfully subscribed to the{" "}
            {active?.product?.name} plan
          </Text>
        </Stack>
        <Grid gridTemplateColumns={"1fr 1fr 1fr"} gridGap={8}>
          {sections.map((section) => {
            return (
              <VStack spacing={3} key={section.title}>
                <Image
                  src={section.image}
                  w={IMAGE_SIZE}
                  h={IMAGE_SIZE}
                  objectFit={"cover"}
                  borderRadius={"50px"}
                  overflow={"hidden"}
                  mb={6}
                />

                <Heading fontSize={"22px"}>{section.title}</Heading>
                <Text fontSize={"18px"} maxW={"350px"} minH={"150px"}>
                  {section.description}
                </Text>
                <Button as={"a"} href={section.buttonLink} target={"_blank"}>
                  {section.buttonLabel}
                </Button>
              </VStack>
            );
          })}
        </Grid>
      </VStack>
    </Layout>
  );
};

export default SubscriptionSuccessPage;
