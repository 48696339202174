import React, { FC, useContext } from "react";
import { Box, Flex } from "@chakra-ui/react";
import Logo from "../../../core/components/Logo";
import PollMiniStats from "./PollMiniStats";
import QrCode from "./QrCode";
import { PollDataContext } from "../contexts/PollDataContext";
import FormMultiCircle from "../../../core/components/FormMultiCircle";
import Legend from "../../../core/components/Legend";

const PollPageHeader: FC = () => {
  const { categories, formScore } = useContext(PollDataContext);

  return (
    <Flex justifyContent={"space-between"} position={"relative"} py={4} px={12}>
      <Box position={"relative"}>
        <Logo variant={"dark"} w={"150px"} h={"auto"} mb={4} />
        <PollMiniStats />
      </Box>
      <Box
        position={"absolute"}
        left={"50%"}
        top={0}
        transform={"translateX(-50%)"}
      >
        {!!formScore?.avg && (
          <FormMultiCircle
            score={formScore.avg}
            categoryScores={formScore.categories || []}
            size={200}
          />
        )}
        {formScore?.categories?.length > 0 && (
          <Legend
            items={categories}
            position={"absolute"}
            top={4}
            left={"102%"}
          />
        )}
      </Box>
      <QrCode />
    </Flex>
  );
};

export default PollPageHeader;
