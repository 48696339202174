import React, { FC, useContext } from "react";
import { useFormik } from "formik";
import { Poll } from "../types/polls";
import { Box, BoxProps, Button, Flex } from "@chakra-ui/react";
import FormItem from "../../../core/components/FormItem";
import { PollsContext } from "../contexts/PollsContext";

type Props = BoxProps & {
  onCreated?: (poll: Poll) => void;
};

const NewPollForm: FC<Props> = ({ onCreated, ...props }) => {
  const { createNewPoll } = useContext(PollsContext);
  const formik = useFormik({
    initialValues: {
      name: "",
      capture_drivers: true,
      capture_location: true,
      capture_categories: true,
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.name) {
        errors.name = "Name required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        const newPoll: Poll = await createNewPoll(values);
        onCreated?.(newPoll);
      } catch (e) {
        alert(
          `There was an error creating the poll. Please ensure you have a unique poll name as duplicates aren't allowed.`
        );
      }
    },
  });
  return (
    <Box {...props}>
      <form onSubmit={formik.handleSubmit}>
        <FormItem
          label={"Bundle Name"}
          name={"name"}
          onChange={formik.handleChange}
          mb={4}
        />
        <Flex justifyContent={"flex-end"} w={"100%"}>
          <Button type={"submit"} mt={4}>
            Create
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default NewPollForm;
