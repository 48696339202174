import React, { FC, useContext } from "react";
import { Box, BoxProps, Flex, Text } from "@chakra-ui/react";
import TimeControls from "core/components/TimeControls";
import { TeamsContext } from "modules/teams/contexts/TeamsContext";
import { TeamMemberWithStats } from "modules/teams/types/teams";
import { UserContext } from "core/contexts/UserContext";

interface Props extends BoxProps {}

const TeamsStats: FC<Props> = (props) => {
  const { team, ...timeControls } = useContext(TeamsContext);
  const { selectedOrg } = useContext(UserContext);

  const renderTeam = (member: TeamMemberWithStats) => {
    return (
      <Box key={member.id}>
        <Flex
          justifyContent={"space-between"}
          fontWeight={selectedOrg?.id === member.id ? "bold" : ""}
          py={2}
          borderBottom={"1px solid"}
          borderColor={"gray.200"}
        >
          <Box>
            <Text>{member.name}</Text>
          </Box>
          <Box w={"100px"}>
            <Text>{member.fullInstanceCount}</Text>
          </Box>
        </Flex>
        <Box pl={4}>{member.children.map(renderTeam)}</Box>
      </Box>
    );
  };

  if (!team) {
    return null;
  }

  return (
    <Box {...props}>
      <Text mb={4} pr={"125px"}>
        This is your org chart with your entity highlighted in bold. Note, the
        number of polls utilised for parent entities includes their own polls
        plus those at the child entity level.
      </Text>
      <TimeControls {...timeControls} mb={5} />
      <Box maxW={"800px"} px={2}>
        <Flex
          justifyContent={"space-between"}
          color={"gray.600"}
          fontSize={"13px"}
          textTransform={"uppercase"}
          fontWeight={"bold"}
          borderBottom={"1px solid"}
          borderColor={"gray.200"}
          py={2}
        >
          <Box>
            <Text>Name</Text>
          </Box>
          <Box w={"100px"}>
            <Text>Polls</Text>
          </Box>
        </Flex>
        {renderTeam(team)}
      </Box>
    </Box>
  );
};

export default TeamsStats;
