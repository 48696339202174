import React, { FC, useContext } from "react";
import { BoxProps, Grid } from "@chakra-ui/react";
import { PollDataContext } from "../contexts/PollDataContext";
import PollStatDriversDisplay from "modules/poll/components/PollStatDriversDisplay";

interface Props extends BoxProps {}

const PollDriversDisplay: FC<Props> = (props) => {
  const { driverStats } = useContext(PollDataContext);
  const stats = {
    "Most Selected Drivers": driverStats.top,
    "Top Boosting Drivers": driverStats.positive,
    "Top Lowering Drivers": driverStats.negative,
  };

  return (
    <Grid
      gridGap={8}
      justifyContent={"center"}
      gridTemplateColumns={"300px 300px 300px"}
      {...props}
    >
      {Object.entries(stats).map(([title, drivers]) => (
        <PollStatDriversDisplay key={title} drivers={drivers} title={title} />
      ))}
    </Grid>
  );
};

export default PollDriversDisplay;
