export enum TimePeriod {
  ThreeMonths = "Three Months",
  SixMonths = "Six Months",
  NineMonths = "Nine Months",
  OneYear = "One Year",
  Custom = "Custom",
}

export const timePeriodToMonthNumber: Record<TimePeriod, number> = {
  [TimePeriod.ThreeMonths]: 3,
  [TimePeriod.SixMonths]: 6,
  [TimePeriod.NineMonths]: 9,
  [TimePeriod.OneYear]: 12,
  [TimePeriod.Custom]: 0,
};
