import { createContext, useContext } from "react";
import { OrgWithStats, TeamMemberWithStats } from "../types/teams";
import { sum } from "lodash";
import { useTimeControls } from "core/services/dates";
import { TimeControlsProps } from "core/components/TimeControls";
import { FCWithChildren } from "core/types/components";
import { useApi } from "core/services/api";
import { UserContext } from "core/contexts/UserContext";
import { buildOrgHierarchy } from "modules/teams/services/teams";
import { StatDriver } from "core/types/form";
import { StatTypeUrl } from "modules/polls/constants/urls";

export type ITeamsContext = TimeControlsProps & {
  team: TeamMemberWithStats | null;
  drivers: StatDriver[];
};

export const TeamsContext = createContext<ITeamsContext>({
  team: {} as TeamMemberWithStats,
  drivers: [],
  from: undefined,
  setFrom: () => {},
  to: undefined,
  setTo: () => {},
  timePeriod: undefined,
  setTimePeriod: () => {},
});

export const TeamsProvider: FCWithChildren = ({ children }) => {
  const { selectedOrg } = useContext(UserContext);
  const timeControls = useTimeControls();
  const [, orgs] = useApi<{
    teams: OrgWithStats[];
  }>(
    `polls/organisations/${selectedOrg?.id}/teams?from=${
      timeControls.from || ""
    }&to=${timeControls.to || ""}`
  );
  const getInstanceCount = (org: TeamMemberWithStats) => {
    let result = Number(org.instances);

    if (org.children) {
      result += sum(org.children.map((o) => getInstanceCount(o)));
    }

    return result;
  };

  const teams = orgs?.teams || [];

  const team: TeamMemberWithStats | null = buildOrgHierarchy<
    OrgWithStats,
    TeamMemberWithStats
  >(teams, (org) => ({
    ...org,
    fullInstanceCount: getInstanceCount(org),
  }));

  const [, driversResult] = useApi(
    teams?.length && team
      ? `${StatTypeUrl.TopDrivers}?args=${JSON.stringify({
          orgId: team.id,
          from: timeControls.from,
          to: timeControls.to,
          limit: 30,
        })}`
      : ""
  );

  return (
    <TeamsContext.Provider
      value={{
        team,
        drivers: driversResult?.stat || [],
        ...timeControls,
      }}
    >
      {children}
    </TeamsContext.Provider>
  );
};
