import React, { FC, ReactNode } from "react";
import {
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardProps,
  Center,
  Divider,
  Flex,
  Grid,
  Heading,
  Icon,
  LinkProps,
  Tooltip,
} from "@chakra-ui/react";

export type GridCardButtonProp = {
  label: string;
  onClick?: () => void;
  icon: any;
} & LinkProps;

interface Props extends CardProps {
  title: string;
  renderAfterChildren?: () => ReactNode;
  buttonsLeft?: GridCardButtonProp[];
  buttonsRight?: GridCardButtonProp[];
}

const GridCard: FC<Props> = ({
  title,
  children,
  renderAfterChildren,
  buttonsLeft,
  buttonsRight,
  onClick,
  ...props
}) => {
  const renderButtons = (buttons: GridCardButtonProp[], justify: string) => {
    return (
      <ButtonGroup
        justifyContent={justify}
        alignItems={"center"}
        w={"100%"}
        fontSize={"24px"}
        spacing={4}
        sx={{
          "> *": {
            cursor: "pointer",
          },
        }}
      >
        {buttons.map(({ label, icon, onClick, ...btn }) => (
          <Tooltip key={label} label={label}>
            {/* @ts-ignore */}
            <Flex alignItems={"center"} onClick={onClick} {...btn}>
              <Icon as={icon} />
            </Flex>
          </Tooltip>
        ))}
      </ButtonGroup>
    );
  };

  // @ts-ignore
  return (
    <Card w={"250px"} maxW={"100%"} bg={"white"} {...props}>
      <CardHeader cursor={"pointer"} onClick={onClick}>
        <Heading textAlign={"center"} fontSize={"2xl"}>
          {title}
        </Heading>
      </CardHeader>
      <CardBody
        minH={"200px"}
        cursor={"pointer"}
        onClick={onClick}
        position={"relative"}
      >
        <Center flexDirection={"column"}>{children}</Center>
        {renderAfterChildren?.()}
      </CardBody>
      <Divider />
      <CardFooter>
        <Grid gridTemplateColumns={"1fr 1fr"} w={"100%"}>
          {renderButtons(buttonsLeft || [], "flex-start")}
          {renderButtons(buttonsRight || [], "flex-end")}
        </Grid>
      </CardFooter>
    </Card>
  );
};

export default GridCard;
