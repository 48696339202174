import React, { FC } from "react";
import { BoxProps, Circle } from "@chakra-ui/react";

interface Props extends BoxProps {
  score: number;
  size?: string | number;
}

const FormCircle: FC<Props> = ({
  score,
  size,
  fontSize,
  children,
  ...props
}) => {
  return (
    <Circle
      bg={`form.${Math.round(score)}`}
      size={size || "120px"}
      fontSize={fontSize || "35px"}
      color={"white"}
      {...props}
    >
      {children}
    </Circle>
  );
};

export default FormCircle;
