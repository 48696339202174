export enum ProductFeature {
  CheckIns = "polls_check_ins",
  ManagerDashboard = "polls_manager_dashboard",
  EnterpriseDashboard = "polls_enterprise_dashboard",
  ExpandedInsights = "polls_expanded_insights",
  EventsOverlay = "polls_events_overlay",
  SingleTeam = "polls_single_team",
  UnlimitedTeam = "polls_unlimited_team",
  TeamsApp = "polls_teams_app",
  PersonalisedContent = "polls_personalised_content",
}

export type ProductFeatureItem = {
  id: ProductFeature;
  description: string;
};

export type ProductPrice = {
  id: string;
  name: string;
  amount: number;
};

export type Product = {
  id: string;
  name: string;
  description: string;
  details: any;
  features: ProductFeatureItem[];
  prices: Record<string, ProductPrice[]>;
};

export type OrganisationProduct = {
  id: string;
  active: boolean;
  trial: boolean;
  trial_expires_at: string;
  product?: Product;
};

export type OrganisationAddress = {
  line1: string;
  line2: string | null;
  city: string;
  state: string;
  postal_code: string;
  country: string;
};
