import React, { FC } from "react";
import { Circle, Heading, HStack, Image, Tag, VStack } from "@chakra-ui/react";
import { getDefaultEmojiUrl } from "../services/drivers";
import { StatDriver } from "core/types/form";

export interface PollStatDriverProps {
  color: string;
  driver: StatDriver;

  variant?: "column" | "row" | "no-count";
}

const PollStatDriver: FC<PollStatDriverProps> = ({
  color,
  driver,
  variant = "column",
}) => {
  if (variant === "column") {
    return (
      <VStack spacing={3}>
        <Image w={"50px"} src={getDefaultEmojiUrl(driver)} />
        <Tag bg={color} color={"white"}>
          {driver.title}
        </Tag>
        <Circle
          position={"absolute"}
          top={0}
          right={4}
          size={"40px"}
          border={"1px solid"}
        >
          {driver.count}
        </Circle>
      </VStack>
    );
  }

  if (variant === "row") {
    return (
      <HStack spacing={3} justifyContent={"space-between"} w={"100%"}>
        <HStack spacing={5}>
          <Image w={"30px"} src={getDefaultEmojiUrl(driver)} />
          <Heading fontSize={"20px"}>{driver.title}</Heading>
        </HStack>
        <Heading fontSize={"28px"}>{driver.count}</Heading>
      </HStack>
    );
  }

  if (variant === "no-count") {
    return (
      <Circle>
        <HStack spacing={5}>
          <Image w={"30px"} src={getDefaultEmojiUrl(driver)} />
          <Heading fontSize={"20px"}>{driver.title}</Heading>
        </HStack>
      </Circle>
    );
  }

  return null;
};

export default PollStatDriver;
