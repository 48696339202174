import { api } from "./api";
import { catchify } from "./ajax";
import { AUTH_COOKIE_NAME } from "../constants/cookies";
import { getItem, storeItem } from "./storage";

export const getAuthToken = () => {
  return getItem(AUTH_COOKIE_NAME);
};

export const setAuthToken = (token: string) => {
  return storeItem(AUTH_COOKIE_NAME, token, 1);
};

export const deleteUserCookies = () => {
  localStorage.clear();
};

export const isLoggedIn = () => {
  return !!getAuthToken();
};

export const loginOrRegister = async (email: string): Promise<string> => {
  const url = "user/register/email";
  const result = await api.post<{ verification_id: string }>({
    url,
    data: {
      email,
      source: "checkin",
    },
  });

  if (!result?.payload?.verification_id) {
    throw new Error("Verification id missing from response");
  }

  return result.payload.verification_id;
};

export const verify = async (verificationId: string, code: string) => {
  const [codeErr, codeResult] = await catchify(
    api.post<{
      user: any;
      token: string;
    }>({
      url: "user/verify",
      data: {
        verification_code: code,
        verification_id: verificationId,
      },
    })
  );
  let success = !codeErr;

  if (codeResult?.payload?.token) {
    setAuthToken(codeResult.payload.token);
  } else {
    success = false;
  }

  return {
    success,
    user: codeResult?.payload?.user,
    error: codeErr,
  };
};

export const logout = () => {
  deleteUserCookies();
  window.location.href = "/";
};
