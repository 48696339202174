import React, { FC, useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ProductsContext } from "../contexts/ProductsContext";

const SubscriptionSuccessRedirectPage: FC = () => {
  const navigate = useNavigate();
  const { finaliseSubscription } = useContext(ProductsContext);
  const [params] = useSearchParams();
  const orgProductId = params.get("org_product_id");
  useEffect(() => {
    if (orgProductId) {
      finaliseSubscription(orgProductId).then(() => {
        setTimeout(() => {
          window.location.href = "/subscription-success";
        }, 500);
      });
    }
  }, [orgProductId]);

  if (!orgProductId) {
    navigate("/polls");
    return null;
  }

  return null;
};

export default SubscriptionSuccessRedirectPage;
