import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
    @font-face {
        font-family: 'Fugue Regular';
        font-style: normal;
        font-weight: normal;
        font-display: swap;
        src: url('/fonts/fugue_regular.ttf') format('truetype')
    }
    
    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: normal;
      font-display: swap;
      src: url('/fonts/Inter-Regular.woff') format('woff')
    }
    
    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-display: swap;
      src: url('/fonts/Inter-SemiBold.woff') format('woff')
    }    
`}
  />
);

export default Fonts;
