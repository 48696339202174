import React, { FC, useContext } from "react";
import { Box, Heading, Text, Wrap } from "@chakra-ui/react";
import { PollsContext } from "../contexts/PollsContext";
import CreatePollInstanceButton from "./CreatePollInstanceButton";
import PollGroupTease from "./PollGroupTease";
import { Poll } from "../types/polls";
import NewPollGroupTease from "./NewPollGroupTease";

const Polls: FC = () => {
  const { polls } = useContext(PollsContext);
  const pollsCopy = (polls || []).slice();

  if (polls?.length) {
    pollsCopy.splice(1, 0, {
      id: "new-poll",
    } as Poll);
  }

  return (
    <Box>
      <CreatePollInstanceButton mb={10} />
      <Heading variant={"section"} mb={2}>
        My Bundles
      </Heading>
      <Text fontSize={"18px"} mb={4}>
        Polls are organised into "Bundles" so you can view performance readiness
        analytics for different scenarios and groups eg "Team Meetings",
        "Projects", or "Employee Grades". Keep things simple and create one
        Bundle for your team or create different bundles as you see fit.
      </Text>
      <Wrap spacing={5}>
        {pollsCopy.map((poll) => {
          if (poll.id === "new-poll") {
            return <NewPollGroupTease key={poll.id} />;
          }

          return <PollGroupTease poll={poll} key={poll.id} mb={6} />;
        })}
        {!pollsCopy.length && <NewPollGroupTease h={"250px"} />}
      </Wrap>
    </Box>
  );
};

export default Polls;
