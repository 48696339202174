import React, { FC, useContext } from "react";
import {
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  useDisclosure,
} from "@chakra-ui/react";
import { Poll } from "../types/polls";
import NewPollInstanceForm from "./NewPollInstanceForm";
import { PollsContext } from "../contexts/PollsContext";

interface Props extends ButtonProps {
  onCreated?: (poll: Poll) => void;
}

const CreatePollInstanceButton: FC<Props> = ({ onCreated, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { polls, startNewPoll } = useContext(PollsContext);

  const handleOnClick = () => {
    if (polls?.length === 1) {
      startNewPoll(polls[0]);
    } else {
      onOpen();
    }
  };

  return (
    <>
      <Button onClick={handleOnClick} {...props}>
        Create Poll
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalHeader>Create New Poll</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <NewPollInstanceForm pb={4} onCreated={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreatePollInstanceButton;
