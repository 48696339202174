import React, { FC, useContext } from "react";
import Layout from "../../../core/components/Layout";
import { PollGroupContext } from "../contexts/PollGroupContext";
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import PollInstances from "./PollInstances";
import HeroHeading from "../../../core/components/HeroHeading";
import AppBreadcrumbs from "../../../core/components/AppBreadcrumbs";
import PollDriversEdit from "./PollDriversEdit";
import { PollDriversEditProvider } from "../contexts/PollDriversEditContext";
import PollGroupAnalytics from "./PollGroupAnalytics";
import PollInsightsFeed from "./PollInsightsFeed";
import { AnalyticsProvider } from "../../analytics/contexts/AnalyticsContext";

const PollGroupEditPage: FC = () => {
  const { poll } = useContext(PollGroupContext);

  if (!poll) {
    return null;
  }

  return (
    <Layout contentProps={{ p: 0 }}>
      <HeroHeading>{poll.name}</HeroHeading>
      <AppBreadcrumbs />
      <Box p={4}>
        <Tabs isLazy>
          <TabList borderBottom={"none"}>
            <Tab>Polls</Tab>
            <Tab>Feed</Tab>
            <Tab>Analytics</Tab>
            {poll?.capture_drivers && <Tab>Drivers</Tab>}
          </TabList>
          <TabPanels py={4}>
            <TabPanel>
              <PollInstances />
            </TabPanel>
            <TabPanel>
              <PollInsightsFeed poll={poll} />
            </TabPanel>
            <TabPanel position={"relative"}>
              <AnalyticsProvider>
                <PollGroupAnalytics />
              </AnalyticsProvider>
            </TabPanel>
            {poll?.capture_drivers && (
              <TabPanel>
                <PollDriversEditProvider>
                  <PollDriversEdit />
                </PollDriversEditProvider>
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Box>
    </Layout>
  );
};

export default PollGroupEditPage;
