import { Poll } from "../types/polls";
import { createContext, useContext } from "react";
import { FCWithChildren } from "../../../core/types/components";
import { useApi } from "../../../core/services/api";
import { addNewPoll, createPollInstance, deletePoll } from "../services/polls";
import { mutate } from "swr";
import { UserContext } from "../../../core/contexts/UserContext";

export type IPollsContext = {
  polls: Poll[];
  createNewPoll: (poll: Partial<Poll>) => Promise<Poll>;
  startNewPoll: (poll: Poll) => void;
  createAndStartPoll: (poll: Partial<Poll>) => void;
  deletePoll: (pollId: string) => Promise<void>;
};

export const PollsContext = createContext<IPollsContext>({
  polls: [],
  createNewPoll: async () => {
    return {} as Poll;
  },
  startNewPoll: () => {},
  createAndStartPoll: () => {},
  deletePoll: async () => {},
});

export const PollsProvider: FCWithChildren = ({ children }) => {
  const { user, selectedOrg } = useContext(UserContext);
  let pollsUrl = "polls";

  if (selectedOrg?.id) {
    pollsUrl += `?orgId=${selectedOrg.id}`;
  }

  const [, polls] = useApi<Poll[]>(user?.id && pollsUrl);

  const handleCreateNewPoll = async (args: Partial<Poll>) => {
    if (selectedOrg?.id) {
      args.organisation_id = selectedOrg.id;
    }

    const result = await addNewPoll(args);
    await mutate(pollsUrl);

    return result;
  };

  const handleStartNewPoll = async (poll: Poll) => {
    const res = await createPollInstance(poll.id);
    window.open(`/p/${poll.short_id}/${res.number}`, "_blank");
  };

  const handleCreateAndStartPoll = async (poll: Partial<Poll>) => {
    const newPoll = await handleCreateNewPoll(poll);
    return handleStartNewPoll(newPoll);
  };

  const handleDeletePoll = async (pollId: string) => {
    const result = await deletePoll(pollId);
    await mutate(pollsUrl);

    return result;
  };

  return (
    <PollsContext.Provider
      value={{
        polls: (polls || []) as Poll[],
        createNewPoll: handleCreateNewPoll,
        startNewPoll: handleStartNewPoll,
        createAndStartPoll: handleCreateAndStartPoll,
        deletePoll: handleDeletePoll,
      }}
    >
      {children}
    </PollsContext.Provider>
  );
};
