import React, { FC } from "react";
import {
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { useWindowSize } from "react-use";
import Logo from "./Logo";

const MobileModal: FC = () => {
  const { width } = useWindowSize();
  return (
    <Modal isOpen={width < 900} onClose={() => {}}>
      <ModalOverlay bg={"primary"} />
      <ModalContent m={4}>
        <ModalBody p={5}>
          <Center>
            <Logo w={"100px"} h={"80px"} variant={"dark"} />
          </Center>
          <Heading fontSize={"22px"} textAlign={"center"} mb={5}>
            We apologise.
          </Heading>
          <Heading fontSize={"22px"} textAlign={"center"}>
            FormScore for Managers is best accessed via your desktop at this
            time. Please visit myform.today on your desktop browser.
          </Heading>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MobileModal;
