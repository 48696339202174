import { defineStyleConfig } from "@chakra-ui/react";

const buttonComponent = defineStyleConfig({
  baseStyle: {
    background: "success",
    borderRadius: "30px",
    color: "white",
    fontSize: "17px",
    fontWeight: "bold",
    fontFamily: "heading",
    h: "auto",
    height: "auto",
    lineHeight: "1",
    minW: "160px",
    minWidth: "160px",
    p: 4,
    _hover: {
      _disabled: {
        background: "success",
      },
    },
  },
  variants: {
    outline: {
      background: "transparent",
      color: "white",
      _hover: {
        background: "transparent",
      },
      _active: {
        background: "transparent",
      },
    },
    poll: {
      background: "transparent",
      borderRadius: "10px",
      border: "1px solid",
      borderColor: "white",
      fontSize: "18px",
      w: "100%",
    },
  },
  sizes: {
    sm: {},
    md: {},
    lg: {},
  },
  defaultProps: {
    variant: null as any,
  },
});

export default buttonComponent;
